import React, { useEffect, useState, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory, useParams } from "react-router-dom";
import AdminLayout from "../../../components/layout/AdminLayout";
import Heading from "../../../components/utilities/Heading";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import OrderSummuryItems from "./OrderSummuryItems";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getSingleOrder } from "../../../redux/actions/order";
import { getRestaurantOwnInfo } from "../../../redux/actions/restaurantInfo";
import { getRestaurantAddress } from "../../../redux/actions/address.js";
import { getServiceCharge } from "../../../redux/actions/serviceCharge";
import DateTimePicker from "react-datetime-picker";
import FoodAppsLogo from "../../../images/logo.png";
import OrderInvoice from "./OrderInvoice";

export default function OrderSummary() {
  let componentRef = useRef();
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);

  const { id } = useParams();
  const orderId = id;
  const token = getCookie("@partner_token");
  let history = useHistory();

  // let cart = order?.cartItems ? JSON.parse(order.cartItems) : {};
  useEffect(() => {
    getRestaurantAddress(dispatch, token, id);
  }, [dispatch, id, token]);

  useEffect(() => {
    getSingleOrder(dispatch, orderId, token);
  }, [dispatch, orderId, token]);

  // const handleAddMore = () => {
  //   localStorage.setItem("cart", order.cartItems);
  //   history.push(`/admin-in-house-orders/${order._id}`);
  // };

  useEffect(() => {
    getRestaurantOwnInfo(dispatch, token);
    getServiceCharge(dispatch);
  }, [dispatch, token]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Partner Inbox</title>
      </Helmet>
      <AdminLayout>
        {/* <Heading classes={"flex items-center pb-2"}>
          <AiOutlineArrowLeft
            className="cursor-pointer"
            onClick={() => history.goBack()}
          />{" "}
          <span className="ml-2">My Order</span>
        </Heading> */}
        {/* <div className="mx-60 mt-10" ref={(el) => (componentRef = el)}> */}
        <div className="w-full text-center mb-3 ">
          <div className="flex justify-end">
            {/* <Heading>Order Details</Heading> */}
            <ReactToPrint
              trigger={() => (
                <button className="bg-prim px-5 ml-24 py-2 rounded text-white">
                  Print
                </button>
              )}
              content={() => componentRef}
            />
          </div>
        </div>

        <OrderInvoice ref={(el) => (componentRef = el)} />

        {/* {order.orderStatus === "Pending" &&
          order.orderType === "Dine In" &&
          order.fromRestaurant === true && (
            <div className="text-center">
              <button
                onClick={handleAddMore}
                className="bg-prim px-5 py-2 text-white rounded"
              >
                Add More Item
              </button>
            </div>
          )} */}
      </AdminLayout>
    </HelmetProvider>
  );
}
