import React, { useEffect, useState } from 'react';

import OrderOptionsPopup from './OrderOptionsPopup';

export default function ProductItem(props) {
    let { food, setCart, cart, index } = props;
    const [openModal, setOpenModal] = useState(false)
    const [active, setActive] = useState(undefined);
    // const[show, setShow]=useState(false);
    const [item, setItem] = useState({
        food: "",
        name: "",
        basePrice: "",
        options: "",
        price: "",
        quantity: 0,
        optionsPrice: 0,
        isAdded: false,
        uniqueId: ""
    })

    useEffect(() => {
        setItem({
            food: food._id,
            name: food.name,
            basePrice: food.basePrice,
            options: [],
            optionsPrice: 0,
            price: 0,
            quantity: 0,
            isAdded: false,
            uniqueId: ""
        })
    }, [food])
   
    const handleClick = (item,index) => {
        let updatedCart = { ...cart }
        updatedCart.subTotal += item.basePrice;
        item.uniqueId = item.name.substring(0, 3).toUpperCase() + Math.floor(Math.random() * 1000) + 1;
        const isAdded = cart.foodItems.find((el) => el.name === item.name);
        if (isAdded) {
            const index = cart.foodItems.findIndex((el) => el.name === item.name);
            cart.foodItems[index].quantity += 1;
            cart.foodItems[index].price += item.basePrice;
        }
        if (!isAdded) {
            item.quantity += 1;
            item.price += item.basePrice;
            updatedCart.foodItems.push(item);
        }
        cart = { ...updatedCart, count: updatedCart.foodItems.length }
        // console.log(cart)
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify(cart));
        setCart({ ...updatedCart, foodItems: updatedCart.foodItems, count: updatedCart.foodItems.length, subTotal: updatedCart.subTotal });
        setActive(index);

    }

    const handleOptions = (index) => {
        setItem({
            food: food._id,
            name: food.name,
            basePrice: food.basePrice,
            options: [],
            optionsPrice: 0,
            price: food.price,
            quantity: 0,
            isAdded: false
        })
        setOpenModal(true)
        setActive(index);
    }

    // const showDescription = (e) =>{
    //     setShow(true);
    //     e.preventDefault();
    // }
    // console.log(props.food.description, "description")
  
    return (
        <div>
            {
                food.options && food.options?.length > 0 ? (
                    <div onClick={() => handleOptions(item,index)} className={`${active === index ? "bg-green-700" : "bg-blue-500"}  h-28 rounded-md p-3 overflow-y-auto cursor-pointer`}>
                        <p className="text-sm font-semibold uppercase text-gray-700">{props.name}</p>
                        <p className="text-gray-800 mt-4 font-semibold"> £{Number.parseFloat(props.price).toFixed(2)}</p>
                        {/* <p className={`text-xs text-gray-700 mt-2`}>
                            { props.food.description }
                        </p> */}
                    </div>
                ) : (
                    <div onClick={() => handleClick(item,index)} className={`${active === index ? "bg-green-700" : "bg-blue-500"}  hover:bg-green-700  h-28 rounded-md p-3 overflow-y-auto cursor-pointer`}
                    >
                        <p className="text-sm font-semibold uppercase text-gray-700">{props.name}</p>
                        <p className="text-gray-800 mt-4 font-semibold"> £{Number.parseFloat(props.price).toFixed(2)}</p>
                        {/* <p className={`text-xs text-gray-700 mt-2`}>
                            { props.food.description }
                        </p> */}
                    </div>
                    // <OrderOptionsPopup openModal={openModal} setOpenModal={setOpenModal} food={food} setItem={setItem} item={item} handleOptions={handleOptions} cart={cart} setCart={setCart} />
                )
            }
            <OrderOptionsPopup openModal={openModal} setOpenModal={setOpenModal} food={food} setItem={setItem} item={item} handleOptions={handleOptions} cart={cart} setCart={setCart} />
        </div>
        // <div className="sm:flex md:flex w-full border-b justify-between items-center py-4">
        //     <div>
        //         <div className=''>
        //             <p className="text-md md:text-lg font-semibold mr-2 mb-1 md:mb-0 text-black text-gray-700">{props.name}</p>
        //             <p className="sm:text-gray-700 md:text-gray-700 ml-2">{props.food.description}</p>
        //             {/* <div className="flex items-center justify-start mb-1">
        //                 {food?.options?.map((el, index) => (
        //                     <p key={index} className="text-xs font-semibold border-2 border-purple-700 rounded-full text-purple-700 bg-purple-200 mr-2 px-3 py-1">{el.name}</p>
        //                 ))}
        //             </div> */}
        //         </div>

        //         {/* <div className='flex items-center mt-2'>
        //             {food?.foodAllergy && food.foodAllergy?.length > 0 ? <span className='mr-2'>Allergies:</span> : null}
        //             {food?.foodAllergy?.map((el, index) => (
        //                 <p key={index} className="text-xs font-semibold border-2 border-purple-700 rounded-full text-purple-700 bg-purple-200 mr-2 px-3 py-1">{el.name}</p>
        //             ))}
        //         </div> */}
        //     </div>
        //     <div className="flex items-center justify-between sm:px-6 md:px-6">
        //         <p className="ml-4 md:ml-0 text-sm md:text-md mr-8 w-32 text-gray-600">
        //             <b className="text-gray-800 "> £{Number.parseFloat(props.price).toFixed(2)}</b>
        //         </p>
        //         {
        //             food.options && food.options?.length > 0 ? (
        //                 <div onClick={() => handleOptions(item)} className="flex items-center">
        //                     <div className="cursor-pointer bg-red-200 border-2 border-red-700 hover:bg-red-700  rounded-full overflow-hidden flex items-center">
        //                         <FiPlus className=" text-red-700 hover:text-white p-1 text-xl" />
        //                     </div>
        //                 </div>
        //             ) : (
        //                 <div onClick={() => handleClick(item)} className="flex items-center mt-2">
        //                     <div className="cursor-pointer bg-red-200 border-2 border-red-700 hover:bg-red-700  rounded-full overflow-hidden flex items-center">
        //                         <FiPlus className=" text-red-700 hover:text-white p-1 text-xl" />
        //                     </div>
        //                 </div>
        //             )
        //         }

        //     </div>
            // <OrderOptionsPopup openModal={openModal} setOpenModal={setOpenModal} food={food} setItem={setItem} item={item} handleOptions={handleOptions} cart={cart} setCart={setCart} />
        // </div>
    )
}
