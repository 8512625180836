import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTimes } from "@fortawesome/free-solid-svg-icons";

import FoodOptions from "./FoodOptions.js";
import FoodAllergy from "./FoodAllergy.js";
import Button from "../../../../../components/utilities/Button.js";
import Toggle from "../../../../../components/utilities/form/Toggle.js";

import {
  deleteFood,
  getFoodsByCategory,
  getSingleFood,
} from "../../../../../redux/actions/foods.js";
import { getCookie } from "../../../../../components/utilities/cookie/cookie.js";

const MenuItems = ({ food, setIsMenuEdit }) => {
  const dispatch = useDispatch();
  let OptionsDOM = [];
  let AllergyDOM = [];
  const id = food._id;
  const token = getCookie("@partner_token");
  const { category } = useSelector((state) => state.categories);
  const categoryId = category._id;

  const handleEditState = async () => {
    await getSingleFood(dispatch, id);
    setIsMenuEdit(true);
  };

  const handleFoodDelete = async () => {
    await deleteFood(dispatch, token, id);
    await getFoodsByCategory(dispatch, categoryId);
    // console.log(id);
  };

  if (food.foodAllergy && food.foodAllergy.length > 0) {
    AllergyDOM = food.foodAllergy.map((el, i) => (
      <FoodAllergy key={i} el={el} />
    ));
  }

  if (food.options && food.options.length > 0) {
    OptionsDOM = food.options.map((el, i) => <FoodOptions key={i} el={el} />);
  }

  return (
    <div className="flex flex-wrap w-full border-b-2 border-gray-400 justify-between py-6">
      <div className="w-full md:w-2/3">
        <div className="flex items-center">
          <p className="font-sm text-gray-800 text-lg">{food.name}</p>
          <div className="px-3">
            <Toggle checked={food.isAvailable} />
          </div>
        </div>
        <div className="flex my-2">
          <span>{food.description}</span>
          {food.foodAllergy.length > 0 ? AllergyDOM : null}
        </div>
        {food.options && food.options.length > 0 ? (
          <div className="flex items-center border-t mt-2 pt-2 w-full">
            {OptionsDOM}
          </div>
        ) : null}
      </div>
      <div className="flex items-center justify-between w-full md:w-auto md:px-6 mt-4 md:mt-0">
        <p className="text-sm mr-8 w-32 text-gray-600">
          From <b className="text-gray-800"> £{food.basePrice}</b>
        </p>
        <Button
          className="mx-4 text-sm text-gray-500 hover:text-red-700 transition-all"
          onClick={handleEditState}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        <Button
          className="text-sm text-gray-500 hover:text-red-700 transition-all"
          onClick={handleFoodDelete}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    </div>
  );
};

export default MenuItems;
