import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import OrderSummuryItems from "./OrderSummuryItems";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrder } from "../../../redux/actions/order";
import { getRestaurantOwnInfo } from "../../../redux/actions/restaurantInfo";
import { getRestaurantAddress } from "../../../redux/actions/address.js";
import { getServiceCharge } from "../../../redux/actions/serviceCharge";
import FoodAppsLogo from "../../../images/logo.png";
import moment from "moment";

const OrderInvoice = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.order);
  const { restaurantInfo } = useSelector((state) => state?.restaurantInfo);
  const { address } = useSelector((state) => state.address);
  const [value, onChange] = useState(new Date());
  // console.log(address);

  const { id } = useParams();
  let orderId = 0;
  if (id) {
    orderId = id;
  } else orderId = props.orderId;

  const token = getCookie("@partner_token");
  let history = useHistory();

  let cart = order?.cartItems ? JSON.parse(order.cartItems) : {};
  useEffect(() => {
    getRestaurantAddress(dispatch, token, id);
  }, [dispatch, id, token]);

  const discount =
    !(order.orderType === "Dine In") &&
    (order?.discount
      ? cart.subTotal * (order?.discount?.discountValue / 100)
      : 0);
  useEffect(() => {
    getSingleOrder(dispatch, orderId, token);
  }, [dispatch, orderId, token]);

  const handleAddMore = () => {
    localStorage.setItem("cart", order.cartItems);

    history.push(`/admin-in-house-orders/${order._id}`);
  };
  useEffect(() => {
    getRestaurantOwnInfo(dispatch, token);
    getServiceCharge(dispatch);
  }, [dispatch, token]);

  return (
    <div className="w-400 mx-auto" ref={ref}>
      {/* <div className="flex justify-center">
        <Link to="/admin">
          <img className="h-10 md:h-16" src={FoodAppsLogo} alt="" />
        </Link>
      </div> */}
      <div className="text-center text-2xl">
        <h2>{restaurantInfo.name}</h2>
        <h2 className="text-sm">
          {address.address},{restaurantInfo.postCode}
        </h2>
        {/* <h2>{restaurantInfo.postCode}</h2> */}
        {/* <DateTimePicker onChange={onChange} value={value} /> */}
        {/* <MyOrderInfo className="text-left"
              title="Date :"
              value={moment(order.createdAt).format("DD-MM-YYYY")}
              
            />
           */}
      </div>
      {/* <h5 className="mb-2">Order details :</h5> */}
      <div className="flex justify-between">
        <div>
          <p className="text-black text-sm">
            <span className="font-semibold text-md ">
              {order?.customerName}
            </span>
          </p>
          <p className="text-black text-sm">
            <span className="font-semibold">{order?.mobileNo}</span>
          </p>
          <p className="text-black font-semibold text-sm">{order.orderType}</p>
        </div>

        <div className="pr-10">
          <p className="text-black text-sm text-right">
            {" "}
            <span className="font-semibold">{order.orderNumber}</span>
          </p>

          <p className="text-right text-sm text-black">
            <span className="font-semibold">{order.orderStatus}</span>
          </p>
          {order?.deliveredAt && (
            <p className="text-right text-sm text-black">{order.deliveredAt}</p>
          )}

          {order?.postCode && (
            <p className="text-right text-sm text-black">{order.postCode}</p>
          )}

          {order?.orderType === "Dine In" && (
            <p className="font-semibold">
              <span className="text-black">Table NO: </span>
              {order?.tableNo}
            </p>
          )}
        </div>
      </div>

      <p className="font-semibold ">
        <span className="text-black  text-sm ">Payment Type : </span>
        <span className="text-black  text-sm">{order.paymentMethod}</span>
      </p>
      <p className="font-semibold ">
        <span className="text-black  text-sm ">Order Date-Time : </span>
        <span className="text-black  text-sm">
          {moment(order.createdAt).format("lll")}
        </span>
      </p>
      {order?.orderType !== "Dine In"}
      {/* {order?.orderType !== "Dine In" && <OrderTimeLine order={order} />} */}

      <div className="border-b-2 border-black" />

      <div className="flex justify-between">
        {/* <div>
                <p className="font-semibold text-xl pb-1">
                  {order.restaurant && order.restaurant.name}
                </p>
                <p className="font-medium">
                  {order.restaurant?.address?.address}
                </p>
              </div>
              <div>
                {/* <p className="font-semibold text-xl pb-1">{order.restaurant?.name}</p> *
                <p className="font-semibold text-right text-sm text-gray-600">
                  {order.address}
                </p>
              </div> */}
      </div>

      {order.kitchenNotes ? (
        <p className="text-sm font-semibold">
          Kitchen Notes:{" "}
          <span className="text-black">{order.kitchenNotes}</span>
        </p>
      ) : null}

      <h5 className="text-black font-semibold">Items</h5>
      {cart.foodItems && cart.foodItems.length > 0
        ? cart.foodItems.map((el, index) => (
            <OrderSummuryItems
              value={el.price}
              itemName={el.name}
              key={index}
              quantity={el.quantity}
              options={el.options.toString()}
            />
          ))
        : null}

      <div className="border-b-2 border-gray-400" />

      <OrderSummuryItems
        value={order.subTotal}
        itemName="Sub Total"
        quantity={0}
      />

      {order.discount ? (
        <OrderSummuryItems
          value={discount}
          itemName="Discount"
          isNegative={true}
          quantity={0}
        />
      ) : null}
      {order.orderType  === "Dine In" && (
        <OrderSummuryItems
          value={order.dineInCharge}
          quantity={0}
          itemName="Dine In Charge"
        />
      )}

      {order.coupon && (
        <OrderSummuryItems
          itemName="Coupon"
          value={order.coupon?.amount}
          quantity={0}
          isNegative={true}
        />
      )}
      {order?.orderType === "Dine In" && (
        <OrderSummuryItems
          value={order.serviceCharge}
          quantity={0}
          itemName="Service Charge"
        />
      )}
      <OrderSummuryItems
        value={order.partnerFACharge}
        quantity={0}
        itemName="Order Charge"
      />

      {order?.orderType === "Home Delivery" && (
        <OrderSummuryItems
          value={order.deliveryCharge}
          quantity={0}
          itemName="Delivery Charge"
        />
      )}

      <div className="border-b-2 border-gray-400" />
      {(order?.dineInCharge === undefined) && (
      <OrderSummuryItems
        value={order.subTotal + order.serviceCharge}
        itemName="Total"
        quantity={0}
      />
       )}
       {(order?.dineInCharge !== undefined) && (
      <OrderSummuryItems
        value={order.subTotal + order.dineInCharge}
        itemName="Total"
        quantity={0}
      />
       )}
    </div>
  );
});

export default OrderInvoice;
