import React from "react";
import { Redirect, Route } from "react-router";
import { useJwt } from 'react-jwt'
import { getCookie } from "../components/utilities/cookie/cookie";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token=getCookie('@partner_token')
    const { isExpired } = useJwt(token);
  // console.log(isExpired,getCookie('@partner_token'))
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
      (!isExpired) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
