import React from 'react';
import { useState } from 'react';
import { getCookie } from "../../../../components/utilities/cookie/cookie.js";
import { getRestaurantDiscount, updateDiscount } from '../../../../redux/actions/discount.js';
import { useDispatch, useSelector } from "react-redux";

const UpdateDiscount = ({ setEditable }) => {
    const { discount } = useSelector(state => state.discount);
    const { restaurantInfo } = useSelector(state => state.restaurantInfo);
    const restaurantId = restaurantInfo._id;


    const discountId = discount && discount?._id;

    const [formData, setFormData] = useState({
        discountValue: discount.discountValue,
        validationExpire: discount.validationExpire
    })

    const dispatch = useDispatch();

    const token = getCookie("@partner_token");

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await updateDiscount(dispatch, token, formData, discountId);
        await getRestaurantDiscount(dispatch, restaurantId);
        setEditable(false);
    };

    return (
        <div className='flex justify-between p-5'>
            <div className="w-1/2 flex items-center px-3 ">
                <label className=" font-semibold">Discount</label>
                <input
                    placeholder="Ex: 10%"
                    className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim cursor-pointer"
                    onChange={handleChange}
                    name="discountValue"
                    value={formData.discountValue}
                    type="number"
                />
            </div>
            <div className="w-1/2 flex items-center px-3 ">
                <label className=" font-semibold">Expire Date</label>
                <input
                    placeholder="Example: 1.50 £"
                    className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim cursor-pointer"
                    onChange={handleChange}
                    name="validationExpire"
                    type="date"
                    value={formData.validationExpire}
                />
            </div>
            <div className="flex justify-end mr-8">
                <button
                    className="px-4 py-2 text-white bg-red-700 rounded-lg "
                    onClick={handleSubmit}
                >
                    Update
                </button>
            </div>
        </div>
    )
}

export default UpdateDiscount
