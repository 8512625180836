// import React,{useState} from 'react';
// export default function LoginFormFooter() {
//     const [authState,setAuthState]=useState("");

//     const setSignUp=(type)=>{
//         setAuthState(type);
//     }
//     return (
//         <div className="flex justify-center py-4">
//             {authState !== "signup" ?
//             <div className="text-center text-gray-700 text-xs font-bold">
//                 <span>Don't have an account? </span>
//                 <button onClick={()=>setSignUp("signup")} className="cursor-pointer text-prim font-bold outline-none">Signup</button>
//             </div>
//             :
//             <div className="text-center text-gray-700 text-xs font-bold">
//                 <span>Already have an account? </span>
//                 <button onClick={()=>setSignUp("login")} className="cursor-pointer font-bold text-prim outline-none">Login</button>
//             </div>
//             }
//         </div>
//     )
// }

import React from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {logIn, signUp} from '../../redux/reducer/form';
// import {toggleError} from '../../redux/reducer/customer';

export default function LoginFormFooter() {
    const {type} = useSelector(state => state.form);

    const dispatch = useDispatch();

    const handleSignUp = () => {
        dispatch(signUp());
        // dispatch(toggleError());
    }

    const handleLogin = () => {
        dispatch(logIn());
        // dispatch(toggleError());
    }
    const handleReset = () => {
        
    }

    return (
        <div className="flex justify-center py-2">
            {type !== "signup" ?
                <div className="text-center text-gray-700 text-xs font-bold">
                    <div className="mb-2">
                        <span>Forgot Password? </span>
                        <button onClick={handleReset} className="cursor-pointer text-prim font-bold outline-none">Reset
                        </button>
                    </div>
                    <div>
                        <span>Don't have an account? </span>
                        <button onClick={handleSignUp} className="cursor-pointer text-prim font-bold outline-none">Signup
                        </button>
                    </div>
                    
                </div>
                :
                <div className="text-center text-gray-700 text-xs font-bold">
                    <span>Already have an account? </span>
                    <button onClick={handleLogin} className="cursor-pointer font-bold text-prim outline-none">Login
                    </button>
                </div>
            }
        </div>
    )
}
