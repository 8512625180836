import React from 'react'


const FoodOptionBottom = (props) => {
    const { formData, setFormData, setOption, option, setType, setShowType, setCancel } = props;


    const handleCancel = () => {
        setFormData({ ...formData, options: [] })
        setOption({ ...option, choiceCount: 0, name: "", types: [] })
        setType({ typeName: "", price: 0, isAdded: false })
        setCancel(false)
        setShowType(false)
    }

    const handleAddOption = async (e) => {
        e.preventDefault();
        const updatedOption = { ...option };
        setFormData({ ...formData, options: [...formData.options, updatedOption] })
        setOption({ ...option, choiceCount: 0, name: "", types: [] })
        setCancel(false)
        setShowType(false)
    }

    return (
        <div className="flex justify-end mt-3">
            <button className=" mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleAddOption}>Save</button>
            <button className=" mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleCancel}>Cancel</button>
        </div>
    )
}

export default FoodOptionBottom
