import React, { useState } from "react";
// import PaymentOptionCard from "../checkout/PaymentOptionCard";
// import AgreementCheck from "../utilities/form/AgreementCheck";
// import ErrorMessage from "../utilities/form/ErrorMessage"
// import { placeDineOrder } from "../../redux/actions/order";
// import { useHistory } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getCookie } from "../utilities/cookie/cookie";
// import CheckoutTimeSchedule from "../../pages/partner/Checkout/CheckoutTimeSchedule";
// import CheckoutContact from "../../pages/partner/Checkout/CheckoutContact";
// import CheckoutTableNumber from "../../pages/partner/Checkout/CheckoutTableNumber";
// import CheckoutDeliveryAddress from "../../pages/partner/Checkout/CheckoutDeliveryAddress";

export default function CartCheckout(props) {

  // let { options } = props;
  // const history = useHistory();
  // const restaurantId = JSON.parse(localStorage.getItem('order')).restaurant;
  
  // const restaurantUniId = JSON.parse(localStorage.getItem('order')).restaurantUniId;
  // const localOrder = JSON.parse(localStorage.getItem('order'))
  // const cart = JSON.parse(localStorage.getItem('cart'));
  // const [check, setCheck] = useState(false)
  // const [selectedType, setSelectedType] = useState(localStorage.getItem('order') ? JSON.parse(localStorage.getItem('order'))?.orderType : options[0]);

  // const [option, setOption] = useState("Counter")
  // const dispatch = useDispatch();
  // const [order, setOrder] = useState({
  //   "restaurant": restaurantId,
  //   "restaurantUniId": restaurantUniId,
  //   "cartItems": JSON.stringify(cart),
  //   "discount": null,
  //   "coupon": null,
  //   "orderType": "Dine In",
  //   "tableNo": localStorage.getItem("tableNo") || "",
  //   "customerName": "",
  //   "mobileNo": "",
  //   "subTotal": cart.subTotal,
  //   "totalPrice": 0,
  //   "paymentMethod": option,
  //   "paymentStatus": "Pending",
  //   "kitchenNotes": "",
  //   "deliveredAt": "",
  //   "deliveryCharge": 0,
  //   "fromRestaurant": true,
  // })
  // const [localCard, setLocalCard] = useState({
  //   total: localOrder?.totalPrice,
  //   cardNumber: "",
  //   exp_month: "",
  //   exp_year: "",
  //   cvc: "",
  // });
  // const { error } = useSelector(state => state.order)
  
  // const token = getCookie('@partner_token')

  // useEffect(() => {
  //   setOrder({...order, paymentMethod: option})    
  // }, [option])

  // localStorage.setItem('order', JSON.stringify(order))
  
  // const placeDineInOrder = async (e) => {
  //   e.preventDefault();
  //     if(check) {
  //       if(localStorage.getItem('tableNo')) {
  //         await placeDineOrder(dispatch, token, option, localCard, history);
  //       } else {
  //         alert("Please input table number")
  //       }
  //     } else {
  //       alert("Please accept the Terms and Conditions")
  //     }
  // }

  return (
    <div>
      
              {/* <div className="w-full bg-white ">
                <CheckoutContact order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white my-6">
                <CheckoutTableNumber />
              </div>
              <div className="w-full bg-white ">
                <CheckoutDeliveryAddress order={order} setOrder={setOrder} />
              </div>
     <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
              <PaymentOptionCard
                options={["Card", "Cash"]}
                localCard={localCard}
                setLocalCard={setLocalCard}
                setOption={setOption}
                option={option}
                />
                <AgreementCheck
                  check={check}
                  setCheck={setCheck}
                  link=" Food Allergy and T&amp;C."
                />
                {error && <ErrorMessage message={error} />}
                <button className={`mt-6 bg-prim text-white w-full rounded p-2`} onClick={placeDineInOrder}>
                  Place Order
                </button>
              </div> */}
        
      <button
        onClick={props.onClick}
        style={props.containerStyle}
        className={`cursor-pointer min-w-full flex justify-between items-center h-12 bg-gray-700 font-bold py-2 px-2 pl-4 rounded-full ${props.containerClasses}`}
      >
          {/* <p className="text-white">{props.buttonText}</p> */}
          <p className="text-white">Total</p>
          <span
            style={props.price}
            className="bg-white text-gray-700 py-2 px-6 rounded-full truncate"
          >
            £{Number.parseFloat(props.totalPrice).toFixed(2)}
            {/* £ {Number.parseFloat(total).toFixed(2)} */}
            
          </span>
      </button>
      
      
     
        
    </div>
  );
}
