import React, { useEffect, useState } from "react";
// import Select from "react-select";
import { useSelector } from "react-redux";
// import { BiFoodMenu } from "react-icons/bi";
import Modal from "react-modal";
import Header from "../Epos/categories/SpecialOfferPopup/Header";

let options = [];

const CheckoutTableNumber = () => {
  const [tableNo, setTableNo] = useState(null);
  const order = JSON.parse(localStorage.getItem("order"));
  const {
    restaurantInfo: { table },
  } = useSelector((state) => state.restaurantInfo);
  options = table?.filter((item) => item.isBooking === false);
  options = options?.map((item) => {
    return {
      value: item._id,
      label: item.No,
    };
  });
  // const handleTableNo = (e) => {
  //   setTableNo(e.label);
  //   order.tableNo = Number(e.label);
  //   localStorage.setItem("order", JSON.stringify(order));
  // };

  useEffect(() => {
    localStorage.setItem("tableNo", tableNo);
    order.tableNo = Number(localStorage.getItem("tableNo"));
    localStorage.setItem("order", JSON.stringify(order));
  }, [order, tableNo]);
  // const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const customStyles = {
    content: {
      top: "30px",
      left: "auto",
      right: "auto",
      bottom: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, .1)",
    },
    modal: {
      outline: "none",
    },
  };

  function openModal() {
    setIsOpen(true);
    // setIsEdit(true);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    setIsOpen(false);
    // send state to server with e.g. `window.fetch`
  };

  return (
    <div>
      <div onClick={() => openModal()}>
        <h3 className="font-semibold text-md border-2 border-prim flex flex-col justify-center rounded-md p-3 text-center min-h-110 cursor-pointer">
          <div>Table Number</div>
          <div className="r">
            {tableNo !== null ? tableNo : "No table is selected!"}
          </div>
        </h3>
      </div>

      <Modal
        isOpen={isOpen}
        style={customStyles}
        className="w-full outline-none absolute border border-none"
        ariaHideApp={false}
      >
        <div className="w-full h-screen">
          <div className="w-full h-full flex items-center justify-center">
            <div className="w-full sm:w-1/3 md:w-1/3 mx-8 sm:mx-0 md:mx-0 bg-white rounded-lg p-6 mt-16">
              <Header heading="Enter Table Number" setOpenModal={setIsOpen} />
              <form onSubmit={onFormSubmit}>
                <input
                  type="text"
                  className="w-full border border-red-500 my-2 rounded-md p-2"
                  onChange={(e) => setTableNo(e.target.value)}
                />

                <div className="flex justify-end my-2">
                  <button
                    className="bg-prim rounded-md px-5 py-2 text-white"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CheckoutTableNumber;
