import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getOwnRestaurantDelivery,
  updateRestaurantDelivery,
} from "../../../../../../redux/actions/restaurantDelivery.js";
import { getCookie } from "../../../../../../components/utilities/cookie/cookie.js";
import Toggle from "../../../../../../components/utilities/form/Toggle.js.js";

const Delivery = ({ setEditable }) => {
  const { restaurantDelivery } = useSelector(
    (state) => state.restaurantDelivery,
  );
  console.log(restaurantDelivery)
  const [formData, setFormData] = useState({
    isDeliveryAvailable: restaurantDelivery.isDeliveryAvailable,
    isRiderService: restaurantDelivery.isRiderService,
    areaLimit: restaurantDelivery.areaLimit,
    deliveryCharge: restaurantDelivery.deliveryCharge,
    deliveryChargeOffArea: restaurantDelivery.deliveryChargeOffArea,
    deliveryChargeOffAreaLimit: restaurantDelivery.deliveryChargeOffAreaLimit
  });
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  const handleDeliveryToggle = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      isDeliveryAvailable: !formData.isDeliveryAvailable,
    });
  };
  const handleRiderToggle = (e) => {
    e.preventDefault();
    setFormData({ ...formData, isRiderService: !formData.isRiderService });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateRestaurantDelivery(dispatch, token, formData);
    await getOwnRestaurantDelivery(dispatch, token);
    setEditable(false);
  };
  return (
    <div className="border-b-2 pb-5">
      <div className="flex flex-wrap items-center justify-between mb-4 px-8 ">
        <div className="w-1/2 sm:w-1/4 flex items-center">
          <span className="mr-2 font-semibold">Delivery Available</span>
          <div className="mt-1">
            <button onClick={handleDeliveryToggle}>
              <Toggle checked={formData.isDeliveryAvailable} />
            </button>
          </div>
        </div>
        <div className="w-1/2 sm:w-1/4 flex items-center">
          <span className="mr-2 font-semibold">Rider Service</span>
          <div className="mt-1">
            <button onClick={handleRiderToggle}>
              <Toggle checked={formData.isRiderService} />
            </button>
          </div>
        </div>
        <div className="w-1/2 sm:w-1/3 flex items-center ">
          <label className=" font-semibold">Delivery Area Limit</label>
          <input
            placeholder="Ex:1"
            type="number"
            min={1}
            className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim"
            onChange={handleChange}
            name="areaLimit"
            value={formData.areaLimit}
          />
        </div>
        <div className="w-1/2 sm:w-1/3 flex items-center">
          <label className=" font-semibold">Delivery Charge</label>
          <input
            placeholder="Example: £ 1.50"
            className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim"
            onChange={handleChange}
            name="deliveryCharge"
            value={formData.deliveryCharge}
            type="number"
          />
        </div>
        <div className="w-1/2 sm:w-1/4 sm:ml-4 flex items-center ">
          <label className=" font-semibold">  Delivery Over Area Limit</label>
          <input
            placeholder="Example:5"
            className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim"
            onChange={handleChange}
            name="deliveryChargeOffAreaLimit"
            value={formData.deliveryChargeOffAreaLimit}
            type="number"
          />
        </div>
        <div className="w-1/2 sm:w-1/3 flex items-center">
          <label className=" font-semibold">Delivery Charge Over Area</label>
          <input
            placeholder="Example: £ 1.50"
            className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim"
            onChange={handleChange}
            name="deliveryChargeOffArea"
            value={formData.deliveryChargeOffArea}
            type="number"
          />
        </div>
      </div>
      <div className="flex justify-end mr-8">
        <button
          className="px-4 py-2 text-white bg-red-700 rounded-lg "
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default Delivery;
