import React, { useState } from 'react';
import ItemOptionType from './ItemOptionType';
function ItemOption(props) {
    const { item, setItem, option } = props;
    const [count, setCount] = useState(0);
    const [isTrue, setIsTrue] = useState(false);
    setTimeout(() => setIsTrue(false), 3000)
    return (
        <>
            <div className='flex rounded-t-lg text-white font-bold bg-gray-500 px-4 py-3'>
                <p className='w-1/3 '>{option.name}</p>
                <p className='w-1/3 text-center'>Choose: {option.choiceCount}</p>
                <p className='w-1/3 text-center'>Selected: {count}</p>
            </div>
            {isTrue ? <div className='bg-red-700 text-white text-center py-3 font-bold'>Please choose {option.choiceCount} options</div> : null}
            {option.types && option.types?.map((el, index) => (
                <ItemOptionType setCount={setCount} count={count} option={option} item={item} setItem={setItem} type={el} key={index} setIsTrue={setIsTrue} />
            ))}
        </>

    )
}


export default ItemOption;