import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import Address from "../Address";
import AdminRestaurantDetails from "./AdminRestaurantDetails";
import Timings from "./../../../../pages/partner/AdminRestaurants/Timings/index";

import { getRestaurantOwnInfo } from "../../../../redux/actions/restaurantInfo";
import { getCookie } from "../../../../components/utilities/cookie/cookie";
import Delivery from './../Delivery/index';
import AdminRestaurantHeader from './AdminRestaurantHeader';
import Discount from "../Discount";

export default function AdminRestaurant() {
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getRestaurantOwnInfo(dispatch, token);
  }, [dispatch, token]);

  return (
      <div className="bg-white rounded-b-lg">
        <AdminRestaurantHeader />
        <AdminRestaurantDetails setIsEdit={setIsEdit} isEdit={isEdit} />
        <Address />
        <Timings />
        <Delivery />
        <Discount />
      </div>
  );
}
