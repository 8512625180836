import React from 'react';
import { FiPhone } from 'react-icons/fi';
export default function ContactUsButton(props) {
    return (
        <div style={props.containerStyle} className="px-4">
            <div className="flex items-center">
                {props.icon && <FiPhone className="text-red-600" />}
                <div className="pl-2 cursor-pointer text-md hover:text-prim hover:trans">Contact us</div>
            </div>
        </div>
    )
}