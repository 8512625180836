import React, { useState } from "react";
import { Link } from "react-router-dom";

import Address from "./Address";
import NameSpeciality from "./NameSpeciality";
import Timing from "./Timing";
import Delivery from "./Delivery";
import Banner from "./Banner/Banner";
import AdminLayout from "../../../../components/layout/AdminLayout";
import BusinessProfile from "../BusinessProfile";
import { useSelector } from "react-redux";

export default function CreateRestaurant() {
  const { restaurantInfo } = useSelector(state => state.restaurantInfo);
  const { address } = useSelector(state => state.address);
  const { restaurantTiming } = useSelector(state => state.restaurantTiming);
  const { restaurantDelivery } = useSelector(state => state.restaurantDelivery);
  const [state, setState] = useState({
    isName: false,
    isAddress: false,
    isTiming: false,
    isDelivery: false,
  });
  return (
    <>
      <AdminLayout>
        <BusinessProfile />
        <div className="w-full bg-white py-5 px-6 pt-1 border-b-2">
          <p className="text-lg w-32 text-black font-semibold border-b-2 mb-3 sm:w-full">
            Create Restaurant
          </p>

          <div className="flex items-center flex-wrap">
            <div className="w-full">
              <div className="flex items-center">
                <div className="w-full px-0 truncate">
                  <NameSpeciality setState={setState} state={state} />
                  <div className="w-full">
                    <Banner />
                  </div>
                  {/* <Type /> */}
                  {restaurantInfo && state.isName && <Address setState={setState} state={state} />}
                  {address && state.isAddress && <Timing setState={setState} state={state} />}
                  {restaurantTiming && state.isTiming && <Delivery setState={setState} state={state} />}

                  {restaurantInfo &&
                    address &&
                    restaurantTiming &&
                    restaurantDelivery &&
                    state.isName &&
                    state.isAddress &&
                    state.isTiming &&
                    state.isDelivery ? (
                    <div className="flex justify-center items-center px-20 py-10 border-2 border-red-700 rounded-lg mt-3 bg-red-100">
                      <span>
                        <span className="font-bold text-red-700">
                          Congratulation!!
                        </span>
                        <span className="font-semibold ml-2">
                          You Have created Your Restaurant!!!
                        </span>
                      </span>
                      <Link
                        to="/admin"
                        className="px-6 py-3 bg-red-700 text-white font-bold rounded-lg ml-3"
                      >
                        Visit Your Restaurant
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
}
