import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
// import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import OrderSummary from "../../pages/partner/AdminOrderSummury/OrderSummary";

import {
  AcceptOrder,
  DeclineOrder,
  getRestaurantsOrdersByOrderStatus,
} from "../../redux/actions/order";
import { getCookie } from "../utilities/cookie/cookie";

import BookingStatus from "../booking/bookingHeader/BookingStatus";
import MyOrderInfo from "./MyOrderInfo";
import Button from "../utilities/buttons/Button";
import OrderInvoice from "../../pages/partner/AdminOrderSummury/OrderInvoice";

export default function MyOrder(props) {
  const { order } = props;
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  const { error: cardError } = useSelector((state) => state.card);
  let history = useHistory();
  let componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const handleAccept = async (orderId) => {
    await AcceptOrder(dispatch, token, orderId);
    // toast.error(cardError);
    getRestaurantsOrdersByOrderStatus(dispatch, token, "Pending");
  };
  const handleDecline = async (orderId) => {
    await DeclineOrder(dispatch, token, orderId);
    getRestaurantsOrdersByOrderStatus(dispatch, token, "Pending");
  };
  const handleAddMore = () => {
    localStorage.setItem("cart", order.cartItems);
    localStorage.setItem("editOrder", "Yes");
    history.push(`/admin-in-house-orders/${order._id}`);
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="w-full p-2">
        <div
          style={props.containerStyle}
          className={`rounded-lg border border-red-700 p-2 px-4 ${props.containerClasses}`}
        >
          <Link to={`/order-summary/${order._id}`}>
            <div className="py-2 flex text-xs items-center justify-between">
              <p className="text-sm font-bold">{order.orderNumber}</p>
              {/* {order.orderType === "Dine In" && order.orderStatus === "Pending" && (
                <BookingStatus
                  status="Editable"
                  containerClasses="text-white bg-blue-400 px-3"
                />
              )} */}
              <BookingStatus
                status={order.orderStatus}
                containerClasses="text-red-700 bg-orange-300 px-3"
              />
            </div>
            <MyOrderInfo
              title="Order Time"
              value={moment(order.createdAt).format("h:mm A")}
            />
            <MyOrderInfo
              title="Order Date"
              value={moment(order.createdAt).format("DD-MM-YYYY")}
            />
            <MyOrderInfo title="Order Type" value={order.orderType} />
            <MyOrderInfo title="Table Number" value={order?.tableNo} />
            <MyOrderInfo title="Payment Method" value={order.paymentMethod} />
            <MyOrderInfo
              title="Item Price"
              value={`₤ ${Number.parseFloat(order?.totalPrice).toFixed(2)}`}
              containerClasses="border-b-2 border-gray-400"
            />
            <MyOrderInfo
              title="Total"
              value={`₤ ${Number.parseFloat(order?.totalPrice).toFixed(2)}`}
            />
          </Link>

          {(order.orderStatus === "Pending" && order.fromRestaurant === true) && (
            <div className="flex justify-between">
              
                <ReactToPrint
                  trigger={() => (
                    <Button variant="sm" bgColor="bg-green-600">
                      Serve
                    </Button>
                  )}
                  content={() => componentRef}
                  onAfterPrint={() => handleAccept(order._id)}
                />
              
              <div className="hidden">
                <OrderInvoice
                  orderId={order._id}
                  ref={(el) => (componentRef = el)}
                />
              </div>
              
                <Button onClick={handleAddMore} variant="sm" classes="p-0">
                E dit
              </Button>
              
            </div>
          )}
          {(order.orderStatus === "Pending" && order.fromRestaurant === false) && (
            <div className="flex justify-between">
              <Button
                variant="sm"
                bgColor="bg-green-600"
                onClick={() => handleAccept(order._id)}
              >
                Accept
              </Button>
              <Button
                onClick={() => handleDecline(order._id)}
                variant="sm"
                classes="p-0"
              >
                Decline
              </Button> 
            </div>
          )}
        </div>
      </div>
    </>
  );
}
