import {
    GET_ALL_CUISINE_SUCCESS, GET_ALL_CUISINE_FAILURE
  } from "../types/cuisine/type";
  
  const initialState = {
    cuisines: [],
    status: null,
    error: "",
    loading: true,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_CUISINE_SUCCESS:
        return {
          ...state,
          loading: false,
          status: "success",
          cuisines: action.payload,
          error: "",
        };
      case GET_ALL_CUISINE_FAILURE:
        return {
          ...state,
          loading: false,
          status: "Failed",
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  