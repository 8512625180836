import React from 'react'

import BookingStatus from './BookingStatus'
import BookingTitle from './BookingTitle'

export default function BookingHeader({ booking }) {
    return (
        <div className="w-full flex justify-between border-b border-gray-400 pb-1">
            <BookingTitle title={booking.rest} />
            <div className="flex items-center">
                <BookingStatus status={booking.orderStatus} containerClasses="bg-orange-400" />                
            </div>
        </div>
    )
}
