import React from 'react'

const TypeInput = (props) => {
    const { setType, type, option, setOption, setShowType } = props;

    const handleTypeChange = (e) => {
        e.preventDefault();
        setType({ ...type, [e.target.name]: e.target.value });

    }

    const handleTypeAdd = (e) => {
        e.preventDefault();
        setType({ ...type, isAdded: true })
        const updatedType = [...option?.types];
        updatedType.push(type)
        setOption({ ...option, types: updatedType })
        setShowType(false)
    }
    return (
        <div className="mt-2">
            <h1 className="text-sm font-semibold ml-2">Types</h1>
            <div className="py-3 w-full flex items-center bg-red-200 mt-2">
                <input type="text" className="w-1/2 mx-2 py-2 px-2" value={type.typeName} name="typeName" placeholder="Name" onChange={handleTypeChange} />
                <input type="text" className="w-1/3 mx-2 py-2 px-2" value={type.price} name="price" placeholder="price" onChange={handleTypeChange} />
                <button className="w-1/3 mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleTypeAdd}>Add</button>
            </div>
        </div>
    )
}

export default TypeInput
