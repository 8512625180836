import {
  GET_ADDRESS_FAILURE,
  GET_ADDRESS_SUCCESS,
} from "../types/address/type";

import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const createRestaurantAddress = async (dispatch, token, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/address/create-restaurant-address`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_ADDRESS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ADDRESS_FAILURE, payload: error });
  }
};

export const getRestaurantAddress = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/address/get-restaurant-address/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_ADDRESS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ADDRESS_FAILURE, payload: error });
  }
};

export const updateRestaurantAddress = async (
  dispatch,
  token,
  formData,
  addressId,
) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/address/update-restaurant-address/${addressId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_ADDRESS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ADDRESS_FAILURE, payload: error });
  }
};
