import {
    GET_ALL_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_SUCCESS,
    GET_ALL_NOTIFICATION_FAILURE,
    DELETE_NOTIFICATION_FAILURE
} from "../types/notification/type";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const getRestaurantNotifications = async (dispatch, token) => {
    try {
        const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/notification/get-restaurant-notification`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        if (data) {
            dispatch({ type: GET_ALL_NOTIFICATION_SUCCESS, payload: data })
        }
    } catch (err) {
      const {
        response: {
          data: { error },
        },
      } = err;
      dispatch({ type: GET_ALL_NOTIFICATION_FAILURE, payload: error });
    }
  };

export const deleteNotification = async (dispatch, token, id) => {
    try {
      const {
        data: { data },
      } = await axios(
        `${apiBaseUrl}${apiVersionPrefix}/notification/remove-restaurant-notification/${id}`,
        {
          method: "PUT",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
            },
        }
      );
      if (data) {
        dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: data });
      }
    } catch (err) {
      const {
        response: {
          data: { error },
        },
      } = err;
      dispatch({ type: DELETE_NOTIFICATION_FAILURE, payload: error });
    }
};
