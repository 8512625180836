import React, { useState } from 'react'

const ItemOptionType = (props) => {
    const { item, setItem, type, option, count, setCount, setIsTrue } = props;
    const [isSelected, setIsSelected] = useState(false)

    const handleAddOptions = () => {

        if (count + 1 > option.choiceCount) {
            setIsTrue(true)
        } else {
            setIsSelected(true)
            const updatedItem = { ...item };
            setIsTrue(false)
            setCount(count + 1);
            if (!updatedItem.options.find(el => el === type.typeName)) {
                updatedItem.options.push(type.typeName);
                updatedItem.optionsPrice += type.price;
            }
            const itemTotalPrice = updatedItem.basePrice + updatedItem.optionsPrice;
            setItem({ ...updatedItem, price: itemTotalPrice, optionsPrice: updatedItem.optionsPrice, options: updatedItem.options })
        }

    }
    const removeOptions = () => {
        setIsSelected(false)
        const updatedItem = { ...item };
        setCount(preState => preState - 1);
        updatedItem.options = updatedItem.options.filter(el => el !== type.typeName)
        updatedItem.optionsPrice = updatedItem.optionsPrice - type.price;
        updatedItem.price = updatedItem.price - type.price;
        setItem({ ...updatedItem, optionsPrice: updatedItem.optionsPrice, options: updatedItem.options })
    }
    return (
        <div>
            {
                !isSelected ? (
                    <div className="px-4 py-2 border-b-2 hover:text-gry-700 flex items-center justify-between  cursor-pointer " onClick={handleAddOptions}>
                        <div className="flex items-center justify-between w-full pr-4">
                            <p className="text-lg font-semibold">{type.typeName}</p>
                            <p className="text-xs font-bold">+ £{type.price}</p>
                            <div className='px-2 py-2 rounded-full border-2 border-red-700'>
                                <div></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="px-4 py-2 border-b-2 hover:text-red-700 flex items-center justify-between cursor-pointer" onClick={removeOptions}>
                        <div className="flex items-center justify-between w-full pr-4">
                            <p className="text-lg font-semibold">{type.typeName}</p>
                            <p className="text-xs font-bold">+ £{type.price}</p>
                            <div className='px-1 py-1 rounded-full border-2 border-red-700 '>
                                <div className='p-1 rounded-full bg-red-700'></div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ItemOptionType
