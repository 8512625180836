import {
    GET_COUPON_SUCCESS,
    GET_COUPON_FAILURE,
} from "../types/coupon/types";

const initialState = {
    coupon: {},
    couponStatus: null,
    couponError: null,
    loading: true
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                couponStatus: "success",
                coupon: action.payload,
                couponError: ""
            };

        case GET_COUPON_FAILURE:
            return {
                ...state,
                loading: false,
                coupon: {},
                couponStatus: "Failed",
                couponError: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;