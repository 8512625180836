import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../../components/utilities/cookie/cookie";
import { createRestaurant } from "../../../../../redux/actions/restaurantInfo";
import Message from "../../../../../components/admin/admin/Message";

export default function NameSpeciality({ setState, state }) {
  const [formData, setFormData] = useState({
    name: "",
    speciality: [],
    capacity: "",
  });
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  const [inputForm, setInputForm] = useState({
    name: "",
    index: "",
  });

  const handleInputFormChange = (e) => {
    e.preventDefault();
    setInputForm({ ...inputForm, name: e.target.value });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const string = inputForm.name;
      setFormData({
        ...formData,
        speciality: [...formData.speciality, string],
      });
      setInputForm({ name: "" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createRestaurant(dispatch, formData, token);
    setState({ ...state, isName: true });
  };

  const handleDelete = (el) => {
    const updatedSpeciality = formData.speciality.filter((item) => item !== el);
    setFormData({ ...formData, speciality: updatedSpeciality });
  };

  const { restaurantInfo } = useSelector(state => state.restaurantInfo);
  // console.log(restaurantInfo)

  if (restaurantInfo._id) {
    return (
      <Message value="Profile" />
    )
  }

  return (
    <form onSubmit={handleSubmit} className="block border-b-2 pb-2">
      <div className="w-full">
        <div className="w-full">
          <label className="text-xs font-bold block mb-2"> Name:</label>
          <input
            placeholder="Restaurant Name"
            className="bg-gray-100 h-12 w-full text-2xl outline-none border border-red-700 rounded-lg px-3 py-2"
            value={formData.name}
            onChange={handleChange}
            name="name"
          />
        </div>
        <div className="flex items-center">
          <div className="w-1/2 mr-2">
            <label className="text-xs font-bold">Specialities:</label>
            <div className="mt-2 flex items-center rounded-lg border border-red-700 py-1 px-2 overflow-hidden">
              {formData.speciality && formData.speciality.length > 0
                ? formData.speciality.map((el, i) => (
                  <div className="mr-1" key={i} index={i}>
                    <div className="cursor-pointer px-1 pl-2 font-bold p-1 flex items-center text-white rounded-full bg-prim">
                      <p className="text-xs">{el}</p>
                      <div className="ml-1 p-1 rounded-full bg-white text-black">
                        <FiX onClick={() => handleDelete(el)} />
                      </div>
                    </div>
                  </div>
                ))
                : null}

              <input
                placeholder="Type and press Enter to add!"
                className="flex-grow outline-none px-3 py-2"
                onChange={handleInputFormChange}
                onKeyPress={handleKeyPress}
                value={inputForm.name}
              />
            </div>
          </div>
          <div className="w-1/2 ml-2">
            <label className=" block text-xs font-bold pt-2 ">
              Booking Capacity:
            </label>
            <input
              placeholder="Ex:50"
              className="w-full mt-2 h-12 outline-none py-2 px-4 rounded-lg border border-prim"
              value={formData.capacity}
              onChange={handleChange}
              name="capacity"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-4 ml-2 justify-end">
        <button
          type="submit"
          className="py-2 px-4 bg-red-700 text-white rounded-lg"
        >
          Save
        </button>
      </div>
    </form>
  );
}
