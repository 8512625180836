import React, { useEffect, useState } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { getCookie } from '../../../components/utilities/cookie/cookie';
import { getBookingAllOrders, getBookingPendingOrders } from './../../../redux/actions/order';

import Booking from '../../../components/booking';
import AdminLayout from '../../../components/layout/AdminLayout';
import Heading from '../../../components/utilities/Heading';
import Tabs from '../../../components/utilities/Tabs';

const menus = ["All Bookings", "Pending"]
export default function AdminBookings() {
    const [selectedTab, setSelectedTab] = useState(menus[0]);
    const dispatch = useDispatch();
    const token = getCookie("@partner_token");

    useEffect(() => {
        if(selectedTab === menus[0]){
            getBookingAllOrders(dispatch, token);
        }
        else if(selectedTab === menus[1]){
          getBookingPendingOrders(dispatch, token);
        }  
      }, [selectedTab, dispatch, token])  
  const { orders, error } = useSelector((state) => state.order);

    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps Partner | Bookings</title>
            </Helmet>
            <AdminLayout>
                <Heading>Bookings</Heading>
                <div className="mt-4">
                    <div className="sm:flex md:flex items-center justify-between">
                        <Tabs variant="prim" tabs={menus} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                    </div>
                </div>
                <div className="mt-4 w-full">
                    {orders && orders.length > 0 ? (
                        orders.map((el, index) => (
                            <Booking booking={el} key={index} />
                        ))
                    ) : (
                        <span className="text-red-700 font-bold text-lg">
                            {error? error: "No orders is found!"}
                        </span>
                    )}
                </div>
            </AdminLayout>
        </HelmetProvider>
    )
}