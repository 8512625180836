import React, { useState } from "react";

import { useEffect } from "react";
import { FiEdit2, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";


import { getCookie } from "../../../../components/utilities/cookie/cookie.js";
import Icon from "../../../../components/utilities/Icon";
import { getRestaurantAddress } from "../../../../redux/actions/address.js";
import EditRestaurantDetailsAddress from '../AdminRestutantDetails/AdminRestaurantDetails/EditRestaurantDetails/Address'

export default function Address() {
  const dispatch = useDispatch();
  const { restaurantInfo } = useSelector((state) => state?.restaurantInfo);
  const id = restaurantInfo._id;
  const token = getCookie("@partner_token");
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    getRestaurantAddress(dispatch, token, id);
  }, [dispatch, id, token]);

  const { address } = useSelector((state) => state.address);

  return (
    <div className="flex flex-wrap border-b-2 border-gray-400 pb-6 pt-1 mt-3 px-3 sm:px-0">
      <div className="w-full flex justify-between">
        <div className="border-b-2 border-gray-400 pb-2">
          <p className="md:px-6 text-lg font-bold">Address</p>
        </div>
        <div className="flex items-center w-full justify-end mr-5">
          {!isEdit ? (
            <Icon onClick={() => setIsEdit(true)} icon={FiEdit2} />
          ) : (
            <div className="flex justify-between">
              <Icon onClick={() => setIsEdit(false)} icon={FiX} />
              {/* <Icon icon={FiCheck} /> */}
            </div>
          )}
        </div>
      </div>
      {!isEdit && (
        <div className="flex mt-2 w-full md:w-1/3 md:px-6 py-1">
          <p className="text-sm text-gray-600 truncate">{address.address}</p>
          <p className="text-sm text-gray-600 truncate ml-1">
            {address.postCode}
          </p>
        </div>
      )}
      {isEdit && <EditRestaurantDetailsAddress setIsEdit={setIsEdit} />}
    </div>
  );
}
