import React from "react";

import NameSpeciality from "./NameSpeciality";

export default function EditRestaurantDetails({ setIsEdit }) {
  return (
    <div>
      <NameSpeciality setIsEdit={setIsEdit} />
    </div>
  );
}
