import React from 'react'
export default function BusinessProfileHeader() {

    return (
        <div className="w-full">
            <div className="w-full flex items-center justify-between">
                <div className="py-2 border-b-2">
                   <p className="font-semibold text-lg sm:text-sm md:text-xl">Business Profile</p>
                </div>
            </div>
        </div>
    )
}
