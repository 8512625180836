import React, { useState } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import InboxContent from '../../../components/inbox';
import AdminLayout from '../../../components/layout/AdminLayout';
import Heading from '../../../components/utilities/Heading';
import Inboxpopup from './InboxPopup';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCookie } from '../../../components/utilities/cookie/cookie';
import { useEffect } from 'react';
import { getAllRestaurantAllContacts } from "./../../../redux/actions/contact";

export default function AdminInbox() {
    const dispatch = useDispatch();
    // const { restaurant } = useSelector((state) => state.restaurant);
    const token = getCookie("@partner_token");
    // const id = restaurant.restaurant;
    const { restaurantInfo } = useSelector((state) => state.restaurantInfo);
    const id = restaurantInfo._id;
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        getAllRestaurantAllContacts(dispatch, token, id);
    }, [dispatch, token, id]);

    const { contacts } = useSelector((state) => state.contact);
    // console.log(contacts.length);

    // const { contacts, error } = useSelector((state) => state);
    // console.log(contacts, "contacts",restaurant);
    // const contact = [
    //     {
    //         name: "Live support",
    //         reason: "How can this happen? The order was for a curry on Saturday 20 th November and was for around £18 from Tuger Spice in Seaton Delaval.",
    //         _id: ""
    //     }
    // ]
    // console.log(contact.length);

    // const handleDelete = (data) => {
    //     deleteRestaurantContact(dispatch, token)
    // }

    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps Partner | Inbox</title>
            </Helmet>
            <AdminLayout>
                <Heading classes="pb-4">Inbox</Heading>
                <div className="mt-8">
                    <div className="flex items-center w-full px-4 py-3 border-b border-gray-400 font-bold">
                        <div className="w-4/12 truncate">
                            <p>Name</p>
                        </div>
                        <div className="w-6/12 truncate">
                            <p>Reason</p>
                        </div>
                        <div className="w-2/12 flex items-center justify-end">
                            <p>Actions</p>
                        </div>
                    </div>
                    <div className="mt-3">
                        {contacts && contacts.length > 0 ? (
                            contacts.map((contact, index) => (
                                <>
                                    <InboxContent
                                        name={contact.name}
                                        reason={contact.reason}
                                        send={() => setOpenModal(true)}
                                        contact={contact._id}
                                    />
                                    <Inboxpopup openModal={openModal} setOpenModal={setOpenModal} contact={contact} />
                                </>
                            ))
                        ) : (
                            <span className="text-red-700 font-bold text-lg">
                                No messages found+
                            </span>
                        )}
                    </div>
                </div>
            </AdminLayout>
        </HelmetProvider>
    )
}

// import React from 'react';
// //
// import { Helmet, HelmetProvider } from 'react-helmet-async';
// //
// import InboxContent from '../../../components/inbox';
// import AdminLayout from '../../../components/layout/AdminLayout';
// import Heading from '../../../components/utilities/Heading';
// import { useDispatch } from 'react-redux';
// import { useSelector } from 'react-redux';
// import { getCookie } from '../../../components/utilities/cookie/cookie';
// import { useEffect } from 'react';
// import { getAllRestaurantAllContacts, deleteRestaurantContact } from "./../../../redux/actions/contact";

// export default function AdminInbox() {
//     const dispatch = useDispatch();
//     const { restaurant } = useSelector((state) => state.restaurant);
//     const token = getCookie("@partner_token");
//     const id = restaurant.restaurant;

//     useEffect(() => {
//         getAllRestaurantAllContacts(dispatch, token, id);
//         console.log(id);
//     }, [dispatch, token, id]);

//     const { contacts, error } = useSelector((state) => state.contact);
//     console.log(contacts.length);

//     // const handleDelete = (data) => {
//     //     deleteRestaurantContact(dispatch, token)
//     // }

//     return (
//         <HelmetProvider>
//             <Helmet>
//                 <title>FoodApps | Partner Inbox</title>
//             </Helmet>
//             <AdminLayout>
//                 <Heading classes="pb-4">Contacts</Heading>
//                 <div className="mt-8">
//                     <div className="flex items-center w-full px-4 py-3 border-b border-gray-400 font-bold">
//                         <div className="w-4/12 truncate">
//                             <p>Name</p>
//                         </div>
//                         <div className="w-6/12 truncate">
//                             <p>Reason</p>
//                         </div>
//                         <div className="w-2/12 flex items-center justify-end">
//                             <p>Actions</p>
//                         </div>
//                     </div>
//                     <div className="mt-3">

//                         {contacts && contacts.length > 0 ? (
//                             contacts.map((contact, index) => (
//                                 <InboxContent
//                                     name={contact.name}
//                                     reason={contact.reason}
//                                     // send={() => alert("Hey")}
//                                     contact={contact._id}
//                                 />
//                             ))
//                         ) : (
//                             <span className="text-red-700 font-bold text-lg">
//                                 No messages found
//                             </span>
//                         )}
//                     </div>
//                 </div>
//             </AdminLayout>
//         </HelmetProvider>
//     )
// }
