import React, { useState } from 'react';
import Field from './../utilities/form/Field';


const CardInfo = ({ localCard, setLocalCard }) => {
    const [focus, setFocus] = useState("");
    const [expTime, setExpTime] = useState("");

    const handleChange = (e) => {
        e.preventDefault();
        setLocalCard({ ...localCard, [e.target.name]: e.target.value });
    };
    localCard.exp_month = Number(expTime.split('/')[0])
    localCard.exp_year = Number("20" + expTime.split('/')[1])
    console.log(focus, expTime)
    
    return (
        <div div className="sm:flex md:flex flex-wrap items-center mt-0">
            <div className="p-2 w-full sm:w-2/3 md:w-full">
                <div className="text-sec rounded">
                    <p className="mt-3 text-gray-700">Card Number</p>
                    <Field classes="pl-3"
                        name="cardNumber"
                        placeholder="Enter Card Number"
                        onChange={handleChange}
                        onFocus={(e) => setFocus(e.target.name)}
                    />
                </div>
            </div>
            <div className="p-2 w-2/4">
                <div className="text-sec rounded">
                    <p className="mt-3 text-gray-700">MM/YY</p>
                    <Field classes="pl-3"
                        name="exp_year"
                        placeholder="MM/YY Expiry"
                        onBlur={(e) => setExpTime(e.target.value)}
                    />
                </div>
            </div>
            <div className="p-2 w-2/4">
                <div className="text-sec rounded">
                    <p className="mt-3 text-gray-700">CVC</p>
                    <Field classes="pl-3"
                        name="cvc"
                        placeholder="CVC"
                        onChange={handleChange}
                        onFocus={(e) => setFocus(e.target.name)} />
                </div>
            </div>
            
            
        </div>
    );
};

export default CardInfo;