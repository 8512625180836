import React, { useState } from 'react'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from 'react-redux'

import { getFoodsByCategory } from "../../../../redux/actions/foods";
import { getSingleCategory, } from '../../../../redux/actions/categories';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };
export default function Categories(props) {


    let { categories } = useSelector(state => state.categories)

    const dispatch = useDispatch();
    const [selectedCate, setSelectedCate] = useState(null)

    const handleClick = (categoryId) => {
        getSingleCategory(dispatch, categoryId);
        getFoodsByCategory(dispatch, categoryId);
        setSelectedCate(categoryId)
    };


    return (
      <div>
        <div className="grid grid-cols-3 h-96 overflow-y-auto gap-3 ">
          {categories &&
            categories.map((cate, i) => (
              <div
                key={i}
                onClick={() => handleClick(cate._id)}
                className={`${
                  selectedCate === cate._id ? "bg-prim" : "bg-gray-700"
                } hidden lg:flex hover:bg-red-700 text-white p-3 cursor-pointer text-sm text-center h-28 items-center justify-center rounded-md`}
              >
                <p className='uppercase tracking-wider font-medium'>{cate.name}</p>
              </div>
          ))}
        </div>

        <Carousel
        className="visible md:invisible"
          swipeable={true}
          responsive={responsive}
          infinite={true}
          arrows={false}
          slidesToSlide={1}
          autoPlaySpeed={4000}
          partialVisible={true}
          
        >
          {categories &&
            categories.map((data, index) => (
              <div
                className="w-full h-full md:p-3 flex justify-center"
                key={index}
                onClick={() => handleClick(data._id)}
              >
                <button
                  className={`w-full mr-2 md:mr-0 text-xs md:text-lg font-semibold rounded-lg text-white py-3 px-2 ${
                    selectedCate === data._id ? "bg-prim" : "bg-gray-600"
                  }`}
                >
                  {data.name}
                </button>
              </div>
            ))}
        </Carousel>
      </div>
    )
}
