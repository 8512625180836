import React, { useState } from 'react';

import { addRestaurantPaymentInfo, updateRestaurantPaymentInfo, getRestaurantPaymentInfo } from '../../../redux/actions/restaurant';
import { useDispatch } from 'react-redux';
import { getCookie } from '../../../components/utilities/cookie/cookie';

const EditInvoicePaymentInfo = ({ restaurant, setEditable }) => {

    const dispatch = useDispatch();
    const token = getCookie("@partner_token");
    const id = restaurant._id

    const [formData, setFormData] = useState({
        name: restaurant.name,
        bankName: restaurant.bankName,
        sortCode: restaurant.sortCode,
        accountNumber: restaurant.accountNumber
    });

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(restaurant.bankName){
            await updateRestaurantPaymentInfo(dispatch, token, id, formData);
        } else {
            await addRestaurantPaymentInfo(dispatch, token, id, formData);
        }
        
        await getRestaurantPaymentInfo(dispatch, token, id);
        setEditable(false);
    };
    console.log(restaurant.bankName)
    return (
        <div className="mt-5 border-b-2 pb-4">
            <div className="w-full md:w-1/3 mt-2">
                <p className="text-sm flex items-center">
                    Name <span className="ml-1 text-xs font-bold text-gray-600"></span>
                </p>
                <input
                    // style={{ borderRadius: ".5rem" }}
                    className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
                    onChange={handleChange}
                    name="name"
                    value={formData.name}
                />
            </div>

            <div className="w-full md:w-1/3 mt-2">
                <p className="text-sm flex items-center">
                    Bank Name <span className="ml-1 text-xs font-bold text-gray-600"></span>
                </p>
                <input
                    // style={{ borderRadius: ".5rem" }}
                    className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
                    onChange={handleChange}
                    name="bankName"
                    value={formData.bankName}
                />
            </div>
            {/* <PaymentInfoContent
                title="Bank Name"
                subTitle=""
                // placeholder=""
                value={restaurant.bankName}
            /> */}

            <div className="w-full md:w-1/3 mt-2">
                <p className="text-sm flex items-center">
                    Sort Code <span className="ml-1 text-xs font-bold text-gray-600"></span>
                </p>
                <input
                    // style={{ borderRadius: ".5rem" }}
                    className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
                    onChange={handleChange}
                    name="sortCode"
                    value={formData.sortCode}
                />
            </div>
            {/* <PaymentInfoContent
                title="Sort Code"
                subTitle=""
                // placeholder=""
                value={restaurant.sortCode}
            /> */}

            <div className="w-full md:w-1/3 mt-2">
                <p className="text-sm flex items-center">
                    Account Number <span className="ml-1 text-xs font-bold text-gray-600"></span>
                </p>
                <input
                    // style={{ borderRadius: ".5rem" }}
                    className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
                    onChange={handleChange}
                    name="accountNumber"
                    value={formData.accountNumber}
                />
            </div>
            {/* <PaymentInfoContent
                title="Account Number"
                subTitle=""
                // placeholder=""
                value={restaurant.accountNumber}
            /> */}
            <div className="w-full mt-2 flex flex-wrap justify-end items-end">
                <button className="bg-prim rounded px-5 py-3 text-white" onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};

export default EditInvoicePaymentInfo;