import React, { useState } from "react";

const CheckoutContactField = ({ order, setOrder, setIsEdit, isEdit,setIsOpen }) => {
  const [formData, setFormData] = useState({
    mobileNo: ""
  })



  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, mobileNo: e.target.value });
  }

  const handleClick = (e) => {
    e.preventDefault();
    let localOrder = JSON.parse(localStorage.getItem('order'));
    localOrder = {
      ...localOrder, mobileNo: formData.mobileNo
    };
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
    setIsEdit(false)
    setIsOpen(false)

  }
  return (
    <div className="sm:flex md:flex flex-wrap items-center mt-3">
      <div className="w-full">
        {!isEdit ? (
          null
        ) : (
          <>
            <div className="sm:flex">
              <input value={formData.mobileNo} onChange={handleChange} type="text" className="w-3/4 border-2 border-prim rounded py-2 px-2" placeholder="Add another contact" />
              <button onClick={handleClick} className="bg-prim px-5 py-2 rounded mx-2 text-white">Add</button>
            </div>       
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutContactField;
