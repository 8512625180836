import { GET_ALL_BUSINESS_TYPE_SUCCESS, GET_BUSINESS_TYPE_FAILURE } from "../types/businessType/type";

const initialState = {
    businessType: {},
    error: null,
    loading: true,
    message: ""
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_BUSINESS_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                businessType: action.payload
            }
        case GET_BUSINESS_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed",
                error: action.payload
            };
            
        default:
            return state;
    }
}
export default reducer;