import React, { useState } from "react";
import AddSpecialOffer from "./AddSpecialOffer/AddSpecialOffer";
import ShowSpecialOffer from "./ShowSpecialOffer";

import SpecialOfferHeading from "./SpecialOfferHeading";
import UpdateSpecialOffer from "./UpdateSpecialOffer/UpdateSpecialOffer";

const SpecialOffer = () => {
  const [showOffer, setShowOffer] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div className="w-full md:w-9/12">
      <SpecialOfferHeading setShowOffer={setShowOffer} showOffer={showOffer} />
      {isEdit ? (
        <UpdateSpecialOffer setShowOffer={setShowOffer} setIsEdit={setIsEdit} />
      ) : showOffer ? (
        <ShowSpecialOffer isEdit={isEdit} setIsEdit={setIsEdit} />
      ) : (
        <AddSpecialOffer setShowOffer={setShowOffer} setIsEdit={setIsEdit} />
      )}
    </div>
  );
};

export default SpecialOffer;
