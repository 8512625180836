import React from "react";
import { useSelector } from "react-redux";
import PackageChild from "./PackageChild";

const Packages = ({ formData, setFormData }) => {
  const { categories } = useSelector((state) => state.categories);
  const PackageChildDOM = categories.map((el, index) => (
    <PackageChild
      setFormData={setFormData}
      formData={formData}
      category={el}
      key={index}
      index={index}
    />
  ));
  return (
    <div className="p-3 py-2 pt-3">
      <p className="mb-2 font-bold text-xs">
        Select from categories<span className="text-red-700">*</span>
      </p>
      {PackageChildDOM}
    </div>
  );
};

export default Packages;
