import React, { useState } from 'react';

import { FiEdit2, FiX } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';

import { getCookie } from '../../../components/utilities/cookie/cookie';
import { useEffect } from 'react';
import { getRestaurantPaymentInfo } from '../../../redux/actions/restaurant';

import Heading from '../../../components/utilities/Heading';
import SectionContent from '../components/SectionContent/index';
import Icon from '../../../components/utilities/Icon';
import EditInvoicePaymentInfo from './EditInvoicePaymentInfo';
import Button from '../../../components/utilities/buttons/Button'

export default function AdminInvoicesPaymentInfo() {
    const [editable, setEditable] = useState(false);
    const dispatch = useDispatch();
    const { restaurant } = useSelector((state) => state.restaurant);
    const token = getCookie("@partner_token");
    // const { restaurant } = useSelector((state) => state.restaurant);
    const id = restaurant._id;
    useEffect(() => {
        // getAllRestaurantsOrders(dispatch, token, id);
        getRestaurantPaymentInfo(dispatch, token, id);
    }, [dispatch, token, id]);    
    
    return (
        <div className="w-full h-full bg-white p-5 sm:p-6 md:p-6 rounded-lg">
            <div className="w-full sm:pl-4 md:pl-4 mt-3 sm:mt-6 md:mt-6">
                <Heading>Payment Info</Heading>
                <div className="mt-4">
                    <div className="border-b-2 pb-2 mt-8 flex items-center justify-between">
                        <p className="text-lg font-bold">Details</p>
                    </div>
                    <div className="flex items-center w-full justify-end mr-5">
                        {!editable ? (
                            restaurant.bankName ?
                                <Icon onClick={() => setEditable(true)} icon={FiEdit2} />
                                :
                                <Button onClick={() => setEditable(true)}>Add Payment</Button>
                            
                        ) : (
                            <div className="flex justify-between">
                                <Icon onClick={() => setEditable(false)} icon={FiX} />
                            </div>
                        )}
                    </div>
                    {!editable && (
                        <div className="w-full flex flex-wrap items-center mt-2">
                            <SectionContent
                                title="Name"
                                value={restaurant.name}
                            />
                            <SectionContent
                                title="Bank Name"
                                value={restaurant.bankName}
                            />
                            <SectionContent
                                title="Sort Code"
                                value={restaurant.sortCode}
                            />
                            <SectionContent
                                title="Account Number"
                                value={restaurant.accountNumber}
                            />
                        </div>
                    )}
                    {editable && (
                        <EditInvoicePaymentInfo restaurant={restaurant} setEditable={setEditable} />
                    )}
                </div>
            </div>
        </div>
    )
}