const styles = {
    plusButtonArea: {
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    foodOptionModalArea: {
        width: 600
    },
    cursorPointer: {
        cursor: 'pointer'
    }
};

export default styles;
