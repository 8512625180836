import {
    GET_DISCOUNT_FAILURE,
    GET_DISCOUNT_SUCCESS,
    DELETE_DISCOUNT_SUCCESS,
} from "../types/discount/types";


import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const createRestaurantDiscount = async (dispatch, token, formData) => {
    try {
        const {
            data: { data },
        } = await axios.post(
            `${apiBaseUrl}${apiVersionPrefix}/discount/create-discount`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (data) {
            dispatch({ type: GET_DISCOUNT_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_DISCOUNT_FAILURE, payload: error });
    }
};

export const updateDiscount = async (dispatch, token, formData, discountId) => {
    try {
        const {
            data: { data },
        } = await axios.put(
            `${apiBaseUrl}${apiVersionPrefix}/discount/update-discount/${discountId}`, formData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (data) {
            dispatch({ type: GET_DISCOUNT_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_DISCOUNT_FAILURE, payload: error });
    }
}

export const getRestaurantDiscount = async (dispatch, restaurantId) => {
    try {
        const {
            data: { data },
        } = await axios.get(
            `${apiBaseUrl}${apiVersionPrefix}/discount/get-discount-by-restaurant/${restaurantId}`,

        );
        if (data) {
            dispatch({ type: GET_DISCOUNT_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_DISCOUNT_FAILURE, payload: error });
    }
};


export const deleteDiscount = async (dispatch, token, discountId) => {
    try {
        const {
            data: { message },
        } = await axios.delete(
            `${apiBaseUrl}${apiVersionPrefix}/discount/delete-discount/${discountId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (message) {
            dispatch({ type: DELETE_DISCOUNT_SUCCESS, payload: message });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_DISCOUNT_FAILURE, payload: error });
    }
};


