import {
    GET_OPTION_SUCCESS,
    GET_OPTION_FAILURE,
    GET_ALL_OPTIONS_SUCCESS,
    DELETE_OPTION_SUCCESS
} from "../types/option/type";

import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";


export const getAllOptions = async (dispatch, foodId) => {
    try {
        const {
            data: { data },
        } = await axios.get(
            `${apiBaseUrl}${apiVersionPrefix}/food/${foodId}/option/get-all-food-options`
        );
        if (data) {
            dispatch({ type: GET_ALL_OPTIONS_SUCCESS, payload: data });
        }
    } catch (err) {
        // console.log(err);
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_OPTION_FAILURE, payload: error });
    }
}

export const addOptions = async (dispatch, foodId, token, option, setCancel) => {
    try {
        const response = await fetch(`${apiBaseUrl}${apiVersionPrefix}/food/${foodId}/option/add-option`, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
                
            },
            body: JSON.stringify(option)
        })
        const data = await response.json();
        if (data) {
            dispatch({ type: GET_OPTION_SUCCESS, payload: data });
        }
        if(data.success) {
            setCancel(false)
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_OPTION_FAILURE, payload: error });
    }
};



export const updateOption = async (dispatch, foodId, token, optionId, option, setEdit) => {
    try {
        const response = await fetch(`${apiBaseUrl}${apiVersionPrefix}/food/${foodId}/option/edit-option/${optionId}`, {
            method: "PUT",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
                
            },
            body: JSON.stringify(option)
        })
        const data = await response.json();
        if (data) {
            dispatch({ type: GET_OPTION_SUCCESS, payload: data });            
        }
        if(data.success) {
            setEdit(false);
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_OPTION_FAILURE, payload: error });
    }
};

export const deleteOption = async (dispatch, foodId, token, optionId, securityKey) => {
    try {
        const response = await fetch(`${apiBaseUrl}${apiVersionPrefix}/food/${foodId}/option/delete-option/${optionId}`, {
            method: "DELETE",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
                
            }
        })
        const data = await response.json();
        if (data) {
            dispatch({ type: DELETE_OPTION_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_OPTION_FAILURE, payload: error });
    }
};


export const getSingleOption = async (dispatch, foodId, token, optionId) => {
    try {
        const response = await fetch(`${apiBaseUrl}${apiVersionPrefix}/food/${foodId}/option/get-single-option/${optionId}`, {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
            }
        })
        const data = await response.json();
        if (data) {
            dispatch({ type: GET_OPTION_SUCCESS, payload: data.data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_OPTION_FAILURE, payload: error });
    }
};




