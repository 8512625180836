import React from "react";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCategory,
  getRestaurantAllCategories,
  getSingleCategory,
} from "../../../redux/actions/categories";
import { getFoodsByCategory } from "../../../redux/actions/foods";
import Button from "../Button";
import { getCookie } from "../cookie/cookie";

export default function CategoryButton({
  crossable,
  category,
  textCenter,
  setOpenSpecialOffer,
}) {
  const dispatch = useDispatch();
  const { restaurant } = useSelector((state) => state.restaurant);
  const token = getCookie("@partner_token");
  const categoryId = category._id;
  const id = restaurant._id;

  const handleClick = async () => {
    await getSingleCategory(dispatch, categoryId);
    await getFoodsByCategory(dispatch, categoryId);
    setOpenSpecialOffer(false);
  };

  const handleDelete = async () => {
    await deleteCategory(dispatch, token, categoryId);
    await getRestaurantAllCategories(dispatch, id, token);
  };
  return (
    <div
      className={`${
        textCenter ? "" : "flex"
      } items-center justify-between w-full bg-gray-600  hover:bg-red-700 text-white py-2 px-2 mb-2 mr-2 cursor-pointer rounded-lg relative z-0`}
      onClick={handleClick}
    >
      <p className="px-2 pb-1 text-center font-semibold">{category.name}</p>
      {crossable && (
        <Button className="mt-1 relative z-10" onClick={handleDelete}>
          <div className="rounded-full bg-white p-1 text-black ml-1">
            <FiX className="text-xl" />
          </div>
        </Button>
      )}
    </div>
  );
}
