import React, { useEffect } from "react";
import { FiEdit2, FiX, FiStar } from "react-icons/fi";

// import AdminRestaurantDetailsContent from "./AdminRestaurantDetailsContent";
import EditRestaurantDetails from "./EditRestaurantDetails";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../../components/utilities/Icon";
import { getAllCuisines } from "../../../../../redux/actions/cuisine";
import { IoFastFoodSharp } from "react-icons/io5";
import { FcBriefcase } from "react-icons/fc";
import { getAllBusinessType } from "../../../../../redux/actions/businessType";
import Toggle from "../../../../../components/utilities/form/Toggle.js";
export default function AdminRestaurantDetails({ isEdit, setIsEdit }) {
  const { restaurantInfo } = useSelector((state) => state?.restaurantInfo);
  const { cuisines } = useSelector((state) => state?.cuisines);
  const { businessType } = useSelector((state) => state?.businessType);
  let cuisineType = [];
  const cuisineId = restaurantInfo?.cuisines?.length;
  for (let i = 0; i < cuisineId; i++) {
    const findCuisine = cuisines?.find(item => item._id === restaurantInfo?.cuisines[i]);
    cuisineType = [...cuisineType, findCuisine]
  }
  
  let findBusinessType = Array?.isArray(businessType)?businessType?.find(item => item?._id === restaurantInfo?.businessType):null
  
  const dispatch = useDispatch();
  useEffect(() => {
    getAllCuisines(dispatch);
    getAllBusinessType(dispatch)
  }, [dispatch])
  localStorage.setItem('restaurantUniId', restaurantInfo?.uniId)
  return (
    <div className="mb-3 w-full bg-white py-3 pb-5 px-6 ">
      <div className="w-full flex justify-end">
        {!isEdit ? (
          <Icon onClick={() => setIsEdit(true)} icon={FiEdit2} />
        ) : (
          <div className="flex justify-between">
            <Icon onClick={() => setIsEdit(false)} icon={FiX} />
          </div>
        )}
      </div>
      {!isEdit && (
        <div
          className={"md:flex items-center rounded border-b-2 border-gray-400 pb-6"}
        >
          <div className="w-full md:w-5/12 ">
            <div className="flex items-center">
              <div className="w-full md:px-4 truncate">
                <div className="flex items-center">
                  <p className="text-4xl font-semibold text-sec truncate pb-2">
                    {restaurantInfo.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex p-2 items-center ">
            <span className="mr-2 font-bold text-xs">Active</span>
            <button>
              <Toggle checked={restaurantInfo.isAvailable} />
            </button>
          </div> */}

          <div className="md:flex justify-around">
            <div className="md:mr-20">
              <p className="text-sm text-sec font-semibold">Speciality</p>
              {
                restaurantInfo?.speciality?.map((sp, i) => (
                  <div key={i} className="flex items-center mx-2">
                    <FiStar className="text-red-600" />
                    <p className="text-sm text-gray-700 mt-1 ml-1">{sp}</p>
                  </div>
                ))
              }
            </div>
            <div className="sm:flex sm:justify-between">
              <div className="">
                <p className="text-sm text-sec ml-2 font-semibold">Cuisines</p>
                {
                  cuisineType?.map((item, i) => (
                    <div key={i} className="flex items-center mx-2">
                      <IoFastFoodSharp className="text-red-600" />
                      <p className="text-sm text-gray-700 mt-1 ml-1">{item?.type}</p>
                    </div>
                  ))
                }

              </div>
              <div className="sm: ml-20">
                <p className="text-sm text-sec ml-2 font-semibold">Business Type</p>
                {

                  <div className="flex items-center mx-2">
                    <FcBriefcase className="text-red-600" />
                    <p className="text-sm text-gray-700 mt-1 ml-1">{findBusinessType?.businessType}</p>
                  </div>
                }

              </div>
            </div>
            <div className="md:ml-20">
              <p className="text-sm text-sec ml-2 font-semibold">Booking Capacity</p>
              <p className="text-sm ml-2 text-gray-700 mt-2" >{restaurantInfo.capacity}</p>
            </div>
          </div>
        </div>
      )}
      {isEdit && <EditRestaurantDetails setIsEdit={setIsEdit} />}
    </div>
  );
}
