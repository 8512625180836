import React, { useState } from "react";

import CheckoutCustomerInfo from "./CheckoutCustomerInfo";
import Modal from "react-modal";
import Header from "../../pages/partner/Epos/categories/SpecialOfferPopup/Header";

export default function CheckoutHeader({ title, order, setOrder }) {
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const customStyles = {
    content: {
        top: '30px',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, .1)'
    },
    modal: {
        outline: "none"
    }
};

  function openModal() {
    setIsOpen(true);
    setIsEdit(true);
  }
 

  return (
    <div>
      <div onClick={() => openModal()}>
     
        {/* <FiUser className="rounded-full w-10 h-10 flex items-center justify-center bg-prim text-white p-2" />
        <div className="px-4 flex-grow">
          <p className="text-lg sm:text-2xl md:text-2xl">{title}</p>
        </div> */}
        <h3 className="font-semibold text-md border-2 border-prim rounded-md p-3 flex items-center justify-center min-h-110 cursor-pointer">{order.customerName ? order.customerName : "Enter A Name"}</h3>
     
          {/* <Icon onClick={() => openModal()} icon={FiEdit2} /> */}
      
      </div>


      <Modal
      isOpen={isOpen}
      style={customStyles}
      className="w-full outline-none absolute border border-none"
      ariaHideApp={false}
    >
      <div className="w-full h-screen">
        <div className="w-full h-full flex items-center justify-center">
          <div className="w-full sm:w-1/3 md:w-1/3 mx-8 sm:mx-0 md:mx-0 bg-white rounded-lg p-6 mt-16">
            <Header heading="Enter Name" setOpenModal={setIsOpen} />
              <CheckoutCustomerInfo setIsOpen={setIsOpen} setIsEdit={setIsEdit} isEdit={isEdit} order={order} setOrder={setOrder} />
          </div>
        </div>
      </div>
    </Modal>
    </div>
  );
}
