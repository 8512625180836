import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AdminLayout from '../../../components/layout/AdminLayout';
import AdminInvoicesPaymentInfo from './AdminInvoicesPaymentInfo';

export default function AdminPaymentInfo () {
    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps Partner | Payment Info</title>
            </Helmet>
            <AdminLayout multi>
                <AdminInvoicesPaymentInfo />
            </AdminLayout>
        </HelmetProvider>
    )
}