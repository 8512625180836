import React from "react";

export default function Toggle({ checked }) {
  return (
    <div
      className={`w-8 h-5 ${checked
        ? "border-2 border-red-700 bg-red-700 justify-end"
        : "border-2 border-gray-400 bg-white justify-start"
        } rounded-full flex  items-center cursor-pointer trans`}
    >
      <div
        className={`w-4 ${checked ? "border-2 bg-white" : "border border-gray-400"
          } h-4 rounded-full full `}
      ></div>
    </div>
  );
}
