import React, { useState } from "react";

import { useSelector } from "react-redux";

import Packages from "./Packages";

const UpdateSpecialOffer = ({ setShowOffer, setIsEdit }) => {
  const { specialOffer } = useSelector((state) => state.specialOffers);
  const [formData, setFormData] = useState({
    name: specialOffer.name,
    description: specialOffer.description,
    price: specialOffer.price,
    packages: specialOffer.packages,
  });
  const handelChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    console.log(formData);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };
  return (
    <div className="w-full">
      <div className="border-b-2">
        <div className="p-3 py-2 pt-3">
          <p className="mb-2 font-bold text-xs">
            Name<span className="text-red-700">*</span>
          </p>
          <input
            type="text"
            name="name"
            className="w-full border border-red-700 py-2 px-4 mr-2 rounded-full outline-none"
            placeholder="Add Name"
            onChange={handelChange}
            value={formData.name}
          />
        </div>
        <div className="p-3 py-2">
          <p className="mb-2 font-bold text-xs">
            Description<span className="text-red-700">*</span>
          </p>
          <textarea
            name="description"
            className="w-full border border-red-700 py-2 px-4 mr-2 rounded-lg  outline-none"
            placeholder="Add Description"
            onChange={handelChange}
            value={formData.description}
          ></textarea>
        </div>
        <div className="p-3 py-2">
          <p className="mb-2 font-bold text-xs">
            Price<span className="text-red-700">*</span>
          </p>
          <input
            type="text"
            name="price"
            className="w-full border border-red-700 py-2 px-4 mr-2 rounded-full outline-none"
            placeholder="Price"
            onChange={handelChange}
            value={formData.price}
          />
        </div>
        <Packages
          setFormData={setFormData}
          formData={formData}
          packages={specialOffer.packages}
        />
      </div>
      <div className="pt-4 flex justify-end">
        <button
          className="text-white bg-red-700 px-4 py-3 rounded-lg"
          onClick={handleSubmit}
        >
          Save
        </button>
        <button
          className="text-white bg-red-700 px-4 py-3 rounded-lg ml-2"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UpdateSpecialOffer;
