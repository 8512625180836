import counterReducer from "./reducer/counter";
import customerReducer from "./reducer/customer";
import formReducer from "./reducer/form";
import carousels from "./reducer/carousels";
import categories from "./reducer/categories";
import foods from "./reducer/foods";
import contact from './reducer/contact';
// import superAdminReducer from "./reducer/superAdmin";
import address from "./reducer/address";
import termsAndConditionsReducer from "./reducer/termsAndConditions";
import restaurantReducer from "./reducer/restaurant";
import roles from "./reducer/roles";
import allergies from "./reducer/allergies";
import specialOffers from "./reducer/specialOffer";
import restaurantInfo from "./reducer/restaurantInfo";
import restaurantTiming from "./reducer/restaurantTiming";
import restaurantDelivery from "./reducer/restaurantDelivery";
import deliveryCharge from "./reducer/deliveryCharge";
import discount from "./reducer/discount";
import order from "./reducer/order";
import coupon from "./reducer/coupon";
import invoice from "./reducer/invoice";
import serviceCharge from "./reducer/serviceCharge";
import charges from "./reducer/charges";
import option from "./reducer/option";
import notification from "./reducer/notification";
import cuisine from './reducer/cuisine'
import card from './reducer/card'
import businessType from './reducer/businessType'
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  counter: counterReducer,
  customer: customerReducer,
  termsAndConditions: termsAndConditionsReducer,
  form: formReducer,
  cuisines:cuisine,
  restaurant: restaurantReducer,
  carousels,
  roles,
  categories,
  foods,
  allergies,
  specialOffers,
  restaurantInfo,
  restaurantTiming,
  restaurantDelivery,
  address,
  order,
  discount,
  coupon,
  serviceCharge,
  charges,
  option,
  contact,
  invoice,
  notification,
  card,
  businessType,
  deliveryCharge
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);
export default store;
