import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import {
  GET_RESTAURANT_TIMING_FAILURE,
  GET_RESTAURANT_TIMING_SUCCESS,
} from "../types/restaurantTiming/type";

export const getRestaurantOwnTimes = async (dispatch, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-timing/get-restaurant-own-timings`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_TIMING_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_TIMING_FAILURE, payload: error });
  }
};

export const addRestaurantTime = async (dispatch, token, formData) => {
  // console.log(formData);
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-timing/add-restaurant-timing`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_TIMING_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_TIMING_FAILURE, payload: error });
  }
};

export const getRestaurantTimesById = async (dispatch, restaurantId) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-timing/get-restaurant-timings-by-restaurant-id/${restaurantId}`,
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_TIMING_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_TIMING_FAILURE, payload: error });
  }
};

export const updateRestaurantTime = async (dispatch, token, formDataString) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-timing/update-restaurant-timing`,
      formDataString,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_TIMING_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_TIMING_FAILURE, payload: error });
  }
};

export const deleteRestaurantTiming = async (
  dispatch,
  token,
  formData,
  restaurantId,
) => {
  try {
    const {
      data: { data },
    } = await axios.delete(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant-timing/delete-restaurant-timing/${restaurantId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_TIMING_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_TIMING_FAILURE, payload: error });
  }
};
