import {
    GET_INVOICE_FAILURE,
    GET_INVOICE_SUCCESS
} from "../types/invoice/type";

import axios from "axios"

import { apiBaseUrl, apiVersionPrefix } from "../../config";

export const get7DaysInvoice = async (dispatch, token, id) => {
    try {
        const { data: { data } } = await axios.get(
            `${apiBaseUrl}${apiVersionPrefix}/order/get-restaurant-invoice-7-days/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            },
        );
        if (data) {
            dispatch({ type: GET_INVOICE_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_INVOICE_FAILURE, payload: error });
    }
};
export const getCurrentDayInvoice = async (dispatch, token, id) => {
    try {
        const { data: { data } } = await axios.get(
            `${apiBaseUrl}${apiVersionPrefix}/order/get-restaurant-invoice-by-day/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            },
        );
        if (data) {
            dispatch({ type: GET_INVOICE_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_INVOICE_FAILURE, payload: error });
    }
};
export const get30DaysInvoice = async (dispatch, token, id) => {
    try {
        const { data: { data } } = await axios.get(
            `${apiBaseUrl}${apiVersionPrefix}/order/get-restaurant-invoice-30-days/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            },
        );
        if (data) {
            dispatch({ type: GET_INVOICE_SUCCESS, payload: data });
        }
    } catch (err) {
        const {
            response: {
                data: { error },
            },
        } = err;
        dispatch({ type: GET_INVOICE_FAILURE, payload: error });
    }
};