import {
    GET_RESTAURANT_DELIVERY_CHARGE_FAILURE,
    GET_RESTAURANT_DELIVERY_CHARGE_SUCCESS,
  } from "../types/deliveryCharge/type";
  
  const initialState = {
    deliveryCharge: 0,
    status: null,
    error: null,
    loading:null
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_RESTAURANT_DELIVERY_CHARGE_SUCCESS:
        return {
          ...state,
         
          loading: false,
          status: "success",
          deliveryCharge: action.payload,
          
        };
  
      case GET_RESTAURANT_DELIVERY_CHARGE_FAILURE:
        return {
          ...state,
          loading: false,
          status: "Failed",
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  