import React, { useEffect, useState } from "react";

import { FiEdit2, FiX } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";
import { getRestaurantOwnTimes, updateRestaurantTime } from "../../../../redux/actions/restaurantTiming.js";
import { getCookie } from "../../../../components/utilities/cookie/cookie.js";

import Icon from "../../../../components/utilities/Icon";
import Toggle from "../../../../components/utilities/form/Toggle.js.js";
import EdiTimeBox from "../AdminRestutantDetails/AdminRestaurantDetails/EditRestaurantDetails/EditTimeBox.js";


export default function Timings() {
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  const { restaurantTiming } = useSelector((state) => state?.restaurantTiming?.restaurantTiming);
  useEffect(() => {
    getRestaurantOwnTimes(dispatch, token);
  }, [dispatch, token]);
  
  // console.log(restaurantTiming)
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState(
//     {
//     available: [
//       {
//         isOpen: true,
//         day : 0,
//         dayName: "Sunday",
//         startTime : "10:00",
//         endTime : "20:00",
//       },
//       {
//         isOpen: true,
//         day : 1,
//         dayName: "Monday",
//         startTime : "10:00",
//         endTime : "20:00",
//       },
//       {
//         isOpen: true,
//         day : 2,
//         dayName: "Tuesday",
//         startTime : "10:00",
//         endTime : "20:00",
//       },
//       {
//         isOpen: true,
//         day : 3,
//         dayName: "Wednesday",
//         startTime : "10:00",
//         endTime : "20:00",
//       },
//       {
//         isOpen: true,
//         day : 4,
//         dayName: "Thirsday",
//         startTime : "10:00",
//         endTime : "20:00",
//       },
//       {
//         isOpen: true,
//         day : 5,
//         dayName: "Friday",
//         startTime : "10:00",
//         endTime : "20:00",
//       },
//       {
//         isOpen: false,
//         day : 6,
//         dayName: "Saturday",
//         startTime : "10:00",
//         endTime : "20:00",
//       }
// ],
//   deliveryTimes: "30",
//   collectionTime: "40",
//   bookingStartTime: "8:00",
//   bookingEndTime: "10:00 ",
//   }
  );
  
  const handleChange = (e, item) => {
    e.preventDefault();
    const newAvailable = formData.available.map((available) => {
      if (available.day === item.day) {
        return {
          ...available,
          [e.target.name]: (e.target.value),
        };
      }
      return available;
    });
    setFormData({ ...formData, available: newAvailable });
  };
  
  const handleToggle = (item) => {
    const newAvailable = formData.available.map((available) => {
      if (available.day === item.day) {
        return {
          ...available,
          isOpen: !available.isOpen,
        };
      }
      return available;
    });
    setFormData({ ...formData, available: newAvailable });
  }
  const handleSubmit =  async(e) => {
    e.preventDefault();
     await updateRestaurantTime(dispatch, token, formData);
     await getRestaurantOwnTimes(dispatch, token);
    setEditable(false);
  };
  
  let timing;
    editable?timing=(formData):(timing = restaurantTiming);
    // timing=formData;
    useEffect(() => {
      setFormData(restaurantTiming)
    },[restaurantTiming])
    // console.log(restaurantTiming) 
  return (
    <>
      <div className="w-full flex justify-between">
        <div className="border-b-2 border-gray-400 pb-2">
          <p className="md:px-6 text-lg font-bold">Hours</p>
        </div>
        <div className="flex items-center w-full justify-end mr-5">
          {!editable ? (
            <Icon onClick={() => setEditable(true)} icon={FiEdit2} />
          ) : (
            <div className="flex justify-between">
              <Icon onClick={() => setEditable(false)} icon={FiX} />
            </div>
          )}
        </div>
      </div>

      <div className="flex w-full flex-wrap border-gray-400 pb-6 pt-1 mt-3">
        <div className="w-full flex flex-wrap items-center mt-2">
          <div className="w-full flex flex-wrap items-center mt-2">
            {timing&&timing.available?.map((item, index) => (
              <>
                <div className={`w-full flex md:w-1/2 md:px-6 mt-14 py-1`}>
                  <div className={`w-full md:w-1/3 md:px-6 py-1`}>
                    <h3>{item.dayName}</h3>
                  </div>
                  <div className={`w-full md:w-1/3 md:px-6 py-1`}>
                    <div className="flex items-center w-full">
                      <button onClick={() => handleToggle(item)} disabled={!editable}>
                        <Toggle checked={item.isOpen} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={`w-full flex md:w-1/2 md:px-6 duration-100 py-1`}>
                { item.isOpen&&<>
                 <EdiTimeBox
                    title="Opening Time"
                    name="startTime"
                    type={"time"}
                    value={item.startTime}
                    onChange={(e) => handleChange(e, item)}
                    disabled={!editable}
                  />
                  <EdiTimeBox
                    title="Closing Time"
                    name="endTime"
                    value={item.endTime}
                    type={"time"}
                    onChange={(e) => handleChange(e, item)}
                    disabled={!editable}
                  />
                 </>}
                </div>
              </>
            ))}
          </div>
        </div>  

       { timing&&<div className="w-full flex flex-wrap items-center mt-2">
          <div className={`w-full flex md:w-1/2 md:px-6 duration-100 py-1`}>
            <EdiTimeBox
              title="Delivery Times"
              name="deliveryTimes"
              type="number"
              value={timing.deliveryTimes}
              onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
              disabled={!editable}
            />
            <EdiTimeBox
              title="Collection Times"
              name="collectionTime"
              type="number" 
              value={timing.collectionTime}
              onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
              disabled={!editable}
            />
          </div>

          <div className={`w-full flex md:w-1/2 md:px-6 duration-100 py-1`}>
            <EdiTimeBox
              title="Booking Available From"
              name="bookingStartTime"
              type={"time"}
              value={timing.bookingStartTime}
              onChange={(e) => setFormData({ ...formData, [e.target.name]: (e.target.value) })}
              disabled={!editable}
            />
            <EdiTimeBox
              title="Booking Available To"
              name="bookingEndTime"
              type={"time"}
              value={timing.bookingEndTime}
              onChange={(e) => setFormData({ ...formData, [e.target.name]: (e.target.value) })}
              disabled={!editable}
            />
          </div>
        </div>
     }  

      </div>
      {editable&&<div className="flex items-center mt-4  mr-8 justify-end">
        <button onClick={(e)=>handleSubmit(e)}
          type="submit"
          className="py-2 px-4 bg-red-700 text-white rounded-lg"
        >
          Update
        </button>
      </div>}
    </>
    
  );
}
