import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useSelector, useDispatch } from 'react-redux';

import AdminLayout from '../../../components/layout/AdminLayout';
import NotificationContent from '../../../components/utilities/cards/NotificationCard';
import Heading from '../../../components/utilities/Heading';

import { deleteNotification, getRestaurantNotifications } from '../../../redux/actions/notification'
import { getCookie } from "../../../components/utilities/cookie/cookie";

export default function AdminNotifications () {
    const dispatch = useDispatch()
    const token = getCookie("@partner_token");
    useEffect(() => {
        getRestaurantNotifications(dispatch, token);
      }, [dispatch, token]);

      const { notifications } = useSelector((state) => state.notification);
      const handleDelete = async (id) => {
        await deleteNotification(dispatch, token, id);
            getRestaurantNotifications(dispatch, token);
      }
    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps Partner | Notifications</title>
            </Helmet>
            <AdminLayout>
                <Heading classes="pb-4">Notifications</Heading>
                {notifications?.map((item, index) =>
                <>
                    <NotificationContent
                        id={item._id}
                        title={item.title}
                        desc={item.description}
                        for={item.notificationFor}
                        dateTime={item.createdAt}
                        onClick={() => handleDelete(item._id)}
                    />
                </>
                )}
            </AdminLayout>
        </HelmetProvider>
    )
}