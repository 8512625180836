import React from 'react'

import { IoBagCheckSharp } from 'react-icons/io5'

export default function MobileCart({ setOpenModal }) {
    const cart = JSON.parse(localStorage.getItem('cart'));
    return (
        <div onClick={() => setOpenModal(true)} className="flex justify-between fixed bottom-0 left-0 right-0 bg-prim cursor-pointer p-1 sm:p-3 text-white rounded-l-lg rounded-r-lg sm:hidden ml-16 mr-10">
            <div className="flex sm:justify-between items-center">
                <IoBagCheckSharp className="text-lg" />
                {cart ?
                    <p className="font-semibold"> {cart.foodItems.length} Items</p>
                :
                    <p className="font-semibold">0 Items</p>
                }
            </div><br />
            <div className="py-3 px-5 rounded-l-lg rounded-r-lg bg-white text-prim">
                <p>₤{Number.parseFloat(`${cart?cart.subTotal: "00"}`).toFixed(2)}</p>
            </div>
        </div>
    )
}