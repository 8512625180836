import React, { useState, useEffect } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";

import AdminLayout from "../../../components/layout/AdminLayout";
import MyOrder from "../../../components/myOrder";
import Heading from "../../../components/utilities/Heading";
import Tabs from "../../../components/utilities/Tabs";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import { getAllRestaurantsOrders, getRestaurantsOrdersByOrderStatus } from "../../../redux/actions/order";

const menus = ["All Orders", "Pending"]
export default function AdminOrder() {
  const [selectedTab, setSelectedTab] = useState(menus[0]);
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");

      useEffect(() => {
        if(selectedTab === menus[0]){
          getAllRestaurantsOrders(dispatch, token);
        }
        else if(selectedTab === menus[1]){
          getRestaurantsOrdersByOrderStatus(dispatch, token, "Pending");
        }  
      }, [selectedTab, dispatch, token])  
  const { orders, error, status } = useSelector((state) => state.order);
  

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps Partner | Orders</title>
      </Helmet>
      <AdminLayout>
        
        <Heading>Orders</Heading>
        <div className="mt-4">
          <div className="sm:flex md:flex items-center justify-between">
            <Tabs variant="prim" tabs={menus} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          </div>
          <div className=" flex flex-wrap sm:flex md:flex justify-start">
            {status === "success" ? (
              orders.map((order, index) => (
                <div className="w-full sm:w-1/2 md:w-1/3" key={index}>
                  <MyOrder order={order} />
                </div>
              ))
            )  : status === "Failed" ?
              <span className="text-red-700 font-bold text-lg">{error}!!</span>
              :
              <span className="text-red-700 font-bold text-lg">Loading</span>
            }
          </div>
        </div>
      </AdminLayout>
    </HelmetProvider>
  );
}
