import {
  GET_ALL_CONTACT_SUCCESS,
  GET_ALL_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  SEND_REPLY_SUCCESS,
  SEND_REPLY_FAILURE,
} from "../types/contact/type";

const initialState = {
  contact: {},
  contacts: [],
  status: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        contacts: action.payload,
      };

    case GET_ALL_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };

    case SEND_REPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        contacts: action.payload,
      };

    case SEND_REPLY_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed",
        error: action.payload,
      };

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "deleted",
        message: action.payload,
        error: "",
      };
    case DELETE_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        status: "Failed to delete",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
