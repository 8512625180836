import {
    GET_SERVICE_CHARGE_SUCCESS,
    GET_SERVICE_CHARGE_FAILURE,
} from "../types/serviceCharge/types";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const getServiceCharge = async (dispatch) => {
    try {
        const { data: { data } } = await axios(`${apiBaseUrl}${apiVersionPrefix}/service-charge/get-service-charge`, {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
            },
        })
        if (data) {
            dispatch({ type: GET_SERVICE_CHARGE_SUCCESS, payload: data })
        }
    } catch (err) {
        const { response: { data: { error } } } = err;
        dispatch({ type: GET_SERVICE_CHARGE_FAILURE, payload: error })
    }
}