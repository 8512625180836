import {
  GET_ALLERGY_SUCCESS,
  GET_ALLERGY_FAILURE,
} from "../types/allergy/type";

import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const getAllAllergies = async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/allergy/get-all-allergies`,
    );
    if (data) {
      dispatch({ type: GET_ALLERGY_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err);
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ALLERGY_FAILURE, payload: error });
  }
};
