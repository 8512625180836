import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

import CheckoutHeader from "../../../components/checkout/CheckoutHeader";
import YourOrder from "./YourOrder";
import CheckoutDeliveryAddress from "./CheckoutDeliveryAddress";
import CheckoutTimeSchedule from "./CheckoutTimeSchedule";
import CheckoutContact from "./CheckoutContact";
import PaymentOptionCard from "../../../components/checkout/PaymentOptionCard";

import { placeOrder } from "../../../redux/actions/order";
import { getCookie } from "../../../components/utilities/cookie/cookie";

export default function CheckoutHomeDelivery() {
  const history = useHistory();
  const restaurantId = JSON.parse(localStorage.getItem('order')).restaurant;
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('order')));
  const [option, setOption] = useState("cash");
  const [localCard, setLocalCard] = useState({
    total: order?.totalPrice,
    cardNumber: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  useEffect(() => {
    setOrder(JSON.parse(localStorage.getItem('order')))    
  }, []);
  
  localCard.total = order?.totalPrice;
  const { error: cardError } = useSelector(state => state.card)
  const token = getCookie("@partner_token")
  const dispatch = useDispatch()

  const submitOrder = async (e) => {
    e.preventDefault();
    await placeOrder(dispatch, token, localCard, option, history);

  }
  const submitOrderCash = async (e) => {
    e.preventDefault();
    await placeOrder(dispatch, token, localCard, option, history);
  }
  useEffect(() => {
    toast.error(cardError);
  },[cardError])
  return (
    <div className="bg-nat">
      <ToastContainer />
      <div className="mx-12 py-5">
        <button className="bg-prim px-5 py-2 rounded text-white" onClick={() => history.push("/order/" + restaurantId)}>Back</button>
        
      </div>
      <div className="py-16 sm:px-32 md:px-10">
        <div className="w-full sm:flex md:flex">
          <div className="w-full sm:w-2/3 md:w-1/2 h-full p-6">
            <div className="w-full h-full">
              <div className=" flex items-center">
                <div className="w-full text-center">
                  <h1 style={{ fontSize: "2em" }} className="mb-4">
                    Home Delivery
                  </h1>
                </div>
              </div>
              <div className="w-full sm:hidden sm:w-1/3 md:w-1/3 h-full p-6">
                <YourOrder order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutHeader title="Customer Name" order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutDeliveryAddress order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutTimeSchedule />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutContact order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">

                <PaymentOptionCard options={["Card", "Cash", "Terminal"]} localCard={localCard} setLocalCard={setLocalCard} setOption={setOption} option={option} />
                {/* <AgreementCheck
                  className="hidden"
                  beforeLink="Before your order please make sure your "
                  link=" Food Allergy and T&amp;C."
                  afterLink=" If you have anything else, please contact us."
                  setCheck={setCheck}
                  check={check}
                /> */}
                {
                  option === "cash" ? (
                    <button className="mt-6 bg-prim text-white w-full rounded p-2" onClick={submitOrderCash}>Submit Order By Cash</button>
                  ) : (
                    <button className="mt-6 bg-prim text-white w-full rounded p-2" onClick={submitOrder}>Proceed to Checkout</button>
                  )
                }
              </div>
            </div>
          </div>
          <div className="w-full hidden sm:block sm:w-1/3 md:w-1/2 h-full p-6">
            <YourOrder order={order} setOrder={setOrder} />
          </div>
        </div>
      </div>
    </div>
  );
}
