import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/utilities/Button";
import AddRestaurantMenu from "./AddRestaurantMenu";
import ShowMenu from "./ShowMenu";
import UpdateRestaurantMenu from "./UpdateRestaurantMenu/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getCookie } from "../../../../../components/utilities/cookie/cookie";
import { updateCategory } from "../../../../../redux/actions/categories";

export default function Menu() {
  const [addMenu, setAddMenu] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isMenuEdit, setIsMenuEdit] = useState(false);
  const token = getCookie("@partner_token");
  const [formData, setFormData] = useState({
    name: "",
  });
  const { name } = formData;
  const { category } = useSelector((state) => state.categories);
  const id = category._id;
  const dispatch = useDispatch();

  const handelChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    await updateCategory(dispatch, { token, formData }, id);
    setIsEdit((prevState) => !prevState);
  };

  const handleEditState = () => {
    setIsEdit((prevState) => !prevState);
    setFormData({
      name: category.name,
    });
  };
  const handleState = () => {
    setAddMenu((prevState) => !prevState);
  };

  return (
    <div className="w-full pl-2">
      <div className="flex items-center justify-between border-b-2 border-gray-400">
        <div className="flex items-center">
          {isEdit ? (
            <>
              <input
                type="text"
                name="name"
                value={name}
                className="rounded-lg border border-red-700 px-3 py-2 outline-none my-3"
                onChange={handelChange}
              />
              <Button className="ml-5" onClick={handleSubmit}>
                <FontAwesomeIcon
                  className="text-sm mt-1 text-gray-500 cursor-pointer hover:text-black "
                  icon={faCheck}
                />
              </Button>
              <Button className="ml-5" onClick={handleEditState}>
                <FontAwesomeIcon
                  className="text-sm mt-1 text-gray-500 cursor-pointer hover:text-red-700 "
                  icon={faTimes}
                />
              </Button>
            </>
          ) : (
            <>
              <p className="text-2xl font-bold pb-6 text-gray-700 mt-6 mr-4">
                {category.name}
              </p>
              <Button onClick={handleEditState}>
                <FontAwesomeIcon
                  className="text-sm mt-1 text-red-700 cursor-pointer hover:text-black "
                  icon={faEdit}
                />
              </Button>
            </>
          )}
        </div>
        <Button
          className="cursor-pointer px-4 py-3 outline-none rounded-lg bg-prim text-white"
          onClick={() => handleState()}
        >
          {addMenu ? "Cancel" : "Add Menu"}
        </Button>
      </div>

      {addMenu ? (
        <AddRestaurantMenu addMenu={handleState} />
      ) : (
        <div className="flex flex-wrap w-full  justify-between">
          {isMenuEdit ? (
            <UpdateRestaurantMenu setIsMenuEdit={setIsMenuEdit} />
          ) : (
            <ShowMenu setIsMenuEdit={setIsMenuEdit} />
          )}
        </div>
      )}
    </div>
  );
}
