import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import AddOption from "./food-option/AddOption";
import Input from "../../../../../../components/utilities/Input";
import { getCookie } from "../../../../../../components/utilities/cookie/cookie";
import {
  addFood,
  getFoodsByCategory,
} from "../../../../../../redux/actions/foods";
import AddFoodAllergy from "./food-allergy/index";

export default function AddRestaurantMenu({ addMenu }) {
  const { category } = useSelector((state) => state.categories);
  const token = getCookie("@partner_token");
  const categoryId = category._id;
  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: 0,
    quantity: 0,
    category: category._id,
    offer: null,
    discount: null,
    options: [],
    foodAllergy: [],
  });

  const handleChange = (key) => (value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleTextChange = (e) => {
    setFormData({ ...formData, description: e.target.value });
  };

  const handleSubmit = async () => {
    await addFood(dispatch, { token, formData });
    await getFoodsByCategory(dispatch, categoryId);
    addMenu();
  };

  return (
    <div className="mt-4">
      <div className="w-full">
        <div className="flex flex-wrap items-center justify-between w-full  ">
          <div className="w-2/4 px-2">
            <div className="py-1 w-full">
              <p className="mb-2 font-bold text-xs">
                Name<span className="text-prim">*</span>
              </p>
              <Input
                className="w-full outline-none p-1 text-black border border-red-700 rounded"
                placeholder="Add New Product"
                name="name"
                onChange={handleChange("name")}
                value={formData.name}
              />
            </div>
          </div>
          <div className="w-2/4 px-2">
            <div className="py-1 w-full">
              <p className="mb-2 font-bold text-xs">
                Price<span className="text-prim">*</span>
              </p>
              <Input
                className="w-full outline-none p-1 text-black border border-red-700 rounded"
                placeholder="Add Price"
                name="price"
                onChange={handleChange("price")}
                value={formData.price}
              />
            </div>
          </div>
          <div className="w-full px-2">
            <div className="py-1 w-full">
              <p className="mb-2 font-bold text-xs">
                Description <span className="text-prim">(Optional)</span>
              </p>
              <textarea
                className="w-full border border-red-700 py-2 px-4 mr-2 rounded-lg h-20 outline-none"
                placeholder="Food Description"
                name="description"
                onChange={handleTextChange}
                value={formData.description}
              />
            </div>
          </div>
          
          <AddOption formData={formData} setFormData={setFormData} />
          <AddFoodAllergy formData={formData} setFormData={setFormData} />
        </div>
        <div className="pt-4 flex justify-end">
          <button
            className="px-4 py-3 outline-none rounded-lg bg-prim text-white hover:bg-red-600 transition-all"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
