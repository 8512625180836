import axios from "axios";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import { GET_CARD_FAILURE, GET_CARD_SUCCESS } from '../types/card/type'

export const payByCard = async (dispatch, token, orderId, localCard, history) => {
    const id = JSON.parse(localStorage.getItem("restaurant")).restaurant;
    try {
        const { data: { data } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/restaurant/${id}/charge-by-card/${orderId}`, localCard
            , {
                headers: {
                    Authorization: `Bearer ` + token
                }
            });
  
        if (data) {
            dispatch({ type: GET_CARD_SUCCESS, payload: data })
            localStorage.removeItem('cart');
            localStorage.removeItem('order');
            localStorage.removeItem('tableNo');
            history.push("/admin-in-house-orders")
        }
    } catch (err) {
        const { response: { data: { error } } } = err;
        dispatch({ type: GET_CARD_FAILURE, payload: error })
    }
  }