import React from 'react';
import AdminNavbar from './AdminNavbar';
import AdminSidebar from './AdminSidebar';

export default function AdminLayout (props) {
    return (
        <div className="bg-nat">
            <div className="flex">
                <AdminNavbar />
                <div className="w-1/12 sm:w-2/12 md:w-1/12 fixed z-30 left-0 mt-10 sm:mt-20 md:mt-20">
                    <AdminSidebar />
                </div>
            </div>
            <div className="flex">
                <div className="w-1/12 sm:w-2/12 md:w-1/12"></div>
                <div className="w-full  mt-12 pt-2 sm:pt-0 md:pt-0 sm:mt-24 md:mt-24 bg-gray min-h-screen">
                    <div className="p-5 sm:p-8 md:px-8">
                        {props.multi ?
                        <>
                            {props.children}
                        </>
                        :
                        <div className="w-full h-full bg-white p-5 sm:p-6 md:p-6 rounded-lg shadow-md">
                            <div className="w-full sm:pl-4 md:pl-4 mt-3 sm:mt-6 md:mt-0">
                                {props.children}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}