import React from 'react'
import Select from "react-select";
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
export default function SelectCuisine({defualtCuisine,handleCuisine,allCuisine}) {
  return (
    <div><Select 
    closeMenuOnSelect={false}
    components={animatedComponents}
    defaultValue={defualtCuisine}
    isMulti
    options={allCuisine}
    onChange={(e,action)=>handleCuisine(e,action)}
    menuColor='red'
    styles={ {
      menu: (provided, state) => ({
        ...provided,
        width: '200px',
        border: '1px solid #b30235',
        color: "black",
        borderRadius:"10px"
        
       
      }),
    
      control: () => ({
        display:"flex",
        width: 200,
        border:'1px solid #b30235',
        borderRadius:'10px',
      //   height:"3em"
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
      }
    }}
    theme={(theme) => ({
      ...theme,
      borderRadius: 10,
      colors: {
        ...theme.colors,
        primary25: 'lightGray',
        primary: 'black',
      },
    })}
  /></div>
  )
}
