import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import { setCookie, deleteCookie } from "../../components/utilities/cookie/cookie";
import {
  GET_RESTAURANT_FAILURE,
  GET_RESTAURANT_PAYMENT_FAILURE,
  GET_RESTAURANT_SUCCESS,
  GET_RESTAURANT_PAYMENT_SUCCESS,
  GET_ALL_RESTAURANTS_SUCCESS,

} from "../types/restaurant/type";

export const restaurantSignin = async (dispatch, obj, history) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/signin`,
      obj,
    );

    if (data) {
      setCookie("@partner_token", token, 360000);
      localStorage.setItem("restaurant", JSON.stringify(data))
      history.push("/admin");
      dispatch({ type: GET_RESTAURANT_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_FAILURE, payload: error });
  }
};

export const restaurantSignup = async (dispatch, obj, history) => {
  try {
    const {
      data: { data, token },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/signup`,
      obj,
    );

    if (data) {
      setCookie("@partner_token", token, 360000);
      localStorage.setItem("restaurant", JSON.stringify(data))
      history.push("/create-restaurant");
      dispatch({ type: GET_RESTAURANT_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_FAILURE, payload: error });
  }
};

export const updateRestaurantCredential = async (
  dispatch,
  { token, formData },
) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/update-own-restaurant-credentials`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    dispatch({ type: GET_RESTAURANT_SUCCESS, payload: data });
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_FAILURE, payload: error });
  }
};

export const getLoggedrestaurantCredential = async (dispatch, token) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/get-restaurant-credendital`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_FAILURE, payload: error });
  }
};

export const getAllRestaurants = async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/get-all-restaurants`,
    );
    if (data) {
      dispatch({ type: GET_ALL_RESTAURANTS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_FAILURE, payload: error });
  }
};

export const getRestaurantPaymentInfo = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/${id}/payment/get-restaurant-payment`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_PAYMENT_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_PAYMENT_FAILURE, payload: error });
  }
};
export const addRestaurantPaymentInfo = async (dispatch, token, id, formData) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/${id}/payment/add-restaurant-payment`, formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_PAYMENT_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_PAYMENT_FAILURE, payload: error });
  }
};

export const updateRestaurantPaymentInfo = async (dispatch, token, id, formData) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/${id}/payment/update-restaurant-payment`, formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_RESTAURANT_PAYMENT_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_PAYMENT_FAILURE, payload: error });
  }
};
export const restaurantLogout = async (dispatch, partnerToken, deviceToken, history) => {
  try {
    const data = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/restaurant/logout`, { deviceToken: deviceToken },
      {
        headers: {
          Authorization: `Bearer ${partnerToken}`,
        },
      }
    );
    
    if (data.status === 200) {  
      dispatch({ type: GET_RESTAURANT_SUCCESS, payload: data });
      deleteCookie("@partner_token");
      deleteCookie("@device_token");
      window.localStorage.removeItem("restaurant");
      window.location.reload(); 
    }
  } catch (err) {
    // console.log(err)
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_RESTAURANT_FAILURE, payload: error });
  }
};