import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../../../components/utilities/cookie/cookie";
import Toggle from "../../../../../../components/utilities/form/Toggle.js";
import Input from "../../../../../../components/utilities/Input";
import {
  getFoodsByCategory,
  updateFood,
} from "../../../../../../redux/actions/foods";
import FoodOption from "../AddRestaurantMenu/food-option/FoodOption";
import AddFoodAllergy from "../AddRestaurantMenu/food-allergy";
import { BsTrash } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';

export default function UpdateRestaurantMenu({ setIsMenuEdit }) {
  const { food } = useSelector((state) => state.foods);
  const { category } = useSelector((state) => state.categories);
  const { options } = useSelector(state => state.option);
  const token = getCookie("@partner_token");

  const categoryId = category._id;
  const id = food._id;
  const dispatch = useDispatch();
  const [edit,setEdit]=useState(true);
  const [formData, setFormData] = useState({
    name: food.name,
    description: food.description,
    basePrice: food.basePrice,
    quantity: food.quantity,
    category: category._id,
    options: food.options,
    foodAllergy: food.foodAllergy,
    // isAvailable: food.isAvailable,
  });
  if (options?.length > 0) {
    formData.options = options;
  }
  else {
    formData.options = [];
  }


  const handleChange = (key) => (value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleTextChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, description: e.target.value });
  };

  const handleSubmit = async () => {
    await updateFood(dispatch, { token, formData }, id);
    await getFoodsByCategory(dispatch, categoryId);
    // setIsMenuEdit(false);
    setEdit(true)
  };
  const handleSave = async () => {
    await updateFood(dispatch, { token, formData }, id);
    await getFoodsByCategory(dispatch, categoryId);
    setIsMenuEdit(false);
    // setEdit(true)
  };

  const handleCancel = () => {
    setIsMenuEdit(false);
  };

  const handleToggleLClick = () => {
    setFormData({ ...formData, isAvailable: !formData.isAvailable });
  };

  return (
    <div className="mt-4 w-full">
      <div className="w-9/10  overflow-x-hidden">
        {edit?<div className="w-full pb-12 ">
        <div className="py-2 w-full flex justify-between items-center px-2  mt-5">
        <p className=" text-base font-medium w-32 text-gray-600">
             Food
            </p>
        <div className='w-1/3 flex justify-end mr-3'>
             <button onClick={()=>(setEdit(false))} className='text-white p-3 mr-3 bg-blue-700 rounded-lg' ><FiEdit /></button>
              {/* <button  className='text-white p-3 mr-3 bg-red-700 rounded-lg'><BsTrash  /></button> */}
            </div>
            
          </div>
          <div className="py-3 w-full flex justify-between items-center px-2 bg-red-200 mt-5">
            <p className="text-sm  w-32 text-gray-600">
              Name:  <b className="text-gray-800"> {food.name}</b>
            </p>
            
            <p className="text-sm  w-32 text-gray-600">
              Price:  <b className="text-gray-800"> £{food.basePrice}</b>
            </p>
           
            
          </div>
        </div>:
        (
          <>
          <div className="flex flex-wrap items-center justify-between w-full  ">
          <div className="w-1/2 px-2">
            <div className="py-1 w-full">
              <p className="mb-2 font-bold text-xs">
                Name<span className="text-prim">*</span>
              </p>
              <Input
                className="w-full outline-none p-1 text-black border border-red-700 rounded"
                placeholder="Add New Product"
                name="name"
                onChange={handleChange("name")}
                value={formData.name}
              />
            </div>
          </div>
          <div className="w-1/2 px-2">
            <div className="py-1 w-full">
              <p className="mb-2 font-bold text-xs">
                Price<span className="text-prim">*</span>
              </p>
              <Input
                className="w-full outline-none p-1 text-black border border-red-700 rounded"
                placeholder="Add Price"
                name="price"
                onChange={handleChange("basePrice")}
                value={formData.basePrice}
              />
            </div>
          </div>
          {/* <div className="flex p-2 items-center ">
            <span className="mr-2 font-bold text-xs">Active</span>
            <button onClick={handleToggleLClick}>
              <Toggle checked={formData.isAvailable} />
            </button>
          </div> */}
          <div className="w-full px-2">
            <div className="py-1 w-full">
              <p className="mb-2 font-bold text-xs">Description</p>
              <textarea
                className="w-full border border-red-700 py-2 px-4 mr-2 rounded-lg h-20 outline-none"
                placeholder="Food Description"
                name="description"
                onChange={handleTextChange}
                value={formData.description}
              />
            </div>
          </div>

          <AddFoodAllergy formData={formData} setFormData={setFormData} />
        </div>
        <div className="pt-4 flex justify-end">
          <button
            className="px-4 py-3 outline-none rounded-lg bg-prim text-white hover:bg-red-600 transition-all"
            onClick={handleSubmit}
          >

            Save
          </button>
          <button
            className="px-4 py-3 outline-none rounded-lg bg-prim text-white hover:bg-red-600 transition-all ml-2"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div></>
        )}
      
        

        <FoodOption formData={formData} setFormData={setFormData} />
        <div className="pt-4 flex justify-end">
          <button
            className="px-4 py-3 outline-none rounded-lg bg-prim text-white hover:bg-red-600 transition-all"
            onClick={handleSave}
          >

            Done
          </button>
          <button
            className="px-4 py-3 outline-none rounded-lg bg-prim text-white hover:bg-red-600 transition-all ml-2"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
