import React, { useState, useEffect } from "react";

import { FiEdit2, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import { getOwnRestaurantDelivery } from "../../../../redux/actions/restaurantDelivery.js";
import { getCookie } from "../../../../components/utilities/cookie/cookie.js";

import Toggle from "../../../../components/utilities/form/Toggle.js";
import Icon from "../../../../components/utilities/Icon";
import EditRestaurantDetailsDelivery from "../AdminRestutantDetails/AdminRestaurantDetails/EditRestaurantDetails/Delivery";

export default function Delivery() {
  const [editable, setEditable] = useState(false);
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  useEffect(() => {
    getOwnRestaurantDelivery(dispatch, token);
  }, [dispatch, token]);

  const { restaurantDelivery } = useSelector(
    (state) => state.restaurantDelivery,
  );
  // console.log(restaurantDelivery)
  return (
    <div className="mt-5 px-3 sm:px-0">
      <div className="w-full flex justify-between">
        <div className="border-b-2 border-gray-400 pb-2">
          <p className="md:px-6 text-lg font-bold">Delivery</p>
        </div>
        <div className="flex items-center w-full justify-end mr-5">
          {!editable ? (
            <Icon onClick={() => setEditable(true)} icon={FiEdit2} />
          ) : (
            <div className="flex justify-between">
              <Icon onClick={() => setEditable(false)} icon={FiX} />
            </div>
          )}
        </div>
      </div>
      {!editable && (
        <div className="w-full flex flex-wrap items-center mt-2">
          <div className="w-full md:w-1/4 flex justify-between">
            <div className="w-full flex items-center md:px-6">
              <div className="mt-1 w-full flex items-center">
                <p className="text-sm text-sec font-semibold m-3">
                  Delivery Available
                </p>
                <Toggle checked={restaurantDelivery.isDeliveryAvailable} />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 flex justify-between">
            <div className="w-full flex items-center md:px-6">
              <div className="mt-1 w-full flex items-center">
                <p className="text-sm text-sec font-semibold m-3">
                  Rider Service
                </p>
                <Toggle checked={restaurantDelivery.isRiderService} />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/4 flex justify-between">
            <div className="w-full flex items-center md:px-6">
              <div className="mt-1 w-full flex items-center">
                <p className="text-sm text-sec font-semibold m-3">
                  Delivery Area Limit
                </p>
                <p className="text-sm text-gray-700">
                  {restaurantDelivery.areaLimit} Mile
                </p>
              </div>
            </div>
          </div>
          
          <div className="w-full md:w-1/4 flex justify-between">
            <div className="w-full flex items-center md:px-6">
              <div className="mt-1 w-full flex items-center">
                <p className="text-sm text-sec font-semibold m-3">
                  Delivery Charge
                </p>
                <p className="text-sm text-gray-700">
                  £ {restaurantDelivery.deliveryCharge}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full block sm:flex justify-between">
            <div className="w-full flex items-center md:px-6">
              <div className="mt-1 w-full flex items-center">
                <p className="text-sm text-sec font-semibold m-3">
                  Delivery Over Area Limit:
                </p>
                <p className="text-sm text-gray-700">{restaurantDelivery.deliveryChargeOffAreaLimit} Mile
                </p>
              </div>
            </div>
            <div className="w-full  flex justify-between">
            <div className="w-full flex items-center md:px-6">
              <div className="mt-1 w-full flex items-center">
                <p className="text-sm text-sec font-semibold m-3">
                Delivery Charge Over Area:
                </p>
                <p className="text-sm text-gray-700">
                  £{restaurantDelivery.deliveryChargeOffArea}
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>
      )}
      {editable && <EditRestaurantDetailsDelivery setEditable={setEditable} />}
    </div>

  );
}
