import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import ProductItem from './ProductItem'
import { getFoodsByCategory } from "../../../../redux/actions/foods";

export default function Products({ setCart, cart }) {
    const { category, loading } = useSelector(state => state.categories);
    const categoryId = category?._id;
    const { foods } = useSelector(state => state.foods);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!loading) {
            getFoodsByCategory(dispatch, categoryId)
        }
    }, [dispatch, categoryId, loading])

    return (
        <div className="">
            <p className="text-xl md:uppercase font-bold text-center text-gray-700">
                    {/* {category.name} */}
            </p>
            <div className='grid lg:grid-cols-3 gap-3'>
                    {foods && foods.length > 0 ? (
                        foods.map((food, index) => (
                            <ProductItem
                                food={food}
                                index={index}
                                name={food.name}
                                price={food.price}
                                setCart={setCart}
                                cart={cart}
                            />
                        ))
                    ) : (
                        <span className="text-red-700 font-bold text-lg">No Food is found!</span>
                    )}
            </div>
        </div>
    )
}
