import React, { useState } from "react";

export default function CheckoutCustomerInfo({ order, setOrder, isEdit, setIsEdit,setIsOpen }) {
  const [formData, setFormData] = useState({
    customerName: ""
  })

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, customerName: e.target.value });

  }

  const handleClick = (e) => {
    e.preventDefault();
    let localOrder = JSON.parse(localStorage.getItem('order'));
    localOrder = { ...localOrder, customerName: formData.customerName };
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
    setIsEdit(false)
    setIsOpen(false)

  }

  return (
    <div className="sm:flex md:flex flex-wrap items-center mt-3">
   
        {!isEdit ? (
          null
        ) : (
          <div className="sm:flex flex-nowrap items-center">
              <input type="text" className="border-2 border-prim rounded py-2 px-2 w-3/4" value={formData.customerName} onChange={handleChange} placeholder="Add another name" />
              <button onClick={handleClick} className=" bg-prim px-5 py-2 rounded mx-2 text-white">Add</button>
          </div>

        )}
    </div>
  );
}
