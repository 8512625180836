import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { placeOrder } from "../../../redux/actions/order";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';

import { getCookie } from "../../../components/utilities/cookie/cookie";

import CheckoutHeader from './../../../components/checkout/CheckoutHeader';
import CheckoutTimeSchedule from './CheckoutTimeSchedule';
import CheckoutContact from './CheckoutContact';
import PaymentOptionCard from './../../../components/checkout/PaymentOptionCard';
// import AgreementCheck from './../../../components/utilities/form/AgreementCheck';
import YourOrder from './YourOrder';

const CheckoutCollection = () => {
  const history = useHistory();
  const [check] = useState(true);
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('order')) ? JSON.parse(localStorage.getItem('order')) : '');
  const [option, setOption] = useState("cash");
  const [localCard, setLocalCard] = useState({
    total: order?.totalPrice,
    cardNumber: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  useEffect(() => {
    setOrder(JSON.parse(localStorage.getItem('order')))    
  }, []);

  const { error: cardError } = useSelector(state => state.card)

  localCard.total = order?.totalPrice;
  
  const token = getCookie("@partner_token")
  const dispatch = useDispatch()

  const submitOrder = async (e) => {
    e.preventDefault();
    if (check) {
        await placeOrder(dispatch, token, localCard, option, history);
    } else {
      alert("Please agree to our terms and conditions");
    }
  }
  const submitOrderCash = async (e) => {
    e.preventDefault();
    if (check) {
      await placeOrder(dispatch, token, localCard, option, history);
    } else {
      alert("Please agree to our terms and conditions");
    }
  }
  useEffect(() => {
    toast.error(cardError);
  },[cardError])
  return (
    <div className="bg-nat">
      <ToastContainer />
      <div className="mx-12 py-5">
        <button className="bg-prim px-5 py-2 rounded text-white" onClick={() => history.push("/admin-in-house-orders")}>Back</button>
       
      </div>
      <div className="py-16 sm:px-32 md:px-10">
        <div className="w-full sm:flex md:flex">
          <div className="w-full sm:w-2/3 md:w-1/2 h-full p-6">
            <div className="w-full h-full">
              <div className=" flex items-center">
                <div className="w-full text-center">
                  <h1 style={{ fontSize: "2em" }} className="mb-4">
                    Collection
                  </h1>
                </div>
              </div>
              <div className="w-full sm:hidden sm:w-1/3 md:w-1/3 h-full p-6">
                <YourOrder order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutHeader title="Customer Name" order={order} setOrder={setOrder} />
              </div>

              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutTimeSchedule order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutContact order={order} setOrder={setOrder} />
              </div>

              <div className="w-full bg-white py-4 ssm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <PaymentOptionCard options={["Card", "Cash", "Terminal"]} localCard={localCard} setLocalCard={setLocalCard} setOption={setOption} option={option} />
                {/* <AgreementCheck
                  className="hidden"
                  beforeLink="Before your order please make sure your "
                  link=" Food Allergy and T&amp;C."
                  afterLink=" If you have anything else, please contact us."
                  check={check}
                  setCheck={setCheck}
                /> */}
                {
                  option === "cash" ? (
                    <button className="mt-6 bg-prim text-white w-full rounded p-2" onClick={submitOrderCash}>Submit Order By Cash</button>
                  ) : (
                    <button className="mt-6 bg-prim text-white w-full rounded p-2" onClick={submitOrder}>Submit order</button>
                  )
                }
              </div>
            </div>
          </div>
          <div className="w-full hidden sm:block sm:w-1/3 md:w-1/2 h-full p-12">
            <YourOrder order={order} setOrder={setOrder} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutCollection;
