import {
    GET_OPTION_SUCCESS,
    GET_OPTION_FAILURE,
    GET_ALL_OPTIONS_SUCCESS,
    DELETE_OPTION_SUCCESS
} from "../types/option/type";

const initialState = {
    option: {},
    options: [],
    optionStatus: null,
    optionError: null,
    loading: true,
    message: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_OPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                optionStatus: "success",
                option: action.payload,
                optionError: ""
            };

        case GET_OPTION_FAILURE:
            return {
                ...state,
                loading: false,
                option: {},
                optionStatus: "Failed",
                optionError: action.payload,
            };
        case GET_ALL_OPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                options: action.payload,
                optionError: null,
                optionStatus: "success",

            }
        case DELETE_OPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
                optionError: null,
                optionStatus: "success",

            }
        default:
            return state;
    }
};

export default reducer;