import React from 'react';
// import { Redirect } from 'react-router-dom';

import { useJwt } from 'react-jwt'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

// Routes
import partnerRoutes from './PartnerRoutes';

// Components
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import AdminRestaurants from "../pages/partner/AdminRestaurants";
import { getCookie } from "../components/utilities/cookie/cookie";

const MasterRoute = () => {
    const { isExpired } = useJwt(getCookie('@partner_token'));
    return (
        <Router>
            <Switch>
                {partnerRoutes.map((route, i) => <PrivateRoute key={i} {...route} />)}
                {/* <Route path="/sign-in" component={Login} /> */}
                {isExpired? <Route path="/" component={Login} />: <Route path="/" component={AdminRestaurants} /> }
                
            </Switch>
        </Router>
    )
}

export default MasterRoute;
