import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  getLoggedrestaurantCredential,
  updateRestaurantCredential,
} from "../../../../redux/actions/restaurant";

import "./index.css";
import { getCookie } from "../../../../components/utilities/cookie/cookie";
import BusinessProfileHeader from "./BusinerProfileHeader";
import BusinessProfileBody from "./BusinessProfileBody";
import Button from "../../../../components/utilities/buttons/Button";
import Input from "../../../../components/utilities/Input";

const BusinessProfile = () => {
  const [editable, setEditable] = useState(false);

  const { restaurant } = useSelector((state) => state?.restaurant);

  const dispatch = useDispatch();

  const token = getCookie("@partner_token");

  const [formData, setFormData] = useState({
    ownerName: "",
    mobileNo: "",
    UTR_no: "",
    hygieneCertificationNo: "",
  });

  const { ownerName, mobileNo, UTR_no, hygieneCertificationNo } = formData;

  const handleClick = () => {
    updateRestaurantCredential(dispatch, { token, formData });
    getLoggedrestaurantCredential(dispatch, token);
    setEditable(false);
  };

  const handleUpdate = () => {
    setFormData({
      ...formData,
      ownerName: restaurant.ownerName,
      mobileNo: restaurant.mobileNo,
      UTR_no: restaurant.UTR_no,
      hygieneCertificationNo: restaurant.hygieneCertificationNo,
    });
    setEditable(true);
  };

  const handleChange = (key) => (value) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <div className="bg-white rounded-t-lg px-5 py-4 flex flex-wrap items-center">
      <div className="upper">
        <BusinessProfileHeader />
        {editable ? (
          <Button onClick={handleClick}>
            <FontAwesomeIcon className="icon-customize" icon={faCheck} />
          </Button>
        ) : (
          <Button onClick={handleUpdate}>
            <FontAwesomeIcon className="icon-customize" icon={faEdit} />
          </Button>
        )}
      </div>
      {!editable ? (
        <BusinessProfileBody />
      ) : (
        <div className="inputs">
          <div className="input-parent">
            <p className="font-bold text-xs sm:text-sm md:text-sm mb-3">
              Full Name<span className="sm:hidden md:hidden">:</span>
            </p>
            <Input
              value={ownerName}
              onChange={handleChange("ownerName")}
              className="input"
            />
          </div>
          <div className="input-parent">
            <p className="font-bold text-xs sm:text-sm md:text-sm mb-3">
              Contact Number<span className="sm:hidden md:hidden">:</span>
            </p>
            <Input
              value={mobileNo}
              onChange={handleChange("mobileNo")}
              className="input"
            />
          </div>
          <div className="input-parent">
            <p className="font-bold text-xs sm:text-sm md:text-sm mb-3">
              Company or UTR No<span className="sm:hidden md:hidden">:</span>
            </p>
            <Input
              value={UTR_no}
              onChange={handleChange("UTR_no")}
              className="input"
            />
          </div>
          <div className="input-parent">
            <p className="font-bold text-xs sm:text-sm md:text-sm mb-3">
              Hygiene Certificate No
              <span className="sm:hidden md:hidden">:</span>
            </p>
            <Input
              value={hygieneCertificationNo}
              onChange={handleChange("hygieneCertificationNo")}
              className="input"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessProfile;
