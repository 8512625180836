import React, { useState } from "react";

import Category from "./RestaurantCategory/Category";
import RestaurantMenu from "./RestaurantMenu";
import SpecialOffer from "./RestaurantSpecialOffer/SpecialOffer";

export default function AddRestaurant() {
  const [openSpecialOffer,setOpenSpecialOffer] = useState(false);

  return (
    <div className="w-full mr-6 bg-white shadow-lg rounded-lg">
      <div className="p-3">
        <div className="w-full flex flex-wrap">
          <Category setOpenSpecialOffer={setOpenSpecialOffer} />
          {openSpecialOffer ? <SpecialOffer /> : <RestaurantMenu />}
        </div>
      </div>
    </div>
  );
}
