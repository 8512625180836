import {
  GET_ALL_CONTACT_SUCCESS,
  GET_ALL_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  SEND_REPLY_SUCCESS,
  SEND_REPLY_FAILURE
} from "../types/contact/type";

import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const getAllRestaurantAllContacts = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/contact/get-restaurant-all-contacts/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_CONTACT_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ALL_CONTACT_FAILURE, payload: error });
  }
};

export const deleteRestaurantContact = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.delete(
      `${apiBaseUrl}${apiVersionPrefix}/contact/delete-contact/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: DELETE_CONTACT_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: DELETE_CONTACT_FAILURE, payload: error });
  }
};

export const sendReply = async (dispatch, token, id, formData) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/contact/send-reply/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: SEND_REPLY_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: SEND_REPLY_FAILURE, payload: error });
  }
};
