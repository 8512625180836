/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { FiX, FiShoppingCart } from "react-icons/fi";

import Cart from "../../../components/cart";
import Categories from "./categories";
import Products from "./products";
import MobileCart from "../../../components/cart/MobileCart";
import CartPopup from "../../../components/cart/CartPopup";
import Icon from "../../../components/utilities/Icon";

import { getRestaurantCategories } from "../../../redux/actions/categories";
import { getRestaurantOwnInfo } from "../../../redux/actions/restaurantInfo";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import AdminLayout from "../../../components/layout/AdminLayout";
import { getSingleOrder } from "../../../redux/actions/order";
import EposSidebar from "../Epos/eposSidebar";

export default function EposAddItem({ setEpos }) {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const restaurantId = JSON.parse(
    localStorage.getItem("restaurant")
  ).restaurant;

  const { categories, category } = useSelector((state) => state.categories);

  const { restaurantInfo } = useSelector((state) => state?.restaurantInfo);
  const { order: pOrder } = useSelector((state) => state.order);


  const token = getCookie("@partner_token");
  const { id } = useParams();
  const orderId = id;
  useEffect(() => {
    getSingleOrder(dispatch, orderId, token);
  }, [dispatch, orderId, token]);

  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")));
  const [order, setOrder] = useState({
    subTotal: cart?.subTotal,
    totalPrice: pOrder?.totalPrice,
    paymentMethod: "",
    cartItems: localStorage.getItem("cart"),
    orderType: pOrder?.orderType,
  });

  const [isAdded, setIsAdded] = useState({
    name: "",
    add: false,
  });

  useEffect(() => {
    localStorage.setItem("currentPage", "orderPage");

    const myCart = JSON.parse(localStorage.getItem("cart"));
    setOrder({ ...order, cartItems: myCart });
    localStorage.setItem("order", JSON.stringify(order));
  }, [restaurantId]);
  useEffect(() => {
    const myCart = JSON.parse(localStorage.getItem("cart"));
    setOrder({
      ...order,
      cartItems: JSON.stringify(myCart),
      subTotal: myCart?.subTotal,
    });
    localStorage.setItem("order", JSON.stringify(order));
  }, [cart]);
  useEffect(
    () => {
      const abortController = new AbortController();
      getRestaurantCategories(
        dispatch,
        localStorage.getItem("restaurantUniId")
      );
      return () => abortController.abort();
    },
    [dispatch],
    [localStorage.getItem("restaurantUniId")]
  );

  useEffect(() => {
    getRestaurantOwnInfo(dispatch, token);
    localStorage.setItem("order", JSON.stringify(order));
  }, [dispatch, token]);
  const closeOrOpen =
    restaurantInfo?.restaurantTiming?.restaurantTiming.available.find(
      (tm) => tm.day === new Date().getDay()
    );
    
  const handleCart = () => {
    if (cart.foodItems.length > 0) {
      setOpenModal(true);
    } else {
      alert("You have no items in your cart!");
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps | Partner Inbox</title>
      </Helmet>
      <div className="flex justify-between">
        <EposSidebar />
        <div className="flex justify-end my-5 md:my-0">
          {cart && (
            <div
              onClick={handleCart}
              className="sm:hidden block px-1 sm:px-3 md:px-3 cursor-pointer"
            >
              <div className="relative">
                {cart.foodItems.length > 0 && (
                  <>
                    <div className="flex bg-prim w-3 h-3 items-center justify-center absolute p-2 top-0 right-0 rounded-full -mt-2 -mr-2 text-white text-xs">
                      {cart.foodItems.length}
                    </div>
                    <Icon icon={FiShoppingCart} />
                  </>
                )}
              </div>
            </div>
          )}
          {/* <Icon icon={FiX} className="cursor-pointer" onClick={() => setEpos(false)} /> */}
        </div>
        <div className="">
          <div className=" w-full flex justify-end px-3">
            {/* <OrderTypeDropdown
              options={["Collection", "Dine In", "Home Delivery"]}
            /> */}
            <Icon
              icon={FiX}
              className="cursor-pointer"
              onClick={() => setEpos(false)}
            />
          </div>
          {/* <div className="sm:flex md:flex px-1 bg-gray pb-8">
                    {
                        category && categories ? (
                            <>
                                <Categories />
                                <Products setCart={setCart} cart={cart} />
                                <div className="hidden md:block w-full sm:w-3/12 md:w-3/12 mt-10 sm:mt-0 md:mt-0 shadow-lg">
                                    <Cart cart={cart} setCart={setCart} isAdded={isAdded} setIsAdded={setIsAdded} isAvailable={closeOrOpen.isAvailable
                                    } />
                                </div>
                            </>
                        ) : <span>Loading</span>
                    }
                </div> */}
          <div class="grid grid-cols-12 grid-flow-col">
            {category && categories ? (
              <>
                <div className="flex flex-col col-span-9">
                  <Categories />
                  <Products setCart={setCart} cart={cart} />
                </div>

                <div className="hidden lg:block col-span-3 py-4 px-3">
                  <Cart
                    cart={cart}
                    setCart={setCart}
                    isAdded={isAdded}
                    setIsAdded={setIsAdded}
                    isAvailable={closeOrOpen?.isOpen}
                  />
                </div>
              </>
            ) : (
              <span>Loading</span>
            )}
          </div>
          <MobileCart setOpenModal={setOpenModal} />
        </div>
        <CartPopup
          openModal={openModal}
          setOpenModal={setOpenModal}
          cart={cart}
          setCart={setCart}
          isAvailable={closeOrOpen?.isOpen}
        />
      </div>
    </HelmetProvider>
  );
}
