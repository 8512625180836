import { FiArrowLeft, FiX } from 'react-icons/fi';
import Modal from 'react-modal';

import Icon from '../utilities/Icon';
import Cart from './index'

export default function CartPopup({ openModal, setOpenModal, cart, setCart, isAvailable }) {
    
    return (
        <Modal
            isOpen={openModal}
            style={customStyles}
            className="w-full outline-none absolute border border-none"
            ariaHideApp={false}
        >
            <div className="w-full h-screen flex items-center justify-center">
                <div className="w-full md:w-1/3 px-4 md:px-0">
                    <div className="bg-white rounded-lg p-6">
                    <div className="flex justify-between">
                        <Icon icon={FiArrowLeft}
                            onClick={() => setOpenModal(false)}
                            classes="cursor-pointer text-gray-600 hover:text-black trans text-xl font-bold"
                        />
                        <Icon icon={FiX}
                            onClick={() => setOpenModal(false)}
                            classes="cursor-pointer text-gray-600 hover:text-black trans text-xl font-bold"
                        />
                        </div>
                        <Cart cart={cart} setCart={setCart} isAvailable={isAvailable} />
                    </div>
                </div>

            </div>
    </Modal>
    )
}

const customStyles = {
    content: {
        top: '30px',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        //   zIndex                : '500'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, .1)'
    },
    modal: {
        outline: "none"
    }
};
