import React from 'react';
import {Link} from 'react-router-dom';

import PartnerLogo from '../../images/FoodApps_Partner.png'

export default function LoginFormHeader() {
    return (
        <div className="flex justify-center">
            <Link href="/">
                <img className="h-16" src={PartnerLogo} alt="Partner Logo"/>
            </Link>
        </div>
    )
}
