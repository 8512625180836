import React from "react";

const Input = (props) => {

    const {
        name,
        onChange,
        type,
        value,
        className,
        placeholder
    } = props;

    const handleChange = (e) => {
        const {value} = e.target;
        onChange(value);
    };

    return (
        <input name={name}
               onChange={handleChange}
               type={type}
               value={value}
               className={className}
               placeholder={placeholder}
        />
    );
};

export default Input;
