import { useEffect } from 'react';
import './App.css';
import './style/index.scss'
import MasterRoute from '../src/router/MasterRoute';
import app from './config/firebase'
import { getMessaging, onMessage } from "firebase/messaging";

function App() {
    useEffect(() => {
        const messaging = getMessaging(app);
        onMessage(messaging, function (payload) {
            console.log('Message received. ', payload);
        })
    }, [])

    return (
        <MasterRoute />
    );
}

export default App;
