import React from 'react'

export default function CartAllergyNote({ cart, setCart }) {
    const handleChange = (e) => {
        setCart({ ...cart, note: e.target.value })
    }
    return (
        <div className="py-3 text-left">
            <p className="p-1 text-black font-bold text-sm">Extra Allergy Note</p>
            <textarea className="rounded-lg w-full border border-red-700 outline-none px-3 py-2" onChange={handleChange} name="note" value={cart.note}></textarea>
        </div>
    )
}
