import React from 'react'

const OptionHeader = ({ cancel, setCancel }) => {
    const handleOption = (e) => {
        e.preventDefault();
        setCancel(true)
    }

    return (
        <div className="w-full flex justify-between mt-2 items-center">
            <h1 className="text-sm font-semibold ml-2">Options</h1>
            <div className="flex justify-end">
                <button className={!cancel ? " mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" : "hidden"} onClick={handleOption}>{"Add Options"}</button>
            </div>
        </div>
    )
}

export default OptionHeader
