import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../../components/utilities/cookie/cookie";
import { createRestaurantAddress } from "../../../../../redux/actions/address";
import Message from "../../../../../components/admin/admin/Message";

export default function Address({ setState, state }) {
  const token = getCookie("@partner_token");
  const dispatch = useDispatch();
  const { address } = useSelector(state => state.address);
  const [formData, setFormData] = useState({
    address: "",
    postCode: "",
    phoneNo: "",
  });
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createRestaurantAddress(dispatch, token, formData);
    setState({ ...state, isAddress: true });
  };

  if (address._id) {
    return (
      <Message value="Address" />
    )
  }

  return (
    <div className="pb-3 border-b-2">
      <h1 className="w-32 border-b-2 py-3 text-lg font-bold">Address</h1>
      <form onSubmit={handleSubmit}>
        <div className="py-2">
          <div className="w-full">
            <input
              placeholder="Address"
              className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center mt-2">
            <div className="w-full ">
              <input
                placeholder="Ex nes2fds"
                className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
                name="postCode"
                value={formData.postCode}
                onChange={handleChange}
              />
            </div>
            <div className="w-full pl-2">
              <input
                placeholder="Contact Number"
                className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end ml-2">
          <button className="px-4 py-2 text-white bg-red-700 rounded-lg">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
