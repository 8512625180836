import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';

import { getCookie } from "../../../../../components/utilities/cookie/cookie";
import { uploadImage, getRestaurantOwnInfo } from './../../../../../redux/actions/restaurantInfo';

import camera from '../../../../../images/camera.png'

export default function Banner() {
  const [editable, setEditable] = useState(false);

  const token = getCookie("@partner_token");
  const dispatch = useDispatch();
  const [filelogo, setFilelogo] = useState("");
  const [filenamelogo, setFilenamelogo] = useState("");

  const [filebanner, setFilebanner] = useState("");
  const [filenamebanner, setFilenamebanner] = useState("");

  const { restaurantInfo, status } = useSelector((state) => state.restaurantInfo);
  console.log(filenamebanner, filenamelogo)
  const handleChangeLogo = (e) => {
    setFilelogo(e.target.files[0]);
    setFilenamelogo(e.target.files[0].name);
  };

  const handleChangeBanner = (e) => {
    setFilebanner(e.target.files[0]);
    setFilenamebanner(e.target.files[0].name);
  };

  const formData = new FormData();
  const handleSubmit = async (e) => {

    formData.append('banner', filebanner)
    formData.append('logo', filelogo)
    e.preventDefault();

    await uploadImage(dispatch, formData, token, restaurantInfo._id);
    await getRestaurantOwnInfo(dispatch, token);
    if (status === "success") {
      setEditable(false);
    }
  };

  return (
    <div>
      {!editable && (
        <div
          style={{
            backgroundImage: `url(https://foodapps.uk/images/banners/banner.jpg)` || `url(${restaurantInfo.banner})`,
          }}
          className="mx-auto  h-56 w-full p-4 border-4 rounded-md  my-5 flex justify-between bg-no-repeat shadow-lg"
        >
          <div className="my-32 mx-8">
            <img
              className="h-16 w-16 rounded-full border-4"
              src={restaurantInfo.logo}
              alt="Prince Logo"
            />
          </div>
          <div className="p-1 h-8 w-10 bg-black rounded-lg">
            {!editable ? (
              <div className="flex justify-center items-center p-1">
                <FontAwesomeIcon
                  icon={faPen}
                  className="text-white"
                  onClick={() => setEditable(true)}
                />
              </div>
            ) : (
              <div className="flex justify-between items-between">
                <div className="border-solid border-1 border-white">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-white"
                    onClick={() => setEditable(false)}
                  />
                </div>
                <div className="border-solid border-1 border-white">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-white"
                    onClick={() => setEditable(false)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {
        editable && (
          <div>
            {filebanner && (
              <form
                onSubmit={handleSubmit}
                style={{
                  backgroundImage: `url(${URL.createObjectURL(filebanner)})`,
                }}
                className="mx-auto  h-56 w-full p-4 border-4 rounded-md  my-5 flex justify-between bg-no-repeat shadow-lg"
              >
                <div className="my-32 mx-8">
                  <label className="cursor-pointer rounded-full">
                    {filelogo && (
                      <img
                        className="h-16 w-16 rounded-full border-4"
                        src={URL.createObjectURL(filelogo)}
                        alt="Prince Logo"
                      />
                    )}
                    {!filelogo && (
                      <img
                        className="h-16 w-16 rounded-full border-4"
                        src={camera}
                        alt="Prince Logo"
                      />
                    )}
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleChangeLogo}
                    />
                  </label>
                </div>
                <div className="flex items-center">
                  <label className="w-64 flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150">
                    <span className="mt-2 text-base leading-normal">
                      Select an Image
                    </span>
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleChangeBanner}
                    />
                  </label>
                </div>
                <div className="p-1 h-8 w-10 bg-black rounded-lg">
                  <div className="flex justify-between items-between">
                    <div className="border-solid border-1 border-white">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-white cursor-pointer"
                        onClick={() => setEditable(false)}
                      />
                    </div>
                    <div className="border-solid border-1 border-white">
                      <button type="submit">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-white cursor-pointer"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {!filebanner && (
              <form
                onSubmit={handleSubmit}
                style={{
                  backgroundColor: "rgba(30, 35, 40, 0.6)",
                }}
                className="mx-auto  h-56 w-full p-4 border-4 rounded-md  my-5 flex justify-between bg-no-repeat shadow-lg"
              >
                <div className="my-32 mx-8">
                  <label className="cursor-pointer rounded-full">
                    {filelogo && (
                      <img
                        className="h-16 w-16 rounded-full border-4"
                        src={URL.createObjectURL(filelogo)}
                        alt="Prince Logo"
                      />
                    )}
                    {!filelogo && (
                      <img
                        className="h-16 w-16 rounded-full border-4"
                        src={camera}
                        alt="Prince Logo"
                      />
                    )}
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleChangeLogo}
                    />
                  </label>
                </div>
                <div className="flex items-center">
                  <label className="w-64 flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-red-600 hover:text-white text-red-600 ease-linear transition-all duration-150">
                    <span className="mt-2 text-base leading-normal">
                      Select an Image
                    </span>
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleChangeBanner}
                    />
                  </label>
                </div>
                <div className="p-1 h-8 w-10 bg-black rounded-lg">
                  <div className="flex justify-between items-between">
                    <div className="border-solid border-1 border-white">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-white cursor-pointer"
                        onClick={() => setEditable(false)}
                      />
                    </div>
                    <div className="border-solid border-1 border-white">
                      <button type="submit">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-white cursor-pointer"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        )
      }
    </div>
  );
}
