import React from 'react';
export default function EdiTimeBox(props) {
    return (
        <div className={`w-1/2 md:w-1/2 md:px-6 py-1`}>
            <p className="text-sm text-sec font-semibold">
                {props.title}
            </p>
            <input
                placeholder="Ex: 12:30"
                className="w-full mt-2 h-12 outline-none py-2 px-4 rounded-lg border border-prim"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                type={props.type}
                disabled={props.disabled}
            />
        </div>
    )
}