import {
  GET_SPECIAL_OFFER_FAILURE,
  GET_SPECIAL_OFFER_SUCCESS,
  GET_ALL_SPECIAL_OFFER_SUCCESS,
  DELETE_SPECIAL_OFFER_SUCCESS,
} from "../types/specialOffer/types";

import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const getAllSpecialOfferOfRestaurant = async (
  dispatch,
  restaurantId,
) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/specialOffer/get-all-special-offer/${restaurantId}`,
    );
    if (data) {
      dispatch({ type: GET_ALL_SPECIAL_OFFER_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err);
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_SPECIAL_OFFER_FAILURE, payload: error });
  }
};

export const addSpecialOffer = async (dispatch, { token, formData }) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${apiBaseUrl}${apiVersionPrefix}/specialOffer/create-special-offer`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_SPECIAL_OFFER_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err)
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_SPECIAL_OFFER_FAILURE, payload: error });
  }
};

export const deleteSpecialOffer = async (dispatch, token, offerId) => {
  try {
    const {
      data: { message },
    } = await axios.delete(
      `${apiBaseUrl}${apiVersionPrefix}/specialOffer/delete-special-offer/${offerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (message) {
      dispatch({ type: DELETE_SPECIAL_OFFER_SUCCESS, payload: message });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_SPECIAL_OFFER_FAILURE, payload: error });
  }
};

export const getSingleSpecialOffer = async (dispatch, offerId) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/specialOffer/get-single-special-offer/${offerId}`,
    );
    if (data) {
      dispatch({ type: GET_SPECIAL_OFFER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_SPECIAL_OFFER_FAILURE, payload: error });
  }
};

export const updateSpecialOffer = async (
  dispatch,
  { token, formData },
  offerId,
) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/specialOffer/update-special-offer/${offerId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (data) {
      dispatch({ type: GET_SPECIAL_OFFER_SUCCESS, payload: data });
    }
  } catch (err) {
    //console.log(err)
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_SPECIAL_OFFER_FAILURE, payload: error });
  }
};
