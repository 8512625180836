import React, { useState } from "react";
import { FiCheck } from "react-icons/fi";

const PackageFoods = ({ food, packageForm, setPackageForm }) => {
  const [checked, setChecked] = useState(true);

  const handleClick = () => {
    setChecked(!checked);
    if (checked) {
      packageForm.foods.push(food);
      setPackageForm({ ...packageForm });
    }
    if (!checked) {
      let foods = packageForm.foods.filter((el) => el._id === food._id);
      packageForm = { ...packageForm, foods };
      setPackageForm({ ...packageForm });
    }
  };

  return (
    <div className="flex justify-between p-3 py-1 text-prim font-semibold text-sm ">
      <div>
        <p>{food.name}</p>
      </div>
      <div className="flex items-center">
        <p className="mr-3">£{food.price}</p>
        <div className="w-5 h-5">
          <div
            onClick={handleClick}
            className={`w-5 h-5 border-2 flex items-center justify-center border-black cursor-pointer rounded-md border-red-700 }`}
          >
            {!checked && <FiCheck />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageFoods;
