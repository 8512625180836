import React from 'react'
import CountControl from '../utilities/CountControl'

export default function CartItem({ item, cart, setCart, index }) {
    return (
        <div className="mt-2 rounded-lg bg-white p-3 px-6 border-b flex items-center">
           
            <div className="flex-grow px-1">
                <p className="text-md text-gray-800 upper font-semibold">{item.name}</p>
                {item.options && item.options?.length > 0 ? (
                    item.options?.map((el, index) => (
                        <div className=' inline-block text-xs font-semibold mr-1 mt-1 px-2 py-1 text-red-700 rounded-full' key={index}>{el}</div>
                    ))
                ) : null}
                  <p className="text-sm text-gray-700 font-medium">£{Number.parseFloat(item.price).toFixed(2)}</p>
            </div>

            <CountControl setCart={setCart} cart={cart} item={item} index={index} />
        </div>
    )
}
