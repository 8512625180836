import React, { useState } from 'react'

import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { addOptions, getAllOptions } from "../../../../../../../redux/actions/option";
import { getCookie } from "../../../../../../../components/utilities/cookie/cookie";

import Input from '../../../../../../../components/utilities/form/Field'

const FoodOptionBottom = (props) => {
    const { formData, setFormData, setOption, option, setType, setShowType, setCancel } = props;

    const dispatch = useDispatch();
    // const [securityKey, setSecurityKey] = useState(null);
    const token = getCookie("@partner_token");

    const { food } = useSelector(state => state.foods);

    const foodId = food?._id;

    const handleCancel = () => {
        setFormData({ ...formData, options: [] })
        setOption({ ...option, choiceCount: 0, name: "", types: [] })
        setType({ typeName: "", price: 0, isAdded: false })
        setCancel(false)
        setShowType(false)
    }
    const {option: { error }} = useSelector(state => state.option)
    const handleAddOption = async (e) => {
        e.preventDefault();
        await addOptions(dispatch, foodId, token, option, setCancel);
        await getAllOptions(dispatch, foodId)
        setOption({ ...option, choiceCount: 0, name: "", types: [] })
        setShowType(false)
        toast.error(error, { position: toast.POSITION.TOP_RIGHT })
    }
    
    
    return (
        <div className="flex justify-end mt-3">
            {/* <ToastContainer style={{ fontSize: "1rem" }} /> */}
            {/* <Input
                className="w-full outline-none p-1 text-black font-bold border border-red-700 rounded"
                placeholder="Please Enter Security Key"
                name="securityKey"
                onChange={(e) => setSecurityKey(e.target.value)}
              /> */}
            <button className=" mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleAddOption}>Save</button>
            <button className=" mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleCancel}>Cancel</button>
        </div>
    )
}

export default FoodOptionBottom
