import React, { useState } from "react";

import Option from "./AddOptionsElements/Option";
import OptionHeader from "./AddOptionsElements/OptionHeader";
import OptionInput from "./AddOptionsElements/OptionInput";
import OptionType from "./AddOptionsElements/OptionType";
import TypeInput from "./AddOptionsElements/TypeInput";
import FoodOptionBottom from "./AddOptionsElements/FoodOptionBottom";

export default function AddOption({ formData, setFormData }) {
    const { restaurant } = JSON.parse(localStorage.getItem("restaurant"));

    const [option, setOption] = useState({
        "choiceCount": 0,
        "name": "",
        "types": [],
        "restaurant": restaurant

    });

    const [type, setType] = useState({
        typeName: "",
        price: 0,
        isAdded: false
    })

    const [cancel, setCancel] = useState(false);

    const [showType, setShowType] = useState(false);

    const [showTypes, setShowTypes] = useState(true)

    return (
        <>
            <OptionHeader cancel={cancel} setCancel={setCancel} />

            <Option formData={formData} setFormData={setFormData} option={option} setOption={setOption} setShowTypes={setShowTypes} />

            <div className="w-full">
                {
                    cancel && (
                        <OptionInput option={option} setShowType={setShowType} setOption={setOption} setType={setType} />
                    )
                }
                {
                    showTypes && option.types && option.types.map((item, index) => (

                        <OptionType option={option} item={item} setOption={setOption} key={index} />

                    ))
                }

                {
                    showType && (
                        <TypeInput setType={setType} type={type} option={option} setOption={setOption} setShowType={setShowType} setShowTypes={setShowTypes} />
                    )
                }

                {cancel && <FoodOptionBottom formData={formData} setFormData={setFormData} setOption={setOption} option={option} setType={setType} setShowType={setShowType} setCancel={setCancel} />}
            </div>
        </>
    );
}
