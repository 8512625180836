import React from 'react';

export default function AdminInvoicesContent(props) {
    // console.log(props.amount)
    return (
        <div className="flex items-center w-full">
            <div className="p-5 w-4/12 md:w-2/12">
                <p className="text-sm font-semibold truncate">{props.date}</p>
            </div>
            <div className="p-4 sm:p-5 md:p-5 w-3/12 md:w-8/12">
                <p className="text-sm font-semibold">{props.description}</p>
            </div>
            <div className="p-4 sm:p-5 md:p-5 w-3/12 md:w-8/12">
                <p className="text-sm font-semibold">{props.orderType}</p>
            </div>
            <div className="p-2 py-4 sm:p-5 md:p-5 w-2/12 flex items-center justify-between">
                <p className="text-sm font-semibold">{isNaN(props.amount) ? 0 : props.amount}</p>
            </div>
        </div>
    )
}