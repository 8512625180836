const styles = {
  plusButtonArea: {
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  foodOptionModalArea: {
    width: 600,
    height: 500,
    overflowY: "scroll",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  border: {
    border: "2px solid red",
  },
};

export default styles;
