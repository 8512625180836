/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiShoppingCart } from "react-icons/fi";
import Cart from "../../../components/cart";
import Categories from "./categories";
import Products from "./products";
import MobileCart from "../../../components/cart/MobileCart";
import CartPopup from "../../../components/cart/CartPopup";

import Icon from "../../../components/utilities/Icon";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { getRestaurantCategories } from "../../../redux/actions/categories";
import { getRestaurantOwnInfo } from "../../../redux/actions/restaurantInfo";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import EposSidebar from "../Epos/eposSidebar";
import OrderType from "./OrderType";
import DineIn from "../../partner/Checkout/checkout-component/DineIn";
import Collection from "../../partner/Checkout/checkout-component/Collection";
import HomeDelivery from "../../partner/Checkout/checkout-component/HomeDelivery";

export default function Order({ setEpos }) {
  const [openModal, setOpenModal] = useState(false);
  const [localOrderType, setLocalOrderType] = useState(undefined);
  const dispatch = useDispatch();
  const restaurantId = JSON.parse(
    localStorage.getItem("restaurant")
  ).restaurant;

  const { categories, category } = useSelector((state) => state.categories);
  const { restaurantInfo } = useSelector((state) => state.restaurantInfo);
  
  const token = getCookie("@partner_token");
  const order = {
    restaurant: restaurantId,
    restaurantUniId: restaurantInfo.uniId,
    orderType: "Dine In",
    discount: null,
    coupon: null,
    customerName: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).name
      : "",
    mobileNo: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).mobileNo
      : "",
    subTotal: 0,
    shippingFee: 0,
    totalPrice: 0,
    paymentMethod: "",
    pickupTime: "",
    kitchenNotes: "",
    deliveredAt: "",
    riderService: restaurantInfo?.restaurantDelivery?.isRiderService,
    driver: null,
    cartItems: "",
    serviceCharge: 0,
    partnerFACharge: 0,
    address: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).address?.address
      : "",
    postCode: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).address?.postCode
      : "",
    deliveryCharge: 0,
    fromRestaurant: true,
  };
  // console.log(restaurantInfo)
  const [isAdded, setIsAdded] = useState({
    name: "",
    add: false,
  });

  let [cart, setCart] = useState({
    customer: "",
    restaurant: restaurantId,
    foodItems: [],
    count: 0,
    subTotal: 0,
    note: "",
  });
  useEffect(() => {
    localStorage.setItem("order", JSON.stringify(order));
    localStorage.setItem("currentPage", "orderPage");
    const myCart = JSON.parse(localStorage.getItem("cart"));
    if (myCart && myCart.restaurant === restaurantId) {
      setCart({
        ...cart,
        foodItems: myCart.foodItems,
        subTotal: myCart.subTotal,
      });
    } else {
      setCart({
        customer: "",
        restaurant: restaurantId,
        foodItems: [],
        count: 0,
        subTotal: 0,
      });
    }
  }, [restaurantId]);

  useEffect(
    () => {
      const abortController = new AbortController();
      getRestaurantCategories(
        dispatch,
        localStorage.getItem("restaurantUniId")
      );
      return () => abortController.abort();
    },
    [dispatch],
    [localStorage.getItem("restaurantUniId")]
  );

  useEffect(() => {
    getRestaurantOwnInfo(dispatch, token);
    localStorage.setItem("order", JSON.stringify(order));
  }, [dispatch, token]);

  const closeOrOpen =
    restaurantInfo?.restaurantTiming?.restaurantTiming.available.find(
      (tm) => tm.day === new Date().getDay()
    );
  const start = new Date(Date.now()).setHours(
    closeOrOpen?.startTime.split(":")[0],
    closeOrOpen?.startTime.split(":")[1]
  );
  const end = new Date(Date.now()).setHours(
    closeOrOpen?.endTime.split(":")[0],
    closeOrOpen?.endTime.split(":")[1]
  );
  const currentTime = new Date(Date.now());
  let available = false;
  closeOrOpen?.isOpen
    ? start < currentTime && end > currentTime
      ? (available = true)
      : (available = false)
    : (available = false);
  const handleCart = () => {
    if (cart.foodItems.length > 0) {
      setOpenModal(true);
    } else {
      alert("You have no items in your cart!");
    }
  };
  // console.log("hrids;")

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps Partner | In-House</title>
      </Helmet>
      <div className="flex w-full">
        <EposSidebar />
        <div className="flex justify-end my-5 md:my-0">
          {cart && (
            <div
              onClick={handleCart}
              className="sm:hidden block px-1 sm:px-3 md:px-3 cursor-pointer"
            >
              <div className="relative">
                {cart.foodItems.length > 0 && (
                  <>
                    <div className="flex bg-prim w-3 h-3 items-center justify-center absolute p-2 top-0 right-0 rounded-full -mt-2 -mr-2 text-white text-xs">
                      {cart.foodItems.length}
                    </div>
                    <Icon icon={FiShoppingCart} />
                  </>
                )}
              </div>
            </div>
          )}
          {/* <Icon icon={FiX} className="cursor-pointer" onClick={() => setEpos(false)} /> */}
        </div>
        <div className="pl-20 mt-6 w-full">
          <OrderType
            localOrderType={localOrderType}
            setLocalOrderType={setLocalOrderType}
            options={["Home Delivery", "Collection", "Dine In"]}
          />

          <div class="grid grid-cols-12 grid-flow-col relative">
            {category && categories ? (
              <>
                <div className="flex flex-col col-span-9">
                  <Categories />
                  <Products setCart={setCart} cart={cart} />

                  <div className="my-6" id="checkout">
                    {localOrderType === "Dine In" ? (
                      <DineIn />
                    ) : localOrderType === "Collection" ? (
                      <Collection />
                    ) : localOrderType === "Home Delivery" ? (
                      <HomeDelivery />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="hidden lg:block col-span-2 fixed inset-y-0 right-0 px-5">
                  <Cart
                    cart={cart}
                    setCart={setCart}
                    isAdded={isAdded}
                    setIsAdded={setIsAdded}
                    isAvailable={available}
                    localOrderType={localOrderType}
                    
                  />
                </div>
              </>
            ) : (
              <span>Loading</span>
            )}
          </div>
          <MobileCart setOpenModal={setOpenModal} />
        </div>
        <CartPopup
          openModal={openModal}
          setOpenModal={setOpenModal}
          cart={cart}
          setCart={setCart}
          isAvailable={available}
        />
      </div>
    </HelmetProvider>
  );
}
