import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs';

import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getCookie } from "../../../../../../../components/utilities/cookie/cookie";
import { deleteOption, getAllOptions, getSingleOption } from "../../../../../../../redux/actions/option";

import EditOption from './EditOption';
import Input from '../../../../../../../components/utilities/form/Field'

const Option = ({ formData, setFormData, option }) => {

    const { food } = useSelector(state => state.foods);

    const foodId = food?._id;

    const { options } = useSelector(state => state.option);
    const dispatch = useDispatch();
    // const [deletable, setDeletable] = useState(false);
    // const [securityKey, setSecurityKey] = useState(null);
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        getAllOptions(dispatch, foodId)
    }, [dispatch, foodId])

    const token = getCookie("@partner_token");

    const  error  = useSelector(state => state.option?.message?.error)
    const handleDelete = async (optionId) => {
        await deleteOption(dispatch, foodId, token, optionId);
        await getAllOptions(dispatch, foodId);
    }

    const handleEdit = async (optionId) => {
        await getSingleOption(dispatch, foodId, token, optionId);    
        setEdit(true)
    }
    
    useEffect(() => {
        if(error) {
            toast.error(error, { position: toast.POSITION.TOP_RIGHT })
        }
        
    },[edit, error])

    return (
        <div className='w-full '>
            <ToastContainer style={{ fontSize: "1rem" }} />
            {
                !edit && options?.map((el, index) => (

                    <div className="w-full" key={index}>
                        <div className="py-3 w-full flex items-center bg-blue-200 mt-5">
                            {/* {(deletable === el._id) ?
                                <Input
                                    classes="w-full outline-none p-1 text-black font-bold border border-red-700 rounded mx-3"
                                    placeholder="Please Enter Security Key"
                                    name="securityKey"
                                    onChange={(e) => setSecurityKey(e.target.value)}
                            />    
                            : */}
                            <>
                                <div className='w-1/3 px-5 font-semibold '>Name : {el.name}</div>
                                    <div className='w-1/3 text-center font-semibold'> Choose Any : {el.choiceCount}</div>
                                    <div className='w-1/3 flex justify-end mr-3'>
                                        <button className='text-white p-3 mr-3 bg-blue-700 rounded-lg' onClick={() => handleEdit(el._id)}><FiEdit /></button>
                                        {/* <button onClick={() => handleDelete(el._id)} className='text-white p-3 mr-3 bg-red-700 rounded-lg'><BsTrash  /></button> */}
                                    </div>
                            </>
                            
                            {/* } */}
                            {/* {(deletable === el._id) &&
                                <button
                                    onClick={() => handleDelete(el._id)}
                                    className='text-white p-3 mr-3 bg-red-700 rounded-lg' >
                                    <BsTrash  />
                                </button>
                            } */}
                        </div>
                        
                        <div className="w-full mt-2 ">
                            <h1 className='text-sm font-semibold ml-2'>Types</h1>
                            <div className='flex flex-wrap w-full '>
                                {
                                    el.types?.map((item, index) => (
                                        <div className="py-3 w-full  rounded-lg flex items-center bg-red-200 mt-2 " key={index}>
                                            <p className='w-1/2 ml-5 font-semibold'>Name : {item.typeName}</p>
                                            <p className='w-1/2 text-right mr-5 font-semibold'>Price: £ {item.price}</p>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                ))
            }
            {
                edit && <EditOption edit={edit}  setEdit={setEdit} />
            }
        </div>
    )
}

export default Option
