export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_SALES_SUCCESS = "GET_SALES_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";
export const GET_SALES_FAILURE = "GET_SALES_FAILURE";
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILURE = "GET_INVOICE_FAILURE";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";
