import React from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';

export default function CountControl(props) {
    const { item, cart, setCart, index } = props;

    const handleAdd = () => {
        item.quantity = item.quantity + 1;

        if (item.options.length > 0) {
            item.price = item.quantity * (item.basePrice + item.optionsPrice);
            cart.subTotal += (item.basePrice + item.optionsPrice);
            cart.foodItems[index].price = item.price;
            cart.foodItems[index].quantity = item.quantity;
        } else if (item.options.length === 0) {
            item.price = item.quantity * item.basePrice;
            cart.subTotal += item.basePrice;
            const itemIndex = cart.foodItems.findIndex((el) => el.name === item.name);
            cart.foodItems[itemIndex].price = item.price;
            cart.foodItems[itemIndex].quantity = item.quantity;
        }
        setCart({ ...cart, foodItems: cart.foodItems, count: cart.foodItems.length })
        localStorage.setItem("cart", JSON.stringify(cart));
    }

    const handleMinus = () => {
        item.quantity = item.quantity - 1;

        if (item.options.length > 0) {
            item.price = item.quantity * (item.basePrice + item.optionsPrice);
            cart.subTotal = cart.subTotal - (item.basePrice + item.optionsPrice);
            cart.foodItems[index].price = item.price;
            cart.foodItems[index].quantity = item.quantity;

        }
        else if (item.options.length === 0) {
            item.price = item.quantity * item.basePrice;
            cart.subTotal = cart.subTotal - item.basePrice;
            const itemIndex = cart.foodItems.findIndex((el) => el.name === item.name);
            cart.foodItems[itemIndex].price = item.price;
            cart.foodItems[itemIndex].quantity = item.quantity;
        }

        let cartArr = [];
        if (item.quantity < 1) {
            for (let i = 0; i < cart.foodItems.length; i++) {
                if (cart.foodItems[i].uniqueId !== item.uniqueId) {
                    cartArr.push(cart.foodItems[i])
                }
            }
            cart.foodItems = cartArr;
        }
        localStorage.setItem("cart", JSON.stringify(cart));
        setCart({ ...cart, foodItems: cart.foodItems, count: cart.foodItems.length })
    }

    return (
        <div className="flex flex-col bg-nat justify-center items-center rounded-full overflow-hidden w-10">
            <FiPlus onClick={handleAdd}
                className="p-1 w-full hover:bg-red-700 hover:text-white bg-nat h-6 cursor-pointer" />

            <button onClick={props.onClick} className="w-full bg-nat text-gray-700 h-6 text-center text-md font-semibold p-1" value={cart.count}>{item.quantity}</button>

            <FiMinus onClick={handleMinus} className="p-1 w-full hover:bg-red-700 hover:text-white bg-nat h-6 cursor-pointer" />
        </div>
    )
}