import React, { useState,useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { FaSave } from "react-icons/fa";
import { GiOpenedFoodCan } from "react-icons/gi";
import { useDispatch } from "react-redux";

import { getCookie } from "../utilities/cookie/cookie";
import { addCartItem } from "./../../redux/actions/order";

import CartAllergyNote from "./CartAllergyNote";
import CartCheckout from "./CartCheckout";
import CartHeader from "./CartHeader";
import CartItem from "./CartItem";

export default function Cart({ cart, setCart, isAvailable }) {
  const [message, setMessage] = useState('');
  const token = getCookie("@partner_token");
  const dispatch = useDispatch();
  const { id } = useParams();

  const customer = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : "";

  const history = useHistory();

  const handleClick = () => {
    const order = JSON.parse(localStorage.getItem("order"));

    if (isAvailable) {
      setCart({ ...cart, customer: customer._id });
      if (order.orderType === "Collection") {
        history.push("/checkout-collection");
      } else if (order.orderType === "Home Delivery") {
        history.push("/checkout-home-delivery");
      } else {
        history.push("/checkout-dine-in");
      }
    } else {
      alert("Sorry, we are closed now!Try again later.");
    }

    localStorage.setItem("cart", JSON.stringify(cart));
  };

  const addItem = async (e) => {
    setCart({ ...cart, customer: customer._id });
    localStorage.setItem("cart", JSON.stringify(cart));
    e.preventDefault();
    await addCartItem(dispatch, token, id, cart);
    history.push("/admin-in-house-orders");
  };
  const handleServe = () => {
    history.push(`/checkout-dine-in/${id}`)
  }
  const handleChange = (event) => {
    let change = event.target.value - cart.subTotal
    if(change < 0){
      change = 0;
    }
    setMessage(change);
  };
  return (
    <div className="h-full w-full overflow-y-auto">
      <div className="bg-white border-l border-gray-300 h-full pb-6 overflow-y-auto">
        <CartHeader count={cart.foodItems?.length} />
        <div className="w-full scroll border-b overflow-y-auto h-96">
          {cart.foodItems && cart.foodItems?.length > 0 ? (
            cart.foodItems?.map((el, index) => (
              <CartItem
                key={index}
                cart={cart}
                item={el}
                setCart={setCart}
                index={index}
              />
            ))
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-600 font-semibold text-lg px-5 py-3 border-2 bg-gray-100 border-gray-900 rounded-lg">
                No Food is Added to cart
              </p>
            </div>
          )}
        </div>

        <div className="text-md text-gray-800 w-full pt-2 px-4 text-center">
          <CartAllergyNote setCart={setCart} cart={cart} />
          {id && (
            <div className="flex justify-center cursor-pointer">
              <button
                onClick={addItem}
                className="bg-prim text-white px-5 py-2 rounded mx-2"
              >
                <FaSave className="inline" /> Save
              </button>
              <button
                onClick={handleServe}
                className="bg-prim text-white px-5 py-2 rounded"
              >
                <GiOpenedFoodCan className="inline" /> Serve
              </button>
            </div>
          )}
          {!id &&
            (cart.foodItems?.length === 0 ? (
              <CartCheckout buttonText="Add Food to To Cart" totalPrice={0} />
            ) : (
              <CartCheckout
                onClick={handleClick}
                buttonText="Checkout"
                totalPrice={cart.subTotal}
              />
            ))}
                      <div className="py-3 text-left">
      <p className="p-1 text-black font-bold text-sm">Collected Cash</p>
      <input
                  type="text"
                  id="collectedCash"
                  placeholder="Enter Collected Cash"
                  className="w-full border border-red-500 my-2 rounded-md p-2"
                  onChange={handleChange}
                />
      </div>
      <div className="text-left">
      <p className="text-black font-bold text-sm">Change £ {message}</p>
      </div>
        </div>
      </div>
    </div>
  );
}
