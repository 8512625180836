import React from 'react'

const FoodOptions = ({el}) => {
  return (
    <div className="mr-10">
      <span className="text-xs font-bold pr-1 text-black">{el.name}:</span>
      <span className="text-gray-600 text-xs">{el.price}</span>
    </div>
  )
}

export default FoodOptions
