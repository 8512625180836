import React, { useEffect, useState } from "react";

import { FiPlus } from "react-icons/fi";
import { useDispatch } from "react-redux";

import { getAllAllergies } from "../../../../../../../redux/actions/allergies";

import FoodAllergyLabel from "./FoodAllergyLabel";
import FoodAllergyModal from "./FoodAllergyModal";

const AddFoodAllergy = ({ formData, setFormData }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState({
    name: "",
    index: "",
  });

  useEffect(() => {
    getAllAllergies(dispatch);
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const openModal = () => {
    state.name = "";
    state.index = "";

    setState(state);
    setIsModalOpen(true);
  };

  const removeOption = (key) => {
    const foodAllergy = formData.foodAllergy.filter(
      (item, index) => key !== index && item,
    );
    setFormData({ ...formData, foodAllergy });
  };

  return (
    <>
      <div className="w-full px-2 border-gray-400 border-b pb-6 mt-3">
        <p className="mb-2 font-bold text-xs">Allergies</p>
        <div className="py-1 w-full flex justify-between">
          <div className="flex items-center w-full">
            <div className="w-full border flex items-center border-red-700 h-10 w-10 rounded-lg outline-none">
              <div className="relative w-full">
                <div className="flex items-center">
                  {formData.foodAllergy.map((item, index) => (
                    <FoodAllergyLabel
                      key={index}
                      item={item}
                      index={index}
                      removeOption={removeOption}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
            className="px-2 py-2 text-white bg-red-700 ml-2 rounded-lg"
            onClick={openModal}
          >
            <FiPlus />
          </button>
        </div>
      </div>
      <FoodAllergyModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        state={state}
        handleChange={handleChange}
        formData={formData}
        setFormData={setFormData}
      />
    </>
  );
};

export default AddFoodAllergy;
