/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// import PaymentOptionCard from "../../../../components/checkout/PaymentOptionCard";
// import AgreementCheck from "../../../components/utilities/form/AgreementCheck";
import YourOrder from "../YourOrder";
import CheckoutTableNumber from "../CheckoutTableNumber";
// import CheckoutHeader from "../../../components/checkout/CheckoutHeader";
// import CheckoutContact from "./CheckoutContact";
// import ErrorMessage from "../../../../components/utilities/form/ErrorMessage";

import { getCookie } from "../../../../components/utilities/cookie/cookie";
import { placeDineOrder, serveDineOrder } from "../../../../redux/actions/order";

const DineIn = () => {
  const history = useHistory();
  
  const restaurantId = JSON.parse(localStorage.getItem("order"))
    ? JSON.parse(localStorage.getItem("order")).restaurant
    : "";

  const restaurantUniId = JSON.parse(localStorage.getItem("order"))
    ? JSON.parse(localStorage.getItem("order")).restaurantUniId
    : "";
  const localOrder = JSON.parse(localStorage.getItem("order"));
  const cart = JSON.parse(localStorage.getItem("cart"));
  const [check] = useState(true);

  
  const option = "Counter";
 
  const dispatch = useDispatch();
  let [order, setOrder] = useState({
    restaurant: restaurantId,
    restaurantUniId: restaurantUniId,
    cartItems: JSON.stringify(cart),
    discount: null,
    coupon: null,
    orderType: "Dine In",
    tableNo: localStorage.getItem("tableNo") || "",
    customerName: "",
    mobileNo: "",
    subTotal: cart.subTotal ? cart.subTotal : "",
    totalPrice: 0,
    paymentMethod: option,
    paymentStatus: "Pending",
    kitchenNotes: "",
    deliveredAt: "",
    deliveryCharge: 0,
    fromRestaurant: true,
  });
  
  const localCard = {
    total: localOrder?.totalPrice,
    tableNo: localOrder?.tableNo,
    cardNumber: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  };
  

  const token = getCookie("@partner_token");
  

  useEffect(() => {
   // setOrder({ ...order });
  }, [option]);
  localStorage.setItem("order", JSON.stringify(order));
  
  const placeDineInOrder = async (e) => {
    order.cartItems = JSON.stringify(cart);
    order.subTotal= cart.subTotal ? cart.subTotal : "";
    order.option = option;
    order.tableNo = localStorage.getItem("tableNo") || "";
    localStorage.removeItem("order");
    localStorage.setItem("order", JSON.stringify(order));
    
    e.preventDefault();
    if (check) {
      if (localStorage.getItem("tableNo")) {
        await placeDineOrder(dispatch, token, option, localCard, history,"save");
      } else {
        alert("Please input table number");
      }
    } else {
      alert("Please accept the Terms and Conditions");
    }
  };


  const serveDineInOrder = async (e) => {
    order.cartItems = JSON.stringify(cart);
    order.subTotal= cart.subTotal ? cart.subTotal : "";
    order.option = option;
    order.tableNo = localStorage.getItem("tableNo") || "";
    localStorage.removeItem("order");
    localStorage.setItem("order", JSON.stringify(order));
    e.preventDefault();
    if (check) {
      if (localStorage.getItem("tableNo")) {
        await placeDineOrder(dispatch, token, option, localCard, history,"serve");
      } else {
        alert("Please input table number");
      }
    } else {
      alert("Please accept the Terms and Conditions");
    }
  };

  return (
    <div className="grid grid-cols-4 gap-3">
      <div className="hidden">
        {cart && cart.length > 0 ? (
          <YourOrder order={order} setOrder={setOrder} />
        ) : (
          ""
        )}
      </div>
      <div className=" hover:bg-prim hover:text-white">
        <CheckoutTableNumber />
      </div>
      <button
        onClick={placeDineInOrder}
        className="border-2 border-prim min-h-110 hover:bg-prim hover:text-white md:text-xl rounded-md p-4"
      >
        SAVE
      </button>
      <button
      onClick={serveDineInOrder}
      className=" border-2 border-prim min-h-110 hover:bg-prim md:text-xl hover:text-white rounded-md p-4">
        SERVE
      </button>
    </div>
  );
};

export default DineIn;
