import React from "react";
import Modal from "react-modal";

import styles from "./styles";
import FoodAllergyModalHead from "./FoodAllergyModalHead";
import FoodAllergyModalBody from "./FoodAllergyModalBody";

const FoodAllergyModal = ({
  isModalOpen,
  setIsModalOpen,
  handleChange,
  handleSubmit,
  state,
  formData,
  setFormData,
}) => {
  const { foodOptionModalArea } = styles;
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div style={foodOptionModalArea}>
        <FoodAllergyModalHead closeModal={() => setIsModalOpen(false)} />
        <FoodAllergyModalBody
          state={state}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          formData={formData}
          setFormData={setFormData}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .8)",
  },
  modal: {
    outline: "none",
  },
};

export default FoodAllergyModal;
