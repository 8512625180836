import React from "react";
import { TiDeleteOutline } from "react-icons/ti";

const FoodAllergyLabel = ({ item, index, removeOption }) => {
  // console.log(item);
  return (
    <div className="cursor-pointer  font-bold  flex items-center text-white m-2 ">
      <div className="flex text-white rounded-full items-center border-2 border-red-700 px-2">
        {/* <img src={item.icon} alt={item.name} className="w-6 h-6" /> */}
        <p className="text-prim">{item.name}</p>
        <button
          onClick={() => removeOption(index)}
          className="ml-1 rounded-full text-black"
        >
          <TiDeleteOutline
            fontSize="25px"
            color="rgba(185, 28, 28, var(--tw-border-opacity))"
          />
        </button>
      </div>
    </div>
  );
};

export default FoodAllergyLabel;
