import AdminBookings from "../pages/partner/AdminBookings";
import AdminInbox from "../pages/partner/AdminInbox";
import AdminInHouse from "../pages/partner/AdminInHouse";
import AdminInvoices from "../pages/partner/AdminInvoices";
import AdminNotifications from "../pages/partner/AdminNotifications";
import AdminOrder from "../pages/partner/AdminOrder";
import AdminRestaurants from "../pages/partner/AdminRestaurants";
import CreateRestaurant from "../pages/partner/AdminRestaurants/CreateRestaurant";
import AdminSalesReport from "../pages/partner/AdminSalesReport";
import AdminTermsOfUse from "../pages/partner/AdminTermsOfUse";
import AdminPaymentInfo from "../pages/partner/AdminPaymentInfo";
import CheckoutDineIn from "../pages/partner/Checkout/CheckoutDineIn";
import CheckoutCollection from "../pages/partner/Checkout/CheckoutCollection";
import CheckoutHomeDelivery from "../pages/partner/Checkout/CheckoutHomeDelivery";
import OrderSummary from "../pages/partner/AdminOrderSummury/OrderSummary";
import EposAddItem from "./../pages/partner/EposAddItem/index";
import CheckoutDineInServe from "../pages/partner/Checkout/CheckoutDineInServe";
import Epos from "../pages/partner/Epos/index";

const partnerRoutes = [
  {
    path: "/admin",
    exact: true,
    component: AdminRestaurants,
  },
  {
    path: "/epos",
    exact: true,
    component: Epos,
  },
  {
    path: "/admin-restaurants",
    exact: true,
    component: AdminRestaurants,
  },
  {
    path: "/admin-orders",
    exact: true,
    component: AdminOrder,
  },
  {
    path: "/admin-in-house-orders",
    exact: true,
    component: AdminInHouse,
  },
  {
    path: "/admin-in-house-orders/:id",
    exact: true,
    component: EposAddItem,
  },
  {
    path: "/admin-bookings",
    exact: true,
    component: AdminBookings,
  },
  {
    path: "/admin-sales-report",
    exact: true,
    component: AdminSalesReport,
  },
  {
    path: "/admin-payment-info",
    exact: true,
    component: AdminPaymentInfo,
  },
  {
    path: "/admin-all-invoices",
    exact: true,
    component: AdminInvoices,
  },
  {
    path: "/admin-inbox",
    exact: true,
    component: AdminInbox,
  },
  {
    path: "/admin-notifications",
    exact: true,
    component: AdminNotifications,
  },
  {
    path: "/admin-terms-of-use",
    exact: true,
    component: AdminTermsOfUse,
  },
  {
    path: "/create-restaurant",
    exact: true,
    component: CreateRestaurant,
  },
  {
    path: "/checkout-dine-in",
    exact: true,
    component: CheckoutDineIn,
  },
  {
    path: "/checkout-dine-in/:orderId",
    exact: true,
    component: CheckoutDineInServe,
  },
  {
    path: "/checkout-collection",
    exact: true,
    component: CheckoutCollection,
  },
  {
    path: "/checkout-home-delivery",
    exact: true,
    component: CheckoutHomeDelivery,
  },
  {
    path: "/order-summary/:id",
    exact: true,
    component: OrderSummary,
  },
];

export default partnerRoutes;
