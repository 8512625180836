import { GET_CARD_FAILURE, GET_CARD_SUCCESS, UPDATE_CARD_FAILURE, UPDATE_CARD_SUCCESS } from '../types/card/type'

const initialState = {
    card: {},
    error: null,
    loading: true,
    message: ""
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                card: action.payload
            }
        case GET_CARD_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed",
                error: action.payload
            };
            case UPDATE_CARD_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    status: "success",
                    card: action.payload
                }
            case UPDATE_CARD_FAILURE:
                return {
                    ...state,
                    loading: false,
                    status: "Failed",
                    error: action.payload
                };
        default:
            return state;
    }
}
export default reducer;