import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFoodsByCategory } from "../../../../../redux/actions/foods";
import MenuItems from "./MenuItems";

export default function ShowMenu({ setIsMenuEdit }) {
  const { category } = useSelector((state) => state.categories);

  const { foods } = useSelector((state) => state.foods);

  const dispatch = useDispatch();

  const categoryId = category._id;

  useEffect(() => {
    getFoodsByCategory(dispatch, categoryId);
  }, [dispatch, categoryId]);

  const FoodMenuDOM = foods.map((el, i) => (
    <MenuItems setIsMenuEdit={setIsMenuEdit} food={el} key={i} />
  ));

  return <>{FoodMenuDOM}</>;
}
