import React from 'react'

export default function Bookingvalue(props) {
    return (
        <p style={props.containerStyle} className={`${props.containerClasses}`}>
            <span style={props.titleStyle} className={`text-sm font-bold ${props.titleClasses}`}>{props.title}: </span>
            <span style={props.valueStyle} className={`font-normal text-gray-700 ${props.valueClasses}`}>{props.value}</span>
        </p>
    )
}
