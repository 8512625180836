import {GET_CUSTOMER_SUCCESS,GET_CUSTOMER_FAILURE} from "../types/customer/types";

const initialState = {
    customer: {},
    customers: [],
    status: null,
    error: "",
    loading:true
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        status:"success",
        customer: action.payload
      };

    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        status:"Failed",
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;

