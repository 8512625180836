/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import PaymentOptionCard from "../../../components/checkout/PaymentOptionCard";
import PaymentOptionCash from "../../../components/checkout/PaymentOptionCash";
import AgreementCheck from "../../../components/utilities/form/AgreementCheck";
import YourOrder from "./YourOrder";
// import CheckoutTableNumber from "./CheckoutTableNumber";
import CheckoutHeader from "../../../components/checkout/CheckoutHeader";
import CheckoutContact from "./CheckoutContact";
import ErrorMessage from "../../../components/utilities/form/ErrorMessage";

import { getCookie } from "../../../components/utilities/cookie/cookie";
import { updateOrder } from "../../../redux/actions/order";

const CheckoutDineInServe = () => {
  const history = useHistory();
  const { orderId } = useParams();
  // const restaurantId = JSON.parse(localStorage.getItem('order')).restaurant;

  // const restaurantUniId = JSON.parse(localStorage.getItem('order')).restaurantUniId;
  const localOrder = JSON.parse(localStorage.getItem("order"));
  // const cart = JSON.parse(localStorage.getItem('cart'));
  const [check, setCheck] = useState(false);

  const [option, setOption] = useState("Counter");

  const dispatch = useDispatch();
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem("order")));
  const [localCard, setLocalCard] = useState({
    total: Number.parseFloat(localOrder?.totalPrice).toFixed(2),
    cardNumber: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  const { error: orderError } = useSelector((state) => state.order);
  const { error: cardError } = useSelector((state) => state.card);

  const token = getCookie("@partner_token");

  useEffect(() => {
    setOrder({ ...order, paymentMethod: option });
  }, [option]);
  localStorage.setItem("order", JSON.stringify(order));

  const placeDineInOrder = async (e) => {
    e.preventDefault();
    if (check) {
      await updateOrder(dispatch, token, orderId, option, localCard, history);
    } else {
      alert("Please accept the Terms and Conditions");
    }
  };
  useEffect(() => {
    toast.error(cardError);
  }, [cardError]);

  return (
    <div className="bg-nat">
      <ToastContainer />
      <div className="mx-12 py-5">
        <button
          className="bg-prim px-5 py-2 rounded text-white"
          onClick={() => history.push("/admin-in-house-orders")}
        >
          Back
        </button>
      </div>
      <div className="py-16 sm:px-32 md:px-32">
        <div className="w-full sm:flex md:flex">
          <div className="w-full sm:w-2/3 md:w-2/3 h-full p-6">
            <div className="w-full h-full">
              <div className=" flex items-center">
                <div className="w-full text-center">
                  <h1 style={{ fontSize: "2em" }} className="mb-4">
                    Dine In
                  </h1>
                </div>
              </div>
              <div className="w-full sm:hidden sm:w-1/3 md:w-1/3 h-full p-6">
                <YourOrder order={order} setOrder={setOrder} />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutHeader
                  title="Customer Name"
                  order={order}
                  setOrder={setOrder}
                />
              </div>
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                <CheckoutContact order={order} setOrder={setOrder} />
              </div>
              {/* <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                  <CheckoutTableNumber />
             </div> */}
              <div className="w-full bg-white py-4 sm:py-8 md:py-8 px-2 sm:px-6 md:px-6 my-4">
                {orderId && (
                  <PaymentOptionCard
                    options={["Card", "Cash", "Terminal"]}
                    localCard={localCard}
                    setLocalCard={setLocalCard}
                    setOption={setOption}
                    option={option}
                  />
                )}

                <PaymentOptionCash
                  options={["Cash"]}
                  localCard={localCard}
                  setLocalCard={setLocalCard}
                  setOption={setOption}
                  option={option}
                />
                <AgreementCheck
                  check={check}
                  setCheck={setCheck}
                  beforeLink="Before your order please make sure your "
                  link=" Food Allergy and T&amp;C."
                  afterLink=" If you have anything else, please contact us."
                />
                {orderError && <ErrorMessage message={orderError} />}

                <button
                  className={`mt-6 bg-prim text-white w-full rounded p-2`}
                  onClick={placeDineInOrder}
                >
                  Place Order
                </button>
              </div>
              <div className=""></div>
            </div>
          </div>
          <div className="w-full hidden sm:block sm:w-1/3 md:w-1/3 h-full p-6">
            <YourOrder order={order} setOrder={setOrder} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDineInServe;
