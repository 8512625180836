import {
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ALL_ORDERS_SUCCESS,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE
} from "../types/order/type";
import { 
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAILURE
} from "../types/card/type";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import { payByCard } from "./card";

export const getAllRestaurantsOrders = async (dispatch, token) => {
  const id = JSON.parse(localStorage.getItem("restaurant")).restaurant;
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/order/get-all-restaurant-orders/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};
export const getRestaurantsOrdersByOrderStatus = async (dispatch, token, orderStatus) => {
  const id = JSON.parse(localStorage.getItem("restaurant")).restaurant;
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/order/get-all-orders-by-delivery-status/${id}?status=${orderStatus}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    }
  } catch (err) {

    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};

export const getAllRestaurantsDineInOrders = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/order/get-orders-by-type/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};

export const getRestaurantsInhousePendingOrders = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/order/get-restaurant-inhouse-pending-dine-in-orders/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};

export const getBookingAllOrders = async (dispatch, token) => {
  const id = JSON.parse(localStorage.getItem("restaurant")).restaurant;
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/order/get-booking-all-orders/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};
export const getBookingPendingOrders = async (dispatch, token) => {
  const id = JSON.parse(localStorage.getItem("restaurant")).restaurant;
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/order/get-booking-pending-orders/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};
export const placeDineOrder = async (dispatch, token, option, localCard, history, type) => {
  const order = JSON.parse(localStorage.getItem('order'))
  localCard.total = Number.parseFloat(order.totalPrice).toFixed(2);
  
  try {
    const orderData = JSON.parse(localStorage.getItem('order'));

    if(type == "serve"){
      orderData.orderStatus = "Accepted";
    }

    console.log("orderrr",orderData)

    const { data: { data } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/order/create-inhouse-dine-in`, orderData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    console.log("order cookie data", data);
    // console.log(success);
    if (data) {
      dispatch({ type: GET_ORDER_SUCCESS, payload: data })
      if(option === 'Card'){
        payByCard(dispatch, token, data?._id, localCard, history)
      } else if(option === "Cash") {
        // history.push("/admin-in-house-orders");
        history.push(`/order-summary/${data._id}`);
        localStorage.removeItem('cart');
        localStorage.removeItem('order');
        localStorage.removeItem('checkOutorderType');
        localStorage.removeItem('tableNo');
      } else if(option === "Counter") {
        history.push(`/order-summary/${data._id}`);
        localStorage.removeItem('cart');
        localStorage.removeItem('order');
        localStorage.removeItem('checkOutorderType');
        localStorage.removeItem('tableNo');
      }
    }
  } catch (err) {
    const { response: { data: { error } } } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error })
  }
}



export const serveDineOrder = async (dispatch, token, option, localCard, history) => {
  const order = JSON.parse(localStorage.getItem('order'))
  localCard.total = Number.parseFloat(order.totalPrice).toFixed(2);
  
  try {
    const orderData = JSON.parse(localStorage.getItem('order'));
    orderData.orderStatus = "Accepted";

    console.log("orrr",orderData);
    return;

    const { data: { data } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/order/create-inhouse-dine-in`, orderData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    console.log("order cookie data", data);
    // console.log(success);
    if (data) {
      dispatch({ type: GET_ORDER_SUCCESS, payload: data })
      if(option === 'Card'){
        payByCard(dispatch, token, data?._id, localCard, history)
      } else if(option === "Cash") {
        // history.push("/admin-in-house-orders");
        history.push(`/order-summary/${data._id}`);
        localStorage.removeItem('cart');
        localStorage.removeItem('order');
        localStorage.removeItem('checkOutorderType');
        localStorage.removeItem('tableNo');
      } 
      else if(option === "Counter") {
        history.push(`/order-summary/${data._id}`);
        localStorage.removeItem('cart');
        localStorage.removeItem('order');
        localStorage.removeItem('checkOutorderType');
        localStorage.removeItem('tableNo');
      }
    }
  } catch (err) {
    const { response: { data: { error } } } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error })
  }
}
export const placeOrder = async (dispatch, token, localCard, option, history) => {
  const order = JSON.parse(localStorage.getItem('order'))
  localCard.total = Number.parseFloat(order.totalPrice).toFixed(2);
  try {
      const orderData = JSON.parse(localStorage.getItem('order'));

      console.log("order cookie ", orderData);

      const { data: { data } } = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/order/create-inhouse-order`, orderData, {
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "Application/json",
              "Authorization": `Bearer ${token}`,
          },
      })

      console.log("order cookie data", data);
      if (data) {
          dispatch({ type: GET_ORDER_SUCCESS, payload: data })
          if(option === 'Card'){
            payByCard(dispatch, token, data?._id, localCard, history)
          } else if(option === "Cash") {
            // history.push("/admin-in-house-orders");
            history.push(`/order-summary/${data._id}`);
            localStorage.removeItem('cart');
            localStorage.removeItem('order');
            localStorage.removeItem('checkOutorderType');
            localStorage.removeItem('tableNo');
          } else if(option === "Terminal") {
            localStorage.removeItem('cart');
            localStorage.removeItem('order');
            localStorage.removeItem('tableNo');
            history.push("/admin-in-house-orders")
          }
      }
  } catch (err) {
      const { response: { data: { error } } } = err;
      dispatch({ type: GET_ORDER_FAILURE, payload: error })
  }
}

export const getSalesByToday = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/order/get-orders-by-day/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};

export const getSalesBySevenDays = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${apiBaseUrl}${apiVersionPrefix}/order/get-orders-of-week/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};



export const getSingleOrder = async (dispatch, orderId, token) => {

  try {
    const { data: { data } } = await axios.get(`${apiBaseUrl}${apiVersionPrefix}/order/get-single-order/${orderId}`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
    if (data) {
      dispatch({ type: GET_ORDER_SUCCESS, payload: data })
    }
  } catch (err) {

    const { response: { data: { error } } } = err;
    dispatch({ type: GET_ORDER_FAILURE, payload: error })
  }
}

export const DeclineOrder = async (dispatch, token, id, setOpenModal) => {
  try {
    const {
      data: { data },
    } = await fetch(
      `${apiBaseUrl}${apiVersionPrefix}/order/restaurant-cancel-order/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: GET_ALL_ORDERS_SUCCESS, payload: data });
      setOpenModal(false);
    }
  } catch (err) {
    // const {
    //   response: {
    //     data: { error },
    //   },
    // } = err;
    // dispatch({ type: GET_ORDER_FAILURE, payload: error });
  }
};
export const AcceptOrder = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await fetch(
      `${apiBaseUrl}${apiVersionPrefix}/order/restaurant-accept-order/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ); 
    if (data) {
      dispatch({ type: UPDATE_CARD_SUCCESS, payload: data });
    }
  } catch (err) {
      dispatch({ type: UPDATE_CARD_FAILURE, payload: "Your card was declined. Try Again" })
  }
}

export const deleteRestaurantOrder = async (dispatch, token, id) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/order/restaurant-remove-order/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: DELETE_ORDER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: DELETE_ORDER_FAILURE, payload: error });
  }
};

export const addCartItem = async (dispatch, token, id, cart) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/order/update-inhouse-pendingOrder/${id}`, cart,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data });
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: UPDATE_ORDER_FAILURE, payload: error });
  }
};

export const updateOrder = async (dispatch, token, id, option, localCard, history) => {
  const order = JSON.parse(localStorage.getItem('order'));
  try {
    if(option === "Terminal") {
      order.paymentMethod = "Card";
    }
    const {
      data: { data },
    } = await axios.put(
      `${apiBaseUrl}${apiVersionPrefix}/order/update-order-inhouse/${id}`, order,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data) {
      dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data });
      if(option === 'Card'){
        payByCard(dispatch, token, data?._id, localCard, history)
      } else {
        history.push("/admin-in-house-orders");
        localStorage.removeItem('cart');
        localStorage.removeItem('order');
        localStorage.removeItem('tableNo');
      }
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err;
    dispatch({ type: UPDATE_ORDER_FAILURE, payload: error });
  }
};
