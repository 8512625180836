import { GET_RESTAURANT_FAILURE, GET_RESTAURANT_SUCCESS,GET_ALL_RESTAURANTS_SUCCESS, GET_RESTAURANT_ORDER_SUCCESS, GET_RESTAURANT_ORDER_FAILURE, GET_RESTAURANT_PAYMENT_SUCCESS, GET_RESTAURANT_PAYMENT_FAILURE } from '../types/restaurant/type';

const initialState = {
    restaurant: {},
    restaurants:[],
    status: null,
    error: null,
    errorState: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_RESTAURANT_SUCCESS:
        return {
          ...state,
          loading: false,
          status:"success",
          restaurant: action.payload
        };

        case GET_RESTAURANT_PAYMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          status:"success",
          restaurant: action.payload
        };

      case GET_ALL_RESTAURANTS_SUCCESS:
        return {
          ...state,
          loading: false,
          status:"success",
          restaurants: action.payload
        };

        case GET_RESTAURANT_ORDER_SUCCESS:
        return {
          ...state,
          loading: false,
          status:"success",
          restaurants: action.payload
        };

        case GET_RESTAURANT_ORDER_FAILURE:
        return {
          ...state,
          loading: false,
          status:"Failed",
          restaurants: action.payload
        };
  
      case GET_RESTAURANT_FAILURE:
        return {
          ...state,
          loading: false,
          status:"Failed",
          error: action.payload
        };

        case GET_RESTAURANT_PAYMENT_FAILURE:
        return {
          ...state,
          loading: false,
          status:"Failed",
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default reducer;

