/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from "react-redux";
import { FiMail, FiLock } from 'react-icons/fi';
import app from '../../config/firebase'
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import Image from "../../components/utilities/images/Image";
import LoginFormHeader from "../../components/LoginForm/LoginFormHeader";
import LoginFormFooter from "../../components/LoginForm/LoginFormFooter";
import AgreementCheck from "../../components/utilities/form/AgreementCheck";
import Button from "../../components/utilities/buttons/Button";
import ErrorMessage from "../../components/utilities/form/ErrorMessage";

import { restaurantSignin, restaurantSignup } from '../../redux/actions/restaurant';
import { getCookie, setCookie } from '../../components/utilities/cookie/cookie';

export default function SignIn() {

    const dispatch = useDispatch();
    const [isCovid, setIsCovid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [deviceToken, setDeviceToken] = useState('');
    const { type: submitType } = useSelector(state => state.form);
    const history = useHistory()
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        deviceToken: "",
    })
    
    const { error } = useSelector(state => state.restaurant);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(error != "Not authorized no token"){
            setErrorMessage(error)
        }
        if (submitType === "login") {
            await restaurantSignin(dispatch, formData, history)
        } else if (submitType === "signup") {
            await restaurantSignup(dispatch, formData, history)
        }
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    useEffect(() => {
        setFormData({ ...formData, email: "", password: "" })
        setErrorMessage(null)
    }, [submitType])
    
    useEffect(() => {
        const messaging = getMessaging(app);
        getToken(messaging, { vapidKey: process.env.FIREBASE_VAPID_KEY }).then((currentToken) => {
        if (currentToken) {
            setDeviceToken(currentToken)    
            setCookie('@device_token',currentToken);
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
        }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        });
        onMessage((payload) => {
            console.log(payload, "payload");
            })
    })

    useEffect(() => {
        setFormData({ ...formData, deviceToken: getCookie('@device_token') })
    }, [getCookie('@device_token'), deviceToken])
    console.log("hrisdo")
    return (
        <>
            <div className="w-full h-screen bg-nat">
                <div className="relative w-full h-full flex items-center justify-center">
                    <div className="absolute top-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food4.png"
                            alt=""
                        />
                    </div>
                    <div className="absolute bottom-0 left-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food1.png"
                            alt=""
                        />
                    </div>

                    <div className="rounded-lg w-full mx-4 sm:mx-0 md:mx-0 p-4 bg-white shadow-md sm:1/3 md:w-1/3">
                        {errorMessage && <ErrorMessage message={errorMessage} />}
                        <LoginFormHeader />

                        <form className="py-2" onSubmit={handleSubmit}>
                            <div id="sign-in-button"></div>
                            <div className="py-2">
                                <div className="bg-white shadow flex items-center p-3 rounded-lg">
                                    <FiMail className="text-lg" />
                                    <input name="email" onChange={handleChange} value={formData.email} type="text" className="outline-none px-3 w-full" placeholder="Email" />

                                </div>
                                <div className="bg-white shadow flex items-center p-3 rounded-lg mt-3">
                                    <FiLock className="text-lg" />
                                    <input name="password" onChange={handleChange} value={formData.password} type="password" className="outline-none px-3 w-full" placeholder="Password" />

                                </div>
                                <AgreementCheck check={isCovid} setCheck={setIsCovid} beforeLink="I Agree with" link={`${submitType === "login" ? "Covid-19 Guidelines." : "Terms & Conditions"}`} />

                            </div>
                            <div className="w-full py-2">
                                <Button classes="w-full">{submitType === "signup" ? "Sign up" : "Login"}</Button>
                            </div>
                        </form>
                        <LoginFormFooter type="signup" />
                    </div>

                    <div className="absolute bottom-0 right-0 w-4/12 mt-10 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food5.png"
                            alt=""
                        />
                    </div>
                    <div className="absolute top-0 left-0 mt-10 w-4/12 hidden sm:block md:block">
                        <Image
                            src="https://foodapps.uk/images/backgrounds/food2.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    )
}