import React from 'react';
import moment from "moment";

export default function AdminSalesReportContent(props) {
    return (
        <div className="mt-3 w-full border flex flex-wrap items-center p-3 rounded-lg hover:shadow-xl shadow-lg">
            <div className="flex items-center w-1/3">
                <div className="px-3">
                    <div className="pb-1">
                        <p className="font-bold text-xs sm:text-sm md:text-sm">{props.orderId}</p>
                    </div>
                </div>
            </div>
            <div className="w-1/3 text-center text-gray-600">
                <p>{moment(props.date).format("DD-MM-YYYY")}</p>
            </div>
            <div className="w-1/3 text-right">
                <p className="font-bold text-xs sm:text-sm md:text-sm">£{props.price}</p>
            </div>
        </div>
    )
}