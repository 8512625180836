import React from 'react';
import Address from '../../../pages/partner/AdminRestaurants/Address';
import AdminRestaurantDetails from '../../../pages/partner/AdminRestaurants/AdminRestutantDetails/AdminRestaurantDetails';
import Delivery from "../../../pages/partner/AdminRestaurants/Delivery"
import Timings from '../../../pages/partner/AdminRestaurants/Timings';

const Message = ({ value }) => {
    if (value === "Delivery") return <Delivery />
    if (value === "Timing") return <Timings />
    if (value === "Address") return <Address />
    if (value === "Profile") return <AdminRestaurantDetails />
};

export default Message;
