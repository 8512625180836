import React from 'react';
export default function FooterBottom() {
    return (
        <div className="px-3 bg-prim w-full">
            <div className="text-center">
                <a className="text-white text-xs font-bold" href="http://netsoftuk.com" target="_blank" rel="noreferrer">Copyright © 2022 www.foodapps.uk - Part of NetsoftUK</a>
            </div>
            <p className="text-white text-xs font-bold pb-1">Version: 1.0.0</p>
        </div>
    )

}
