import React, { useState } from "react";

import Modal from "react-modal";
import CardInfo from "./CardInfo";
import Header from "../../pages/partner/Epos/categories/SpecialOfferPopup/Header";

export default function PaymentOptionCard(props) {
  const [cardNum, setCardNum] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
        top: '30px',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, .1)'
    },
    modal: {
        outline: "none"
    }
};

  const handleSelectCard = async (option, i) => {
    setPaymentType(option);
    props.setOption(option);
    setCardNum(i);
    setIsOpen(true)
  };

  const handleSelectCash = (option, i) => {
    setPaymentType(option);
    props.setOption(option);
    setCardNum(i);
  };

  const setPaymentType = (option) => {
    const order = JSON.parse(localStorage.getItem("order"));
    order.paymentMethod = option;
    localStorage.setItem("order", JSON.stringify(order));
  };

  return (
    <div className="flex flex-col">
      {props.options?.map((op, i) => (
        <div key={i} className="">
          {op === "Card" && (
            <div onClick={() => handleSelectCard(op, i)}>
              <h3
                style={props.style}
                className={`font-semibold text-md border-2 border-prim rounded-md p-3 flex items-center justify-center min-h-110 cursor-pointer ${props.classes}`}
              >
                {op}
              </h3>
            </div>
          )}
        </div>
      ))}

          <Modal
            isOpen={isOpen}
            style={customStyles}
            className="w-full outline-none absolute border border-none"
            ariaHideApp={false}
            >
            <div className="w-full h-screen">
              <div className="w-full h-full flex items-center justify-center">
                <div className="w-full sm:w-1/3 md:w-1/3 mx-8 sm:mx-0 md:mx-0 bg-white rounded-lg p-6 mt-16">
                  <Header heading="Enter Card Information" setOpenModal={setIsOpen} />
                  {props.option === "Card" && (
                      <CardInfo
                      setCheck={props.setCheck}
                      check={props.check}
                      localCard={props.localCard}
                      setLocalCard={props.setLocalCard}
                      />
                  )}
                  </div>
              </div>
            </div>
          </Modal>
     
    </div>
  );
}
