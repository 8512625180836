import React, { useState } from "react";

import styles from "./styles";

import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getAllOptions, updateOption } from "../../../../../../../redux/actions/option";
import { getCookie } from "../../../../../../../components/utilities/cookie/cookie";

import OptionInput from "./OptionInput";
import OptionType from "./OptionType";
import TypeInput from "./TypeInput";
import Input from '../../../../../../../components/utilities/form/Field'

toast.configure();
const EditOption = ({ edit, setEdit }) => {
    const { foodOptionModalArea } = styles;

    const { food } = useSelector(state => state.foods);

    const foodId = food._id;

    const dispatch = useDispatch();

    const token = getCookie("@partner_token");

    const { restaurant } = JSON.parse(localStorage.getItem("restaurant"));

    const dataOption = useSelector(state => state.option.option);
    // const [securityKey, setSecurityKey] = useState(null);
    const [option, setOption] = useState({
        "choiceCount": dataOption.choiceCount,
        "name": dataOption.name,
        "food": food._id,
        "types": dataOption.types,
        "restaurant": restaurant

    });

    const optionId = dataOption._id;

    const [type, setType] = useState({
        typeName: "",
        price: 0,
        isAdded: false
    })

    const [showType, setShowType] = useState(false);
    const {option: { error }} = useSelector(state => state.option)
    const handleCancel = () => {
        setType({ typeName: "", price: 0, isAdded: false })
        setEdit(false)
    }

    const handleEditOption = async (e) => {
        e.preventDefault();
        await updateOption(dispatch, foodId, token, optionId, option, setEdit);
        await getAllOptions(dispatch, foodId);
        toast.error(error, { position: toast.POSITION.TOP_RIGHT })
    }
    
    return (
        <div>
            <ToastContainer style={{ fontSize: "1rem" }} />
            <Modal
                isOpen={edit}
                onRequestClose={() => setEdit(false)}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div style={foodOptionModalArea}>
                
                    <OptionInput option={option} setShowType={setShowType} setOption={setOption} setType={setType} />
                    {
                        option.types && option.types.map((item, index) => (

                            <OptionType option={option} item={item} setOption={setOption} key={index} />

                        ))
                    }
                    {
                        showType && (
                            <TypeInput setType={setType} type={type} option={option} setOption={setOption} setShowType={setShowType} />
                        )
                    }
                    <div className="flex justify-end mt-3">
                        {/* <Input
                            className="w-full outline-none p-1 text-black font-bold border border-red-700 rounded"
                            placeholder="Please Enter Security Key"
                            name="securityKey"
                            onChange={(e) => setSecurityKey(e.target.value)}
                        /> */}
                        <button className=" mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleEditOption}>Save</button>
                        <button className=" mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </Modal>

        </div>
    )
}


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, .8)",
    },
    modal: {
        outline: "none",
    },
};


export default EditOption;
