import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import CheckoutHeader from "../../../../components/checkout/CheckoutHeader";
import YourOrder from "../YourOrder";
import CheckoutDeliveryAddress from "../CheckoutDeliveryAddress";
// import CheckoutTimeSchedule from "../CheckoutTimeSchedule";
import CheckoutContact from "../CheckoutContact";
import PaymentOptionCard from "../../../../components/checkout/PaymentOptionCard";
import PaymentOptionCash from "../../../../components/checkout/PaymentOptionCash";

import { placeOrder } from "../../../../redux/actions/order";
import { getCookie } from "../../../../components/utilities/cookie/cookie";

export default function HomeDelivery() {
  const history = useHistory();
  // const restaurantId = JSON.parse(localStorage.getItem("order"))
  //   ? JSON.parse(localStorage.getItem("order")).restaurant
  //   : "";
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem("order")));
  const [option, setOption] = useState("cash");
  const [localCard, setLocalCard] = useState({
    total: order?.totalPrice,
    cardNumber: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  useEffect(() => {
    setOrder(JSON.parse(localStorage.getItem("order")));
  }, []);
  // console.log(order)
  localCard.total = order?.totalPrice;
  const { error: cardError } = useSelector((state) => state.card);
  const token = getCookie("@partner_token");
  const dispatch = useDispatch();

  const submitOrder = async (e) => {
    e.preventDefault();
    await placeOrder(dispatch, token, localCard, option, history);
  };
  const submitOrderCash = async (e) => {
    e.preventDefault();
    await placeOrder(dispatch, token, localCard, option, history);
  };
  useEffect(() => {
    toast.error(cardError);
  }, [cardError]);
  const  { status }  = useSelector((state) => state.deliveryCharge)
  
  return (
    <div className="grid grid-cols-4 gap-3">
      <div className="hidden">
        <YourOrder order={order} setOrder={setOrder} />
      </div>
      <div className="hover:bg-prim hover:text-white">
        <CheckoutHeader
          title="Customer Name"
          order={order}
          setOrder={setOrder}
        />
      </div>
      <div className="hover:bg-prim hover:text-white">
        <CheckoutDeliveryAddress order={order} setOrder={setOrder} />
      </div>
      {/* <div>
        <CheckoutTimeSchedule />
      </div> */}
      <div className="hover:bg-prim hover:text-white">
        <CheckoutContact order={order} setOrder={setOrder} />
      </div>
      <div className="hover:bg-prim hover:text-white">
        <PaymentOptionCard
          options={["Card"]}
          localCard={localCard}
          setLocalCard={setLocalCard}
          setOption={setOption}
          option={option}
        />
      </div>
      <div className="hover:bg-prim hover:text-white">
        <PaymentOptionCash
          options={["Cash"]}
          localCard={localCard}
          setLocalCard={setLocalCard}
          setOption={setOption}
          option={option}
        />
      </div>
      {/* <AgreementCheck
                  className="hidden"
                  beforeLink="Before your order please make sure your "
                  link=" Food Allergy and T&amp;C."
                  afterLink=" If you have anything else, please contact us."
                  setCheck={setCheck}
                  check={check}
                /> */}
      {option === "cash" ? (
        <button
          className="hover:bg-prim hover:text-white border-2 min-h-110 border-prim rounded-md p-3 flex items-center justify-center min-h-300 cursor-pointer text-md font-bold"
          onClick={submitOrderCash}
        >
          Submit Order By Cash
        </button>
      ) : (
        <button
          className="hover:bg-prim hover:text-white border-2 min-h-110 border-prim rounded-md p-3 flex items-center justify-center min-h-300 cursor-pointer text-md font-bold"
          onClick={submitOrder}
        >
          Proceed to Checkout
        </button>
      )}

      <ToastContainer />
      <div className="hidden">
        <YourOrder order={order} setOrder={setOrder} />
      </div>
    </div>
  );
}
