import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import { getCookie } from "../../../../../../components/utilities/cookie/cookie";
import Toggle from "../../../../../../components/utilities/form/Toggle.js";
import { getAllBusinessType } from "../../../../../../redux/actions/businessType";
import {
  getAllCuisines,
  updateCuisine,
} from "../../../../../../redux/actions/cuisine";
import {
  getRestaurantOwnInfo,
  updateRestaurant,
} from "../../../../../../redux/actions/restaurantInfo";
import SelectBusinessType from "./selectBusinessType";
import SelectCuisine from "./SelectCuisine";

export default function NameSpeciality({ setIsEdit }) {
  const { restaurantInfo } = useSelector((state) => state?.restaurantInfo);
  const { cuisines } = useSelector((state) => state?.cuisines);
  const { businessType } = useSelector((state) => state?.businessType);
  // console.log(businessType)
  const restaurantInfoId = restaurantInfo._id;
  let cuisine = [];
  // let initial=restaurantInfo.cuisines;
  // const [newCuisine, setNewCuisine] = useState(restaurantInfo.cuisines);
  const [formData, setFormData] = useState({
    name: restaurantInfo.name,
    speciality: restaurantInfo.speciality,
    capacity: restaurantInfo.capacity,
    cuisines: restaurantInfo?.cuisines,
    businessType: restaurantInfo?.businessType,
    isAvailable: restaurantInfo?.isAvailable,
  });
  restaurantInfo?.cuisines?.forEach((item) => {
    const findCuisine = cuisines?.find((id) => id._id === item);
    cuisine = [...cuisine, findCuisine];
  });
  // console.log(cuisines)
  const restaurant = {
    id: restaurantInfo._id,
  };
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  const [inputForm, setInputForm] = useState({
    name: "",
    index: "",
  });
  const [options, setOptions] = useState(false);

  useEffect(() => {
    getAllCuisines(dispatch);
    getAllBusinessType(dispatch)
  }, [dispatch]);
  const handleInputFormChange = (e) => {
    e.preventDefault();
    setInputForm({ ...inputForm, name: e.target.value });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const string = inputForm.name;
      setFormData({
        ...formData,
        speciality: [...formData.speciality, string],
      });
      setInputForm({ name: "" });
    }
  };

  const handleCuisine = (e, ac) => {
    e.forEach((item) => {
      if (ac.action === "select-option") {
        setFormData({
          ...formData,
          cuisines: [...formData.cuisines, ac.option.value],
        });
      } else if (ac.action === "remove-value") {
        setFormData({
          ...formData,
          cuisines: formData.cuisines.filter(
            (id) => id !== ac.removedValue.value
          ),
        });
      } else if (ac.action === "clear") {
        setFormData({ ...formData, cuisines: [] });
      }
    });
  };
  const handleBusinessType = (e) => {
    // console.log(e)
    setFormData({...formData,businessType:e.value})
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateRestaurant(dispatch, formData, restaurantInfoId, token);
    await getRestaurantOwnInfo(dispatch, token);
    const cuisineInfo = {
      id: formData.cuisines,
      restaurant: restaurant.id,
    };
    await updateCuisine(dispatch, cuisineInfo);

    setOptions(!options);

    setIsEdit(false);
  };
  const handleDelete = (el) => {
    const updatedSpeciality = formData.speciality.filter((item) => item !== el);
    setFormData({ ...formData, speciality: updatedSpeciality });
  };
  const allCuisine = cuisines.map((item) => {
    return {
      value: item?._id,
      label: item?.type,
    };
  });
  // const allBusinessType=''
  const allBusinessType = businessType?.map((item) => {
    return {
      value: item?._id,
      label: item?.businessType,
    };
  });

  const defualtCuisine = cuisine?.map((item) => {
    return {
      value: item?._id,
      label: item?.type,
    };
  });
  const findBusinessType = businessType?.find(item => item._id === restaurantInfo?.businessType)
  const defaultBusinessType = {
    value:findBusinessType?._id,
    label:findBusinessType?.businessType
  }
  const handleToggleLClick = () => {
    
    setFormData({ ...formData, isAvailable: !formData.isAvailable });
   
    
  };

  
  return (
    <form onSubmit={handleSubmit} className="block border-b-2 pb-2">
      <div className="w-full">
        <div className="w-full">
          <label className="text-xs font-bold block mb-2"> Name:</label>
          <input
            placeholder="Restaurant Name"
            className="bg-gray-100 h-12 w-full text-2xl outline-none border border-red-700 rounded-lg px-3 py-2"
            value={formData.name}
            onChange={handleChange}
            name="name"
          />
        </div>
        {/* <div className="flex p-2 items-center ">
            <span className="mr-2 font-bold text-xs">Active</span>
            <button onClick={handleToggleLClick}>
              <Toggle checked={formData.isAvailable} />
            </button>
          </div> */}
        <div className="flex items-center">
          <div className="w-1/2 mr-2">
            <label className="text-xs font-bold">Specialities:</label>
            <div className="mt-2 flex items-center rounded-lg border border-red-700 py-1 px-2 overflow-hidden">
              {formData.speciality && formData.speciality.length > 0
                ? formData.speciality.map((el, i) => (
                    <div className="mr-1" key={i} index={i}>
                      <div className="cursor-pointer px-1 pl-2 font-bold p-1 flex items-center text-white rounded-full bg-prim">
                        <p className="text-xs">{el}</p>
                        <div className="ml-1 p-1 rounded-full bg-white text-black">
                          <FiX onClick={() => handleDelete(el)} />
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              <input
                placeholder="Type and press Enter to add!"
                className="flex-grow outline-none px-3 py-2"
                onChange={handleInputFormChange}
                onKeyPress={handleKeyPress}
                value={inputForm.name}
              />
            </div>
          </div>
          <div className="relative  w-1/2 flex justify-evenly  text-left">
            <div className="mr-4">
              <label className=" block text-xs font-bold pt-2 mb-2  ">
                Cuisine:
              </label>
              <SelectCuisine
                defualtCuisine={defualtCuisine}
                handleCuisine={handleCuisine}
                allCuisine={allCuisine}
              />
            </div>
            <div className="ml-2">
              <label className=" block text-xs font-bold pt-2 mb-2 ">
                Business Type:
              </label>
              <SelectBusinessType
                defaultBusinessType={defaultBusinessType}
                handleBusinessType={handleBusinessType}
                allBusinessType={allBusinessType}
              />
            </div>
          </div>
          
          <div className="w-1/6 ml-4">
            <label className=" block text-xs font-bold pt-2 ">
              Booking Capacity:
            </label>
            <input
              placeholder="Ex:50"
              className="w-full mt-2 h-12 outline-none py-2 px-4 rounded-lg border border-prim"
              value={formData.capacity}
              onChange={handleChange}
              name="capacity"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-4 ml-2 justify-end">
        <button
          type="submit"
          className="py-2 px-4 bg-red-700 text-white rounded-lg"
        >
          Update
        </button>
      </div>
    </form>
  );
}
