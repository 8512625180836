import React, { useState } from "react";
import Button from "../../../../../components/utilities/Button";
import Input from "../../../../../components/utilities/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  addCategory,
  getRestaurantAllCategories,
} from "../../../../../redux/actions/categories";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../../components/utilities/cookie/cookie";

const AddCategory = () => {
  const [isAdd, setIsAdd] = useState(false);
  const { restaurant } = useSelector((state) => state.restaurant);
  const [formData, setFormData] = useState({
    name: "",
  });
  const id = restaurant._id;

  const { name } = formData;

  const token = getCookie("@partner_token");

  const dispatch = useDispatch();

  const handleState = () => {
    setIsAdd((prevState) => !prevState);
  };

  const handleChange = (key) => (value) => {
    setFormData({ [key]: value });
  };

  const handleAddCatagory = async () => {
    await addCategory(dispatch, { token, formData });
    await getRestaurantAllCategories(dispatch, id, token);
    setIsAdd((prevState) => !prevState);
  };

  return (
    <>
      <div
        className={
          isAdd
            ? "hidden"
            : "w-full bg-red-700 text-white py-3 px-4 rounded-lg font-semibold text-center cursor-pointer"
        }
        onClick={handleState}
      >
        Add Category
      </div>
      <Input
        className={
          isAdd
            ? "w-full border border-red-700 py-2 px-4 rounded-lg outline-none"
            : "hidden"
        }
        placeholder={"Add new Category"}
        value={name}
        onChange={handleChange("name")}
      />
      <div className="flex justify-end mt-3">
        <Button
          className={
            isAdd
              ? "bg-red-700 px-2 py-2 text-white rounded-md text-sm mr-2"
              : "hidden"
          }
          onClick={handleAddCatagory}
        >
          <FontAwesomeIcon icon={faPlus} className="text-xs" /> Add
        </Button>
        <Button
          className={
            isAdd
              ? "bg-red-700 px-2 py-2 text-white rounded-md text-sm mr-2"
              : "hidden"
          }
          onClick={handleState}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default AddCategory;
