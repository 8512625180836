import React from "react";
import { FiMail, FiSend, FiTrash2 } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";

import { getCookie } from "../utilities/cookie/cookie";

import Icon from "../utilities/Icon";
import {
  getAllRestaurantAllContacts,
  deleteRestaurantContact,
} from "./../../redux/actions/contact";

export default function InboxContent(props) {
  const dispatch = useDispatch();

  const token = getCookie("@partner_token");
  const { restaurant } = useSelector((state) => state.restaurant);

  const id = restaurant.restaurant;

  const handleDelete = () => {
    deleteRestaurantContact(dispatch, token, props.contact);
    getAllRestaurantAllContacts(dispatch, token, id);
  };

  return (
    <div
      style={props.containerStyle}
      className={`flex items-center w-full rounded-lg hover:shadow-lg ${props.containerClasses}`}
    >
      <div className="py-2 w-4/12 pl-3 cursor-pointer flex items-center ">
        <Icon classes="text-prim" icon={FiMail} />
        <p className="ml-2">{props.name}</p>
      </div>
      <div className="py-2 w-6/12 cursor-pointer">
        <p>{props.reason}</p>
      </div>
      <div className="py-2 w-2/12 pr-6 flex items-center justify-between">
        <div className="h-full flex-grow py-4 cursor-pointer"></div>
        <div className="px-3">
          <Icon onClick={handleDelete} icon={FiTrash2} />
        </div>
        <Icon onClick={props.send} icon={FiSend} />
      </div>
    </div>
  );
}
