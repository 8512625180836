import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";
import { GET_RESTAURANT_DELIVERY_CHARGE_FAILURE, GET_RESTAURANT_DELIVERY_CHARGE_SUCCESS } from "../types/deliveryCharge/type";
export const getDeliveryCharge = async (dispatch, deliveryFormData) => {
    try {
      const data = await axios.post(`${apiBaseUrl}${apiVersionPrefix}/restaurant-delivery/calculate-delivery-charge`, deliveryFormData)
  
      if (data) {
        dispatch({ type: GET_RESTAURANT_DELIVERY_CHARGE_SUCCESS, payload: data.data });
      }
    } catch (err) {
      const {
        response: {
          data: { error },
        },
      } = err;
      dispatch({ type: GET_RESTAURANT_DELIVERY_CHARGE_FAILURE, payload: error });
  
    }
  
    // console.log(deliveryFormData)
  };