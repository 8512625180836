import React, { useState, useEffect } from "react";
  
import "react-daterange-picker/dist/css/react-calendar.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import AdminInvoicesContent from "./AdminInvoicesContent";
import AdminLayout from "../../../components/layout/AdminLayout";
import Heading from "./../../../components/utilities/Heading";
import {
  get7DaysInvoice,
  getCurrentDayInvoice
} from "../../../redux/actions/invoice";
import { getCookie } from "../../../components/utilities/cookie/cookie";
import Tabs from "../../../components/utilities/Tabs";
const menus = ["Today","Last 7 Days"];

export default function AdminInvoices() {
  const token = getCookie("@partner_token");
  const dispatch = useDispatch();
  const id = JSON.parse(localStorage.getItem("restaurant"))?.restaurant;
  const [selectedTab, setSelectedTab] = useState(menus[0]);
  const {
    invoice: { invoice, orders },
  } = useSelector((state) => state.invoice);

  useEffect(() => {
    if (selectedTab === menus[0]) {
      getCurrentDayInvoice(dispatch, token, id);
    } else if (selectedTab === menus[1]) {
      get7DaysInvoice(dispatch, token, id);
      //get30DaysInvoice(dispatch, token, id);
    } 
  }, [selectedTab, dispatch, token, id]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps Partner | Invoices</title>
      </Helmet>
      <AdminLayout multi>
        <Heading>Invoices</Heading>
        <div className="mt-4">
          <div className="sm:flex md:flex items-center justify-between">
            <Tabs
              variant="prim"
              tabs={menus}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
        </div>

        <div className="bg-white rounded-lg mt-4 sm:mt-8 md:mt-8">
          <div className="bg-white rounded-lg mt-4 sm:mt-8 md:mt-8">
          <div className="w-full ">
              <div className="flex items-center w-full shadow-md">
                <div className="p-5 w-4/12 md:w-2/12 border-r">
                  <p className="text-sm font-semibold">Date</p>
                </div>
                <div className="p-4 sm:p-5 md:p-5 w-3/12 md:w-8/12 border-r">
                  <p className="text-sm font-semibold">Description</p>
                </div>
                <div className="p-4 sm:p-5 md:p-5 w-3/12 md:w-8/12 border-r">
                  <p className="text-sm font-semibold">Order Type</p>
                </div>
                <div className="p-2 py-4 sm:p-5 md:p-5 md:w-2/12 pr-3">
                  <p className="text-sm font-semibold truncate">Amount</p>
                </div>
              </div>
              <div className="border-b-2">
                {orders?.length > 0 ? (
                  orders?.map((order, index) => (
                    <AdminInvoicesContent
                      date={order.createdAt.split("T")[0]}
                      description={order.paymentMethod}
                      orderType={order.orderType}
                      amount={Number.parseFloat(order.totalPrice).toFixed(2)}
                      key={index}
                    />
                  ))
                ) : (
                  <h1 className="text-center font-bold">No Orders!</h1>
                )}
              </div>
              {orders?.length > 0 ? (
              <div>
                <div className="border-b-2">
                <AdminInvoicesContent
                  date="Gross Income"
                  description=""
                  amount={Number.parseFloat(invoice?.grossTotal).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  date="Cash Collected"
                  description=""
                  amount={Number.parseFloat(invoice?.cashCollected).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  date="FA Charge"
                  description=""
                  amount={Number.parseFloat(invoice?.faCharges).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  // date="Inhouse FA Charge"
                  date="Order Charge"
                  description=""
                  amount={Number.parseFloat(invoice?.inhouseFaCharge).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  date="Service Charge"
                  description=""
                  amount={Number.parseFloat(invoice?.serviceCharge).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  date="Dine In Charge"
                  description=""
                  amount={Number.parseFloat(invoice?.dineInCharge).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  date="Rider Charge"
                  description=""
                  amount={Number.parseFloat(invoice?.riderCharge).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  date="Booking Charge"
                  description=""
                  amount={Number.parseFloat(invoice?.bookingCharge).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  date="Bank Charge"
                  description=""
                  amount={Number.parseFloat(invoice?.bankCharge).toFixed(2)}
                />
              </div>
              <div className="border-b-2">
                <AdminInvoicesContent
                  date="Total"
                  description=""
                  amount={Number.parseFloat(invoice?.total).toFixed(2)}
                />
              </div>
              </div>
              ): (
                <span></span>
              )}
              
            </div>
          </div>
        </div>
      </AdminLayout>
    </HelmetProvider>
  );
}
