import React from "react";

const Packages = ({ packages }) => {
  return (
    <div className="py-3 flex flex-wrap">
      {packages.map((el, i) => (
        <div key={i} className="w-1/2">
          <div className="py-1 flex flex-wrap">
            <p className="text-sm font-bold text-gray-700 w-full px-3">
              Chooseable Items: {el.chooseAny}
            </p>
            {el.foods.map((food, i) => (
              <div key={i} className="p-2 px-3 w-full">
                <div className="flex items-center justify-between p-2 border-b border-red-700">
                  <p className="text-xs font-bold text-prim">{food.name}</p>
                  <p className="text-xs font-bold text-prim ml-4">
                    (Actual Price: £{food.price})
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Packages;
