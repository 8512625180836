import React from "react";
// import { FiUser } from "react-icons/fi";

const CheckoutTimeSchedule = () => {
  return (
    <div>
      <div className="bg-nat p-2">
        <h3 className="font-semibold">Normal Delivery</h3>
        <h3 className="text-gray-700">45 Mins</h3>
      </div>

      <div className="bg-nat my-4 p-2">
        <h3 className="font-semibold">Busy Time</h3>
        <h3 className="text-gray-700">60 Mins</h3>
      </div>

      <div className="bg-nat p-2">
        <h3 className="font-semibold">Extreme Busy</h3>
        <h3 className="text-gray-700">90 Mins</h3>
      </div>
    </div>
  );
};

export default CheckoutTimeSchedule;
