import React, { useState } from "react";





export default function PaymentOptionCard(props) {
  const [cardNum, setCardNum] = useState(null)
  const [active, setActive] = useState(undefined);

  

  const handleSelectCash = (option, i) => {
    setPaymentType(option)
    props.setOption(option)
    setCardNum(i);
    setActive(i)
  }

  const setPaymentType = (option) => {
    const order = JSON.parse(localStorage.getItem('order'));
    order.paymentMethod = option;
    localStorage.setItem('order', JSON.stringify(order))
  }

  return (
    <div className="flex flex-col">
      {props.options?.map((op, i) => (
        <div key={i} className="">
          {/* {op === "Card" && (
            <div
              className={cardNum === i ? "rounded p-4 cursor-pointer border-2 bg-prim text-white" : "rounded p-4 cursor-pointer border-2 bg-prim text-white"}
              onClick={() => handleSelectCard(op, i)} 
            >
              <h3
                style={props.style}
                className={`text-lg text-center font-bold italic ${props.classes}`}
              >
                {op}
              </h3>
            </div>

          )} */}
          

          {op !== "Card" && (
            <div
            
              onClick={() => handleSelectCash(op, i)}
            >
              <h3
                style={props.style}
                className={`${active == i ? "bg-prim text-white" : ""} font-semibold text-md border-2 border-prim rounded-md p-3 flex items-center justify-center min-h-110 cursor-pointer ${props.classes}`}
              >
                {op}
              </h3>
            </div>
          )}
        </div>
      ))}
    
    {/* {props.option === "Card" &&
              <div className="border-2 border-prim rounded-md p-4 mt-4">
                  <CardInfo setCheck={props.setCheck} check={props.check} localCard={props.localCard} setLocalCard={props.setLocalCard} />
              </div>
            } */}
  
    </div>
  );
}
