import React, { useState } from 'react';

import Modal from 'react-modal';
import { FiX } from 'react-icons/fi'

import ItemOption from './ItemOption';
import Icon from '../../../../../components/utilities/Icon';

export default function OrderOptionsPopup(props) {
    let { openModal, setOpenModal, food, item, setItem, cart, setCart } = props;
    const [isDisable, setIsDisable] = useState(false)
    const handleResetOption = () => {
        setItem({ ...item, options: [], optionsPrice: 0, price: 0 })
        setOpenModal(false);
    }

    const handleAddToCart = (item) => {
        const updatedCart = { ...cart }
        updatedCart.subTotal += item.price;
        item.uniqueId = item.name.substring(0, 3).toUpperCase() + Math.floor(Math.random() * 1000) + 1;
        item.quantity += 1;
        item.price = (item.basePrice + item.optionsPrice);
        updatedCart.foodItems.push(item);
        cart = { ...updatedCart, count: updatedCart.foodItems.length }
        localStorage.setItem("cart", JSON.stringify(cart));
        setCart({ ...updatedCart, foodItems: updatedCart.foodItems, count: updatedCart.foodItems.length, subTotal: updatedCart.subTotal });
        setOpenModal(false)
    }

    return (
        <Modal
            isOpen={openModal}
            style={customStyles}
            className="w-full outline-none absolute border border-none "
            ariaHideApp={false}
        >

            <div className="w-full h-screen flex items-center justify-center">
                <div className="w-full sm:w-1/3 md:w-1/3 mx-8 sm:mx-0 md:mx-0 bg-white rounded-lg p-6 mt-16">
                    <div className="flex justify-end mb-2">
                        <Icon onClick={handleResetOption} icon={FiX} color="text-gray-600 text-2xl" />
                    </div>
                    {/* <div className='w-full border-2 border-green-900 rounded-lg  px-1 py-2 h-20 flex flex-wrap items-start  overflow-y-scroll'>{item.options && item.options?.length > 0 ? (
                        item.options?.map((el, index) => (
                            <div key={index} className='text-red-700 bg-red-200 px-4 py-1 mx-1 my-2 rounded-full'>{el}</div>
                        ))
                    ) : null}</div> */}
                    <div className="mt-2 w-full rounded-lg shadow-lg bg-white border overflow-y-scroll h-56 scroll">
                        {food.options && food.options?.length > 0 ? (
                            food.options?.map((el, index) => (
                                <ItemOption setIsDisable={setIsDisable} isDisable={isDisable} key={index} option={el} item={item} setItem={setItem} />
                            ))
                        ) : null}
                    </div>
                    <div className='w-full text-center py-3 text-gray-600 rounded-lg mt-2 font-bold'>Total Price: £{Number.parseFloat(item.price).toFixed(2)}</div>
                    {
                        item.options?.length > 0 ? (
                            <button disabled={isDisable} onClick={() => handleAddToCart(item)} className='mt-2 px-4 w-full bg-red-700 py-2 text-white rounded-lg font-semibold hover:bg-red-900'>Add to Cart</button>
                        ) : (
                            <button className='mt-2 px-4 w-full bg-gray-700 py-2 text-black rounded-lg font-semibold'>Add Something to Cart</button>
                        )
                    }

                </div>
            </div>

        </Modal>
    )
}
const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        //   zIndex                : '500'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, .8)'
    },
    modal: {
        outline: "none",
        height: "600px"

    }
};