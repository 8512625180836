import React, { useState, useEffect } from "react";
import Button from "../../../components/utilities/buttons/Button";
// import useClickOutside from '../../../Hooks/useOutsideClick';

export default function OrderType(props) {
  let { options, setLocalOrderType } = props;
  const [selectedType, setSelectedType] = useState(
    localStorage.getItem("order")
      ? JSON.parse(localStorage.getItem("order"))?.orderType
      : ""
  );
  const [active, setActive] = useState(undefined);

  useEffect(() => {
    setOrderObj(
      localStorage.getItem("order")
        ? JSON.parse(localStorage.getItem("order")).orderType
        : ""
    );
  }, [selectedType]);

  const restaurantId = JSON.parse(
    localStorage.getItem("restaurant")
  ).restaurant;

  //   let [cart, setcart] = useState({
  //     customer: "",
  //     restaurant: restaurantId,
  //     foodItems: [],
  //     count: 0,
  //     subTotal: 0,
  //     note: "",
  //   });

  let cart = {
    customer: "",
    restaurant: restaurantId,
    foodItems: [],
    count: 0,
    subTotal: 0,
    note: "",
  };

  //   const myCart = JSON.parse(localStorage.getItem("cart"));
  //   if (myCart && myCart.restaurant === restaurantId) {
  //     setcart({
  //       ...cart,
  //       foodItems: myCart.foodItems,
  //       subTotal: myCart.subTotal,
  //     });
  //   } else {
  //     setcart({
  //       customer: "",
  //       restaurant: restaurantId,
  //       foodItems: [],
  //       count: 0,
  //       subTotal: 0,
  //     });
  //   }

  const handleSetOrderType = (type, key) => {
    setOrderObj(type);
    setSelectedType(type);
    setLocalOrderType(type);
    setActive(key);

    let cartCookie = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : "";
    if (cartCookie === "") {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  };

  const setOrderObj = (type) => {
    let order = JSON.parse(localStorage.getItem("order"));
    const newOrder = { ...order };
    newOrder.orderType = type;
    order = { ...newOrder };
    localStorage.setItem("order", JSON.stringify(order));
  };

  return (
    <div className="inline-flex">
      {options &&
        options.length > 0 &&
        options.map((type, key) => {
          return (
            <Button
              classes={`${active === key ? "bg-btn-active" : ""} mr-6`}
              onClick={(e) => handleSetOrderType(type, key)}
            >
              {type}
            </Button>
          );
        })}
    </div>
  );
}
