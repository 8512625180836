import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../../components/utilities/cookie/cookie.js";
import Toggle from "../../../../../components/utilities/form/Toggle.js";
import { addRestaurantDelivery } from "../../../../../redux/actions/restaurantDelivery.js";
import Message from "../../../../../components/admin/admin/Message";


const Delivery = ({ state, setState }) => {
  const [formData, setFormData] = useState({
    isDeliveryAvailable: false,
    isRiderService: false,
    areaLimit: "",
    deliveryCharge: 0,
    deliveryChargeOffArea: 0,
    deliveryChargeOffAreaLimit: 0

  });
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  const handleDeliveryToggle = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      isDeliveryAvailable: !formData.isDeliveryAvailable,
    });
  };
  const handleRiderToggle = (e) => {
    e.preventDefault();
    setFormData({ ...formData, isRiderService: !formData.isRiderService });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addRestaurantDelivery(dispatch, token, formData);
    setState({ ...state, isDelivery: true });
  };

  const { restaurantDelivery } = useSelector(state => state.restaurantDelivery);
  // console.log(restaurantDelivery)

  if (restaurantDelivery._id) {
    return (
      <Message value="Delivery" />
    )
  }
  return (
    <div>
      <h1 className="w-32 border-b-2 font-bold py-3 ">Delivery</h1>
      <div className="mt-2 flex flex-wrap items-center mb-4">
        <div className="w-1/3 flex items-center">
          <span className="mr-2 font-semibold">Delivery Available</span>
          <div className="mt-1">
            <button onClick={handleDeliveryToggle}>
              <Toggle checked={formData.isDeliveryAvailable} />
            </button>
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="mr-2 font-semibold">Rider Service</span>
          <div className="mt-1">
            <button onClick={handleRiderToggle}>
              <Toggle checked={formData.isRiderService} />
            </button>
          </div>
        </div>
        <div className="w-1/3 flex items-center ">
          <label className=" font-semibold">Delivery Charge</label>
          <input
            placeholder="Example: 1.50"
            type="number"
            className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim"
            onChange={handleChange}
            name="deliveryCharge"
            value={formData.deliveryCharge}
          />
        </div>
        <div className="w-1/3 flex items-center ">
          <label className=" font-semibold">Delivery Area Limit</label>
          <input
            placeholder="Ex:1"
            type="number"
            className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim"
            onChange={handleChange}
            name="areaLimit"
            value={formData.areaLimit}
          />
        </div>
        <div className="w-1/3 flex items-center ">
          <label className=" font-semibold">Delivery Over Area Limit</label>
          <input
            placeholder="Ex:1"
            type="number"
            className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim"
            onChange={handleChange}
            name="deliveryChargeOffAreaLimit"
            value={formData.deliveryChargeOffAreaLimit}
          />
        </div>
        <div className="w-1/3 flex items-center ">
          <label className=" font-semibold">Delivery Charge Over Area</label>
          <input
            placeholder="Ex:1"
            type="number"
            className="w-full mt-1 ml-2 outline-none py-2 px-4 rounded-lg border border-prim"
            onChange={handleChange}
            name="deliveryChargeOffArea"
            value={formData.deliveryChargeOffArea}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className="px-4 py-2 text-white bg-red-700 rounded-lg"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>

  );
};

export default Delivery;
