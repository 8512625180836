import React from "react";

const SpecialOfferHeading = ({ setShowOffer, showOffer }) => {
  const handleClick = () => {
    setShowOffer(!showOffer);
  };
  return (
    <div className="flex items-center justify-between border-b-2 border-gray-400 pl-2">
      <p className="text-2xl font-bold pb-6 text-gray-700 mt-6">
        Special Offers
      </p>
      <button
        type="submit"
        className="cursor-pointer px-4 py-3 outline-none rounded-lg bg-red-700 text-white"
        onClick={handleClick}
      >
        {showOffer ? "Add Offer" : "Cancel"}
      </button>
    </div>
  );
};

export default SpecialOfferHeading;
