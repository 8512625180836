import React from "react";

import { TiDeleteOutline } from "react-icons/all";

import Button from "../../../../../../../components/utilities/Button";

export default function FoodAllergyModalHead({ closeModal }) {
  return (
    <div className="flex justify-between pb-1 border-b">
      <h2 className="text-xl">Food Allergy Add/Update</h2>
      <Button
        className="text-xl text-red-700 cursor-pointer text-xl"
        onClick={closeModal}
      >
        <TiDeleteOutline />
      </Button>
    </div>
  );
}
