import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import SignIn from './SignIn';
import FooterBottom from './FooterBottom';

export default function Login () {
    
    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps Partner | Sign In</title>
            </Helmet>
            <SignIn />
            <FooterBottom />
        </HelmetProvider>
        
    )
}