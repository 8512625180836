import { apiBaseUrl, apiVersionPrefix } from "../../config";
  import axios from "axios";
import { GET_ALL_BUSINESS_TYPE_SUCCESS, GET_BUSINESS_TYPE_FAILURE } from "../types/businessType/type";
  
  export const getAllBusinessType = async (dispatch, type) => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `${apiBaseUrl}${apiVersionPrefix}/business-type/get-all-business-type`,
      );
      
      if (data) {
        dispatch({ type: GET_ALL_BUSINESS_TYPE_SUCCESS, payload: data });
        // console.log(data);
      }
    } catch (err) {
    
      const {
        response: {
          data: { error },
        },
      } = err;
      dispatch({ type: GET_BUSINESS_TYPE_FAILURE, payload: error });
    }
  };