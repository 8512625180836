import React from 'react'
import { BsTrash } from 'react-icons/bs';

const OptionType = (props) => {

    const { item, option, setOption } = props;

    const handleTypeDelete = (item) => {
        let updatedOption = { ...option };
        updatedOption.types = updatedOption.types.filter(el => el.typeName !== item.typeName);
        setOption({ ...option, types: updatedOption.types });
    }
    return (
        <div className="py-3 w-full  rounded-lg flex items-center bg-red-200 mt-2 ">
            <p className='w-1/3 ml-5 font-semibold'>Name : {item.typeName}</p>
            <p className='w-1/3 mr-5 font-semibold text-center'>Price: £ {item.price}</p>
            <div className='w-1/3 flex justify-end mr-3'>
                <button className='text-white p-3 mr-3 bg-red-700 rounded-lg' onClick={() => handleTypeDelete(item)}><BsTrash /></button>
            </div>
        </div>
    )
}

export default OptionType
