import React, { useState } from 'react'
import { FiEdit } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import EditOption from './EditOption';


const Option = ({ formData, setFormData, option, setOption, setShowTypes }) => {

    const [edit, setEdit] = useState(false)

    const handleDelete = (option) => {
        let updatedOptions = formData.options;
        updatedOptions = updatedOptions.filter(el => el.name !== option.name);
        setFormData({ ...formData, options: updatedOptions });
    }

    const handleEdit = (option) => {
        setOption({
            "choiceCount": option.choiceCount,
            "name": option.name,
            "types": option.types,
            "restaurant": option.restaurant
        })
        setEdit(true)
    }

    return (
        <div className='w-full '>
            {
                !edit && formData.options?.map((el, index) => (

                    <div className="w-full" key={index}>
                        <div className="py-3 w-full flex items-center bg-blue-200 mt-5">
                            <div className='w-1/3 px-5 font-semibold '>Name : {el.name}</div>
                            <div className='w-1/3 text-center font-semibold'> Choose Any : {el.choiceCount}</div>
                            <div className='w-1/3 flex justify-end mr-3'>
                                <button className='text-white p-3 mr-3 bg-blue-700 rounded-lg' onClick={() => handleEdit(el)}><FiEdit /></button>
                                <button onClick={() => handleDelete(el)} className='text-white p-3 mr-3 bg-red-700 rounded-lg'><BsTrash /></button>
                            </div>
                        </div>
                        <div className="w-full mt-2 ">
                            <h1 className='text-sm font-semibold ml-2'>Types</h1>
                            <div className='flex flex-wrap w-full '>
                                {
                                    el.types?.map((item, index) => (
                                        <div className="py-3 w-full  rounded-lg flex items-center bg-red-200 mt-2 " key={index}>
                                            <p className='w-1/2 ml-5 font-semibold'>Name : {item.typeName}</p>
                                            <p className='w-1/2 text-right mr-5 font-semibold'>Price: £ {item.price}</p>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                ))
            }
            {
                edit && <EditOption setShowTypes={setShowTypes} edit={edit} setDataOption={setOption} setEdit={setEdit} optionData={option} formData={formData} setFormData={setFormData} />
            }
        </div>
    )
}

export default Option
