import React, { useState, useEffect } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";

import { getCookie } from "../../../components/utilities/cookie/cookie";
import {
  getSalesByToday,
  getSalesBySevenDays,
} from "../../../redux/actions/order";

import AdminSalesReportContent from "./AdminSalesReportContent";
import AdminLayout from "../../../components/layout/AdminLayout";
import Heading from "../../../components/utilities/Heading";
import Tabs from "../../../components/utilities/Tabs";

const menus = ["Today", "Last 7 Days"];
export default function AdminSalesReport() {
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  const id = JSON.parse(localStorage.getItem("restaurant"))?.restaurant;
  const [selectedTab, setSelectedTab] = useState(menus[0]);

  useEffect(() => {
    if (selectedTab === menus[0]) {
      getSalesByToday(dispatch, token, id);
    } else if (selectedTab === menus[1]) {
      getSalesBySevenDays(dispatch, token, id);
    }
  }, [selectedTab, dispatch, token, id]);

  // const { orders: { orders, totalSales } } = useSelector((state) => state.order);
  const { orders } = useSelector((state) => state.order);

  const totalSales = (total) => {
    for (let i = 0; i < orders.length; i++) {
      total += orders[i].totalPrice;
    }
    return total?.toFixed(2);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps Partner | Sales Report</title>
      </Helmet>
      <AdminLayout>
        <Heading>Sales Report</Heading>
        <div className="mt-4">
          <div className="mt-4">
            <div className="sm:flex md:flex items-center justify-between">
              <Tabs
                variant="prim"
                tabs={menus}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </div>
          </div>
          <div className="sm:flex md:flex items-center justify-between">
            <div></div>
            <div className="px-2 sm:px-8 md:px-8 mt-2 sm:mt-0 md:mt-0">
              <p className="text-sm font-bold">
                Total: <span className="fotn-thin">{totalSales(0)}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full pr-4">
          {orders && orders.length > 0 ? (
            orders.map((order, index) => (
              <AdminSalesReportContent
                orderId={order.orderNumber}
                date={order.createdAt}
                price={order.totalPrice?.toFixed(2)}
                key={index}
              />
            ))
          ) : (
            <span className="text-red-700 font-bold text-lg">
              No orders is found!
            </span>
          )}
        </div>
      </AdminLayout>
    </HelmetProvider>
  );
}
