import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../../../components/utilities/cookie/cookie.js";

import {
  getRestaurantAddress,
  updateRestaurantAddress,
  createRestaurantAddress
} from "../../../../../../redux/actions/address.js";

export default function Address({ setIsEdit }) {
  const token = getCookie("@partner_token");
  const dispatch = useDispatch();

  const { address } = useSelector((state) => state.address);
  const [formData, setFormData] = useState({
    address: address.address,
    postCode: address.postCode,
    phoneNo: address.phoneNo,
  });
  const addressId = address._id;
  const { restaurantInfo } = useSelector((state) => state.restaurantInfo);
  const id = restaurantInfo._id;
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(addressId != undefined){
      await updateRestaurantAddress(dispatch, token, formData, addressId);
    } else {
      await createRestaurantAddress(dispatch, token, formData, id);
    }
   
    await getRestaurantAddress(dispatch, token, id);
    setIsEdit(false);
  };

  return (
    <div className="pb-3 w-full ">
      <form onSubmit={handleSubmit} className="w-full px-8">
        <div className="py-2">
          <div className="w-full">
            <input
              placeholder="Address"
              className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center mt-2">
            <div className="w-full ">
              <input
                placeholder="Ex nes2fds"
                className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
                name="postCode"
                value={formData.postCode}
                onChange={handleChange}
              />
            </div>
            <div className="w-full pl-2">
              <input
                placeholder="Contact Number"
                className="w-full mt-1 outline-none py-2 px-4 rounded-lg border border-prim"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end ml-2">
          <button className="px-4 py-2 text-white bg-red-700 rounded-lg">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
