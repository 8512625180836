import React, { useState } from "react";
import Option from "./Option";
import OptionHeader from "./OptionHeader";
import OptionInput from "./OptionInput";
import OptionType from "./OptionType";
import TypeInput from "./TypeInput";
import FoodOptionBottom from "./FoodOptionBottom";

export default function FoodOption({ formData, setFormData }) {

  const { restaurant } = JSON.parse(localStorage.getItem("restaurant"));

  const [option, setOption] = useState({
    "choiceCount": 0,
    "name": "",
    "types": [],
    "restaurant": restaurant

  });

  const [type, setType] = useState({
    typeName: "",
    price: 0,
    isAdded: false
  })

  const [cancel, setCancel] = useState(false);

  const [showType, setShowType] = useState(false);

  return (
    <>
      <OptionHeader cancel={cancel} setCancel={setCancel} />

      <Option formData={formData} setFormData={setFormData} option={option} />

      <div className="w-full">
        {
          cancel && (
            <OptionInput option={option} setShowType={setShowType} setOption={setOption} setType={setType} />
          )
        }
        {
          option.types && option.types.map((item, index) => (

            <OptionType option={option} item={item} setOption={setOption} key={index} />

          ))
        }

        {
          showType && (
            <TypeInput setType={setType} type={type} option={option} setOption={setOption} setShowType={setShowType} />
          )
        }

        {cancel && <FoodOptionBottom formData={formData} setFormData={setFormData} setOption={setOption} option={option} setType={setType} setShowType={setShowType} setCancel={setCancel} />}
      </div>
    </>
  );
}
