import React from 'react'

const FoodAllergy = ({el}) => {
  return(
    <div className="ml-2">
        {/* <img className="w-6 h-6 object-cover" src={el.icon} alt={el.name}/> */}
        <p>Allergies: {el.name}</p>
    </div>
)
}

export default FoodAllergy
