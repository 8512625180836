import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { placeOrder } from "../../../../redux/actions/order";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { getCookie } from "../../../../components/utilities/cookie/cookie";

import CheckoutHeader from ".././../../../components/checkout/CheckoutHeader";
// import CheckoutTimeSchedule from '.././CheckoutTimeSchedule';
import CheckoutContact from ".././CheckoutContact";
import PaymentOptionCard from ".././../../../components/checkout/PaymentOptionCard";
import PaymentOptionCash from "../../../../components/checkout/PaymentOptionCash";
// import AgreementCheck from './../../../components/utilities/form/AgreementCheck';
import YourOrder from "../YourOrder";

const Collection = () => {
  const history = useHistory();
  const [check] = useState(true);
  const [order, setOrder] = useState(JSON.parse(localStorage.getItem("order")));
  const [option, setOption] = useState("cash");
  const [localCard, setLocalCard] = useState({
    total: order?.totalPrice,
    cardNumber: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  useEffect(() => {
    setOrder(JSON.parse(localStorage.getItem("order")));
  }, []);

  const { error: cardError } = useSelector((state) => state.card);

  localCard.total = order?.totalPrice;

  const token = getCookie("@partner_token");
  const dispatch = useDispatch();

  const submitOrder = async (e) => {
    e.preventDefault();
    if (check) {
      await placeOrder(dispatch, token, localCard, option, history);
    } else {
      alert("Please agree to our terms and conditions");
    }
  };
  const submitOrderCash = async (e) => {
    e.preventDefault();
    if (check) {
      await placeOrder(dispatch, token, localCard, option, history);
    } else {
      alert("Please agree to our terms and conditions");
    }
  };
  useEffect(() => {
    toast.error(cardError);
  }, [cardError]);
  return (
    <div className="grid grid-cols-4 gap-3 ">
      <div className="hidden">
        <YourOrder order={order} setOrder={setOrder} />
      </div>
      <div className="hover:bg-prim hover:text-white">
        <CheckoutHeader
          title="Customer Name"
          order={order}
          setOrder={setOrder}
        />
      </div>
     
      <div className="hover:bg-prim hover:text-white">
        <CheckoutContact order={order} setOrder={setOrder} />
      </div>

      <div className="hover:bg-prim hover:text-white">
        <PaymentOptionCard
          options={["Card"]}
          localCard={localCard}
          setLocalCard={setLocalCard}
          setOption={setOption}
          option={option}
        />
      </div>
      <div className="hover:bg-prim hover:text-white">
        <PaymentOptionCash
          options={["Cash"]}
          localCard={localCard}
          setLocalCard={setLocalCard}
          setOption={setOption}
          option={option}
        />
      </div>
      {option === "cash" ? (
        <button
          className="border-2 border-prim font-bold rounded-md p-4 hover:bg-prim min-h-110 hover:text-white"
          onClick={submitOrderCash}
        >
          Checkout
        </button>
      ) : (
        <button
          className="border-2 border-prim font-bold rounded-md p-4 hover:bg-prim hover:text-white min-h-110"
          onClick={submitOrder}
        >
         Checkout
        </button>
      )}
      <ToastContainer />

      <div className="hidden">
        <YourOrder order={order} setOrder={setOrder} />
      </div>
      {/* <div className="mx-12 py-5">
        <button className="bg-prim px-5 py-2 rounded text-white" onClick={() => history.push("/admin-in-house-orders")}>Back</button>
       
      </div>
      <div className="py-16 sm:px-32 md:px-10">
        <div className="w-full sm:flex md:flex">
          <div className="w-full sm:w-2/3 md:w-1/2 h-full p-6">
            <div className="w-full h-full">
              <div className=" flex items-center">
                <div className="w-full text-center">
                  <h1 style={{ fontSize: "2em" }} className="mb-4">
                    Collection
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Collection;
