import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { useDispatch } from "react-redux";

import { getCookie } from "../../../components/utilities/cookie/cookie";
import { getLoggedrestaurantCredential } from "../../../redux/actions/restaurant";

import AdminLayout from "../../../components/layout/AdminLayout";
import AddRestaurant from "./AddRestaurant";
import BusinessProfile from "./BusinessProfile";
import AdminRestaurant from "./AdminRestutantDetails";


export default function AdminRestBusinessProfile() {
  const dispatch = useDispatch();
  const token = getCookie("@partner_token");
  
  useEffect(() => {
    getLoggedrestaurantCredential(dispatch, token);
  }, [dispatch, token,]);
  
  return (
    <HelmetProvider>
        <Helmet>
            <title>FoodApps Partner | Business Profile</title>
        </Helmet>
      <AdminLayout multi>
        <BusinessProfile />
        <AdminRestaurant />
        <AddRestaurant />
      </AdminLayout>
    </HelmetProvider>
  );
}
