import React from "react";
import { Link } from "react-router-dom";

import ReactTooltip from "react-tooltip";
import { MdPayment } from "react-icons/md";
import Icon from "../../../components/utilities/Icon";
// import { restaurantLogout } from "../../../redux/actions/restaurant";
// import { BiLogOut } from "react-icons/bi";
// import { getCookie } from "../../../components/utilities/cookie/cookie";
// import { useDispatch } from "react-redux";
// import { useJwt } from "react-jwt";

export default function AdminSidebar() {
  const partnerTabs = [
    {
      id: 1,
      url: "/admin-restaurants",
      title: "Home",
      logo: "https://foodapps.uk/images/icons/BusinessProfile1.png",
    },
    {
      id: 2,
      url: "/admin-orders",
      title: "My Orders",
      logo: "https://foodapps.uk/images/icons/Orders1.png",
    },
    {
      id: 3,
      url: "/admin-in-house-orders",
      title: "In-House",
      logo: "https://foodapps.uk/images/icons/Drivers1.png",
    },
    {
      id: 5,
      url: "/admin-bookings",
      title: "Bookings",
      logo: "https://foodapps.uk/images/icons/MyBooking1.png",
    },
    {
      id: 6,
      url: "/admin-sales-report",
      title: "Sales Report",
      logo: "https://foodapps.uk/images/icons/SalesReport1.png",
    },
    {
      id: 7,
      url: "/admin-payment-info",
      title: "Payment Info",
      logo: MdPayment,
    },
    {
      id: 8,
      url: "/admin-all-invoices",
      title: "Invoices",
      logo: "https://foodapps.uk/images/icons/TermsOfUse1.png",
    },
    // {
    //     id: 9,
    //     url: "/admin-inbox",
    //     title: "Inbox",
    //     logo: emailIcon
    // },
    {
      id: 9,
      url: "/admin-notifications",
      title: "Notifications",
      logo: "https://foodapps.uk/images/icons/Notifications1.png",
    },
    {
      id: 12,
      url: "/admin-terms-of-use",
      title: "Terms & Conditions",
      logo: "https://foodapps.uk/images/icons/TermsOfUse1.png",
    },
  ];
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const { isExpired } = useJwt(getCookie("@partner_token"));
  // const logout = () => {
  //   restaurantLogout(
  //     dispatch,
  //     getCookie("@partner_token"),
  //     getCookie("@device_token"),
  //     history
  //   );
  // };

  return (
    <div className="h-screen fixed top-0 bottom-0 w-12 bg-white flex-col justify-center pt-4 epos-sidebar">
      <div className="text-sec font-semibold py-2 ">
        {partnerTabs.map((tab) => (
          <Link to={tab.url} key={tab.id}>
            <div
              data-tip={tab.title}
              className="flex mb-2 sm:px-2 cursor-pointer hover:text-red-700 py-2 items-center justify-center sm:justify-start md:justify-start hover:bg-gray-400 rounded-full"
            >
              {typeof tab.logo === "string" ? (
                <img
                  src={tab.logo}
                  className="w-6 h-6 object-cover"
                  alt={tab.title}
                />
              ) : (
                <Icon icon={tab.logo} />
              )}
              {/* <p className="hidden sm:block md:block px-4 font-semiblod truncate">
                                    {tab.title}
                                </p> */}
            </div>
          </Link>
        ))}
        <ReactTooltip className="bg-black text-white" />
      </div>
      {/* <div
            className={!isExpired ? "block" : "hidden"}
            onClick={logout}>
          <BiLogOut className="inline text-prim ml-3 cursor-pointer" />
        </div> */}
    </div>
  );
}
