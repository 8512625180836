import React, { useState, useEffect } from 'react'
import { FiEdit2, FiX, FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../components/utilities/Icon";
import { deleteDiscount, getRestaurantDiscount } from '../../../../redux/actions/discount.js';
import CreateDiscount from './CreateDiscount.js';
import UpdateDiscount from './UpdateDiscount.js';
import { getCookie } from "../../../../components/utilities/cookie/cookie.js";

const Discount = () => {

    const [editable, setEditable] = useState(false);
    const { restaurantInfo } = useSelector(state => state.restaurantInfo);
    const restaurantId = restaurantInfo._id;


    const dispatch = useDispatch();

    const token = getCookie("@partner_token");

    useEffect(() => {
        getRestaurantDiscount(dispatch, restaurantId)
    }, [dispatch, restaurantId])

    const { discount } = useSelector(state => state.discount);
    const discountId = discount && discount?._id;

    const handleDelete = async (e) => {
        e.preventDefault();
        await deleteDiscount(dispatch, token, discountId);
        await getRestaurantDiscount(dispatch, restaurantId);
    }

    return (
        <div className="mt-5 px-3 sm:px-3">
            <div className="w-full flex justify-between">
                <div className="border-b-2 border-gray-400 pb-2">
                    <p className="md:px-6 text-lg font-bold">Discount</p>
                </div>
                {
                    discount && (
                        <div className="flex items-center w-full justify-end mr-5">
                            {!editable ? (
                                <Icon onClick={() => setEditable(true)} icon={FiEdit2} />
                            ) : (
                                <div className="flex justify-between">
                                    <Icon onClick={() => setEditable(false)} icon={FiX} />
                                </div>
                            )}
                            <Icon classes="ml-2" onClick={handleDelete} icon={FiTrash2} />
                        </div>
                    )
                }

            </div>
            {!editable && discount ? (
                <div className="w-full flex flex-wrap items-center mt-2">
                    <div className="w-full md:w-1/4 flex justify-between">
                        <div className="w-full flex items-center md:px-6">
                            <div className="mt-1 w-full flex items-center">
                                <p className="text-sm text-sec font-semibold m-3">
                                    Discount
                                </p>
                                <p>{discount && discount.discountValue}%</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 flex justify-between">
                        <div className="w-full flex items-center md:px-6">
                            <div className="mt-1 w-full flex items-center">
                                <p className="text-sm text-sec font-semibold m-3">
                                    Valid Until
                                </p>
                                <p>{discount && discount.validationExpire}</p>
                            </div>
                        </div>
                    </div>

                </div>
            ) : <CreateDiscount setEditable={setEditable} />}
            {editable && <UpdateDiscount setEditable={setEditable} />}

        </div>
    )
}

export default Discount
