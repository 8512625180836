import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import CategoryButton from "../../../../../components/utilities/buttons/CategoryButton";
import AddCategory from "./AddCategory";

import { getCookie } from "../../../../../components/utilities/cookie/cookie";
import { getRestaurantAllCategories } from "../../../../../redux/actions/categories";

export default function Category({ setOpenSpecialOffer }) {
  const token = getCookie("@partner_token");
  const { restaurant } = useSelector((state) => state.restaurant);
  const id = restaurant._id;
  const { categories } = useSelector((state) => state.categories);

  const dispatch = useDispatch();

  useEffect(() => {
    getRestaurantAllCategories(dispatch, id, token);
  }, [dispatch, id, token]);

  const CategoryDOM = categories?.map((el) => (
    <CategoryButton
      crossable
      setOpenSpecialOffer={setOpenSpecialOffer}
      category={el}
      key={el._id}
    />
  ));

  return (
    <div className="w-full md:w-3/12 items-center pr-2 border-r-2">
      <p className="text-2xl font-bold border-b-2 border-gray-400 pb-6 text-gray-700 mt-6">
        Categories
      </p>
      <div className="mt-2">
        <div className="p-1 w-full">
          {categories ? CategoryDOM : null}
          {/* <SpecialOffer setOpenSpecialOffer={setOpenSpecialOffer} /> */}
          <AddCategory />
        </div>
      </div>
    </div>
  );
}
