/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector, useDispatch } from 'react-redux';

import AdminLayout from "../../../components/layout/AdminLayout";
import Heading from "../../../components/utilities/Heading";
import Epos from "./../Epos/index";
import MyOrder from "../../../components/myOrder";
import Tabs from "../../../components/utilities/Tabs";


import { getCookie } from "../../../components/utilities/cookie/cookie";
import { getAllRestaurantsDineInOrders, getRestaurantsInhousePendingOrders } from "./../../../redux/actions/order";
// const menus = ["All Orders"]
const menus = ["All Orders", "Pending"];

export default function AdminInHouse() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(menus[0]);
  const id = JSON.parse(localStorage.getItem("restaurant")).restaurant;
  const token = getCookie("@partner_token");

  useEffect(() => {
    if(selectedTab === menus[0]){
      getAllRestaurantsDineInOrders(dispatch, token, id);
    }
    else if(selectedTab === menus[1]){
      getRestaurantsInhousePendingOrders(dispatch, token, id);
    }  
  }, [selectedTab, dispatch, token]) 

  const { orders, error } = useSelector((state) => state.order);
  
  const [epos, setEpos] = useState(false);

  return (
    <HelmetProvider>
      <Helmet>
        <title>FoodApps Partner | In-House</title>
      </Helmet>
      <AdminLayout>
        {!epos && (
          <div>
            <Heading>In-House Orders</Heading>
            <div className="mt-4 flex justify-between">
              <div className="sm:flex md:flex items-center justify-between">
                <Tabs variant="prim" tabs={menus} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              </div>
              {/* <Button variant="sm" onClick={() => setEpos(true)}>E pos</Button> */}
            </div>
            <div className=" flex flex-wrap sm:flex md:flex justify-start">
            {orders?.length > 0 ? (
              orders.map((order, index) => (
                <div className="w-full sm:w-1/2 md:w-1/3">
                  <MyOrder key={index} order={order} />
                </div>
              ))
            ) : (
              <span className="text-red-700 font-bold text-lg">
                {error? error: "No orders is found!"}
              </span>
            )}
          </div>
          </div>
        )}
        {epos && (
            <Epos setEpos={setEpos} />
        )}
      </AdminLayout>
    </HelmetProvider>
  );
}
