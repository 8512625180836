import React from 'react'

import moment from "moment";

import CrossButton from '../buttons/CrossButton'

export default function NotificationContent(props) {

    let { dateTime } = props;

    const time = moment(dateTime).fromNow();

    return (
        <div className="py-2">
            <div key={props.id} style={props.containerStyle} className={`w-full rounded-lg bg-nat py-4 px-2 sm:px-6 md:px-6 sm:flex md:flex items-center justify-between ${props.containerClasses}`}>
                <p style={props.descStyle} className={`text-sm ${props.descClasses}`}><span style={props.titleStyle} className={`font-bold ${props.titleClasses}`}>{props.title} </span>{props.desc} {props.for}</p>
                <div className="flex items-center mt-3 sm:mt-0 md:mt-0">
                    <p style={props.dateTimeStyle} className={`sm:mx-4 md:mx-4 flex-grow ${props.dateTimeClasses}`}>{time}</p>
                    <CrossButton onClick={props.onClick} />
                </div>
            </div>
        </div>
    )
}
