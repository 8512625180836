import React from 'react'

const OrderSummuryItems = (props) => {
    const { value, itemName, quantity, isNegative,options } = props;
    return (
        <div className="flex justify-between">
            <div>
                <p className="font-medium text-md">{quantity === 0 ? itemName : `${quantity} X ${itemName}`}</p>
                <p className="px-1">{options}</p>
            </div>
            <div className='pr-10'>
            <p className="font-medium">{isNegative ? "-" : null} £ {Number.parseFloat(value).toFixed(2)}</p>
           
            </div>
        </div>
    )
}

export default OrderSummuryItems
