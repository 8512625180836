import React from 'react';

import { useDispatch } from 'react-redux'

import { getCookie } from "../utilities/cookie/cookie";
import { AcceptOrder, DeclineOrder, getBookingPendingOrders } from "../../redux/actions/order";

import 'react-toastify/dist/ReactToastify.css';

import Button from '../utilities/buttons/Button';
import BookingBody from './BookingBody.js/index.js';
import BookingHeader from './bookingHeader';

export default function Booking({ booking }) {
    const dispatch = useDispatch();
    const token = getCookie("@partner_token");

    const handleAccept = async (orderId) => {
      await AcceptOrder(dispatch, token, orderId);
      getBookingPendingOrders(dispatch, token);
    //   toast.success("Booking is Accepted", { position: toast.POSITION.TOP_CENTER })
    }
    const handleDecline = async (orderId) => {
      await DeclineOrder(dispatch, token, orderId);
      getBookingPendingOrders(dispatch, token);
    //   toast.success("Booking is Canceled", { position: toast.POSITION.TOP_CENTER })
    }
    return (
        <div className="py-2">
            {/* <ToastContainer style={{ fontSize: "1rem" }} /> */}
            <div className="w-full rounded-lg bg-white shadow-lg border p-2 flex flex-wrap justify-between">
                <BookingHeader booking={booking} />
                <BookingBody booking={booking}  />
                {booking.orderStatus === "Pending" &&
                <div className="flex items-center px-2 pt-4">
                    <Button onClick={() => handleAccept(booking._id)} variant="md" bgColor="bg-green-600" classes="">Accept</Button>
                    <Button onClick={() => handleDecline(booking._id)} variant="md" bgColor="bg-prim" classes="mx-2">Decline</Button>
                </div>}
            </div>
        </div>
    )
}
