import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function FoodAllergyModalBody({
  state,
  onChange,
  handleSubmit,
  formData,
  setFormData,
  setIsModalOpen,
}) {
  const { name } = state;

  const [searchString, setSearchString] = useState("");

  let { allergies } = useSelector((state) => state.allergies);

  allergies = allergies.filter((el) => {
    let value;
    let nameFilter = el.name.toLowerCase().includes(searchString.toLowerCase());
    if (searchString === "") {
      value = el;
    } else if (nameFilter) {
      value = el;
    }
    return value;
  });

  const handleChange = (e) => {
    e.preventDefault();
    setSearchString(e.target.value);
    onChange(e);
  };

  const handleClick = (allergy) => {
    const updatedFormData = { ...formData };
    updatedFormData.foodAllergy.push(allergy);
    setFormData(updatedFormData);
    setIsModalOpen(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          label="Name"
          name="name"
          placeHolder="Search Allergy And Select"
          value={name}
          type="text"
          onChange={handleChange}
          isRequired={true}
          className="w-full outline-none p-1 text-black border border-red-700 rounded"
        />
      </form>
      <div className="pt-3 ">
        {allergies.map((el) => (
          <div
            className="flex items-center py-2 px-1 cursor-pointer"
            onClick={() => handleClick(el)}
          >
            <span className="text-lg">{el.name}</span>
            {/* <img className="ml-3 h-6 w-6" src={el.icon} alt={el.name} /> */}
          </div>
        ))}
      </div>
    </>
  );
}
