import {
    GET_INVOICE_FAILURE,
    GET_INVOICE_SUCCESS
} from "../types/invoice/type";

const initialState = {
    invoice: {},
    invoices: [],
    status: null,
    error: "",
    loading: true
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                invoice: action.payload,
                error: "",
            };

        case GET_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
                status: "Failed",
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
