import React , {useState} from "react";
import { Link, useHistory } from "react-router-dom";

import { BiLogOut } from "react-icons/bi";
import { useJwt } from 'react-jwt'

import ContactUsButton from "../../navbar/ContactUsButton";
import NotificationsButton from "../../utilities/buttons/NotificattionsButton";
import FoodAppsLogo from "../../../images/FoodApps_Partner.png";
import Button from "../../utilities/buttons/Button";

import { restaurantLogout } from "../../../redux/actions/restaurant"
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../utilities/cookie/cookie";
export default function AdminNavbar() {

  const dispatch = useDispatch();
  const history = useHistory();
  const { isExpired } = useJwt(getCookie('@partner_token'));
  const logout = () => {
    restaurantLogout(dispatch, getCookie('@partner_token'), getCookie('@device_token'),history)
  };
  const { restaurantInfo } = useSelector((state) => state.restaurantInfo);
  const restaurantId = JSON.parse(localStorage.getItem("restaurant")) ? JSON.parse(localStorage.getItem("restaurant")).restaurant : '';

  const order = {
    restaurant: restaurantId,
    restaurantUniId: restaurantInfo.uniId,
    orderType: "Dine In",
    discount: null,
    coupon: null,
    customerName: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).name
      : "",
    mobileNo: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).mobileNo
      : "",
    subTotal: 0,
    shippingFee: 0,
    totalPrice: 0,
    paymentMethod: "",
    pickupTime: "",
    kitchenNotes: "",
    deliveredAt: "",
    riderService: restaurantInfo?.restaurantDelivery?.isRiderService,
    driver: null,
    cartItems: "",
    serviceCharge: 0,
    partnerFACharge: 0,
    address: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).address?.address
      : "",
    postCode: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer")).address?.postCode
      : "",
    deliveryCharge: 0,
    fromRestaurant: true,
  };

  const [cart, setCart] = useState({
    customer: "",
    restaurant: restaurantId,
    foodItems: [],
    count: 0,
    subTotal: 0,
    note: "",
  });

  const handleCookie = () =>{
    localStorage.setItem("order", JSON.stringify(order));

    const myCart = JSON.parse(localStorage.getItem("cart"));
    if (myCart && myCart.restaurant === restaurantId) {
      setCart({
        ...cart,
        foodItems: myCart.foodItems,
        subTotal: myCart.subTotal,
      });
    } else {
      setCart({
        customer: "",
        restaurant: restaurantId,
        foodItems: [],
        count: 0,
        subTotal: 0,
      });
    }
  }

  return (
    <div className="w-full fixed z-40 right-0">
      <div className="flex lg:flex items-center w-full px-10 justify-between h-16 md:h-24 bg-white border">
        <div className="flex justify-center">
          <Link to="/admin">
            <img className="h-10 md:h-16" src={FoodAppsLogo} alt="" />
          </Link>
        </div>
        <div className="flex items-center">
          <Link to="/epos">
            <Button onClick={handleCookie} className="m-4" variant="sm">E pos</Button>
          </Link>
            <ContactUsButton />              
            <NotificationsButton />
          <Link to="/admin">
            <p className="text-prim hover:text-red-900 trans">Admin</p>
          </Link>
          <button
            className={!isExpired ? "block" : "hidden"}
            onClick={logout}
          >
            <BiLogOut className="inline text-prim ml-3" /> Logout
          </button>
        </div>
      </div>
    </div>
  );
}
