import React, { useState } from "react";

import Modal from "react-modal";

import styles from "../styles";
import OptionInput from "./OptionInput";
import OptionType from "./OptionType";
import TypeInput from "./TypeInput";

const EditOption = ({ edit, setEdit, optionData, formData, setFormData, setDataOption, setShowTypes }) => {
    const { foodOptionModalArea } = styles;

    const [option, setOption] = useState({
        "choiceCount": optionData.choiceCount,
        "name": optionData.name,
        "types": optionData.types,
        "restaurant": optionData.restaurant

    });


    const [type, setType] = useState({
        typeName: "",
        price: 0,
        isAdded: false
    })

    const [showType, setShowType] = useState(false);

    const handleCancel = () => {
        setType({ typeName: "", price: 0, isAdded: false })
        setDataOption({
            "choiceCount": 0,
            "name": "",
            "types": [],
            "restaurant": optionData.restaurant
        })
        setShowTypes(false);
        setEdit(false)
    }

    const handleEditOption = async (optionData) => {
        const updatedOptions = formData.options;
        for (let i = 0; i < updatedOptions?.length; i++) {
            if (updatedOptions[i].name === optionData.name) {
                updatedOptions[i] = optionData;
            }
        }
        setFormData({ ...formData, options: updatedOptions })
        setShowTypes(false);
        setDataOption({
            "choiceCount": 0,
            "name": "",
            "types": [],
            "restaurant": optionData.restaurant
        })
        setEdit(false);
    }


    return (
        <div>
            <Modal
                isOpen={edit}
                onRequestClose={() => setEdit(false)}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div style={foodOptionModalArea}>
                    <OptionInput option={option} setShowType={setShowType} setOption={setOption} setType={setType} setShowTypes={setShowTypes} isEdit={true} />
                    {
                        option.types && option.types.map((item, index) => (

                            <OptionType option={option} item={item} setOption={setOption} key={index} />

                        ))
                    }
                    {
                        showType && (
                            <TypeInput setType={setType} type={type} option={option} setOption={setOption} setShowType={setShowType} setShowTypes={setShowTypes} />
                        )
                    }
                    <div className="flex justify-end mt-3">
                        <button className=" mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={() => handleEditOption(option)}>Save</button>
                        <button className=" mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </Modal>

        </div>
    )
}


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, .8)",
    },
    modal: {
        outline: "none",
    },
};


export default EditOption;
