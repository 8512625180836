import React from "react";
import { FiEdit2 } from "react-icons/fi";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../../../components/utilities/cookie/cookie.js";
import Toggle from "../../../../../../components/utilities/form/Toggle.js";
import {
  deleteSpecialOffer,
  getAllSpecialOfferOfRestaurant,
  getSingleSpecialOffer,
} from "../../../../../../redux/actions/specialOffer.js";

const SpecialOfferTitle = ({ specialOffer, setIsEdit }) => {
  const dispatch = useDispatch();
  const { restaurant } = useSelector((state) => state.restaurant);
  const restaurantId = restaurant.restaurant;
  const offerId = specialOffer._id;
  const handleEdit = async () => {
    await getSingleSpecialOffer(dispatch, offerId);
    setIsEdit(true);
  };
  const token = getCookie("@partner_token");
  const handleDelete = async () => {
    await deleteSpecialOffer(dispatch, token, offerId);
    await getAllSpecialOfferOfRestaurant(dispatch, restaurantId);
    setIsEdit(false);
  };
  return (
    <div className="p-2 border-b flex justify-between items-center">
      <div>
        <div className="flex items-center pb-2">
          <h1 className="text-lg font-bold text-gray-700 mr-2">
            {specialOffer.name}
          </h1>
          <Toggle checked={specialOffer.isActive} />
        </div>
        <p className="text-sm text-gray-600 font-semibold ">
          {specialOffer.description}
        </p>
      </div>
      <div className="px-3">
        <p className="text-sm font-bold text-gray-700">£{specialOffer.price}</p>
      </div>
      <div className="flex items-center">
        <button className="px-2 " onClick={handleEdit}>
          <FiEdit2 fontSize="15px" />
        </button>
        <button className="px-2 " onClick={handleDelete}>
          <TiDeleteOutline fontSize="20px" />
        </button>
      </div>
    </div>
  );
};

export default SpecialOfferTitle;
