import React, { useState } from "react";

import { AiOutlineDown } from "react-icons/ai";
import PackageFoods from "./PackageFoods";
import { FiCheck } from "react-icons/fi";
import "./index.css";

const PackageChild = ({ formData, setFormData, category, index }) => {
  const [state, setState] = useState(false);
  const [checked, setChecked] = useState(true);
  const [packageForm, setPackageForm] = useState({
    foods: [],
    category: category._id,
    chooseAny: 0,
    index: index,
  });

  const handleCheck = () => {
    setChecked(!checked);
    if (checked) {
      const { packages } = formData;
      packages.push(packageForm);
      setFormData({ ...formData, packages });

    }
    if (!checked) {
      let packages = formData.packages.filter(
        (el) => el.category !== category._id,
      );
      formData = { ...formData, packages };
      setFormData(formData);
    }
  };

  const handelChange = (e) => {
    e.preventDefault();
    setPackageForm({ ...packageForm, chooseAny: e.target.value });

  };

  const handleClick = () => {
    setState(!state);
  };

  return (
    <div className="p-2">
      <div className="flex justify-between cursor-pointer mb-2">
        <div className="flex items-center text-red-700 text-sm font-semibold">
          <div className="w-5 h-5">
            <div
              onClick={handleCheck}
              className={`w-5 h-5 border-2 flex items-center justify-center border-black cursor-pointer rounded-md border-red-700 }`}
            >
              {!checked && <FiCheck />}
            </div>
          </div>
          <span className="ml-1">Choose</span>
          <input
            type="text"
            className="outline-none w-12 px-1 mx-2 rounded border border-red-700 text-prim"
            value={packageForm.chooseAny}
            name="chooseAny"
            onChange={handelChange}
          />
          form {category.name}
        </div>

        <div
          className="p-1 rounded-full bg-prim text-white "
          onClick={handleClick}
        >
          <AiOutlineDown fontSize="15px" />
        </div>
      </div>

      <div className={state ? "dropdown-full " : "dropdown "}>
        {category.foods && category.foods.length > 0 ? (
          category.foods.map((el, i) => (
            <PackageFoods
              food={el}
              packageForm={packageForm}
              setPackageForm={setPackageForm}
              key={i}
            />
          ))
        ) : (
          <p className="px-3 border-t border-b py-2 font-semibold text-sm">
            Nothing Found
          </p>
        )}
      </div>
    </div>
  );
};

export default PackageChild;
