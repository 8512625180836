
import React,{useEffect} from 'react';
import { useDispatch} from 'react-redux';
import { getTermsAndCondition } from '../../../redux/actions/termsAndConditions';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AdminLayout from '../../../components/layout/AdminLayout';
import "./index.css";


export default function AdminTermsOfUse () {
    const dispatch = useDispatch();

    useEffect(()=>{
        getTermsAndCondition(dispatch,"Partner");
    },[dispatch])
    
    return (
        <HelmetProvider>
            <Helmet>
                <title>FoodApps Partner | Terms & Conditions</title>
            </Helmet>
            <AdminLayout>
            <h1 className="text-center font-bold text-2xl mb-5">Business Owner Policy</h1>
                <p><strong>FoodApps UK</strong>  tells you the terms and conditions on which food and drink will be collected from the listed Restaurants or Takeaways and deliver to the allocated customers.</p> <br />
                <p> 
                     Please read these terms and conditions carefully before complete the registration as <br /> <strong>FoodApps Partner</strong> by mark the read option you should understand that you are agree to be bound by these terms and conditions. If you refuse to accept these terms and conditions, you will not be able complete the registration through this website.
                </p>
                <br />
                <p>
                    <strong>www.foodapps.uk</strong> operated by NETSOFTUK SOLUTION LTD which is trading name of this Company. We are registered in UK under company NO-12235478 and with our registered office- Unit-3, 2a Beaconsfield Street, Newcastle Upon Tyne, Tyne & Wear, United Kingdom, NE4 5JN.
                </p>
                <br />
                <p>
                    <strong>FoodApps</strong> is only intended for <strong>Business Owner</strong> by people’s post code resident in their specified Ares by this website. The purpose of this website is able to deliver the Foods Food from the Chosen Restaurants or Takeaways. 
                </p>
                <br />
                <p>
                    <strong>Cancel Policy:</strong>
                    <br />
                    Once an order is placed, you will be provided the option to cancel the order up until the point of acceptance from the Outlet. You will not have any right to cancel an order once accepted by the Outlet.
                </p>
                <br />
                <p>
                    <strong>Right to Business:</strong> It’s <strong>Business Owner</strong> responsibility to comply all legal requirements with local council as well as health & safety policy to run the Food Business. We are not liable any responsibility if they are fail to maintain any points of legal points. 
                </p>
                <br />
                <p>
                    <strong>Not show policy:</strong> All drivers need to inform Restaurants/ Takeaways if any Customer No Show their visibility to pick up their Food. They have to wait at list 10 Minutes to confirm NO SHOW massage to customer Support and return the Food to Restaurants/ Takeaways.
                </p>
                <br />
                <p>
                    <strong>Terminate by the</strong> Restaurants/ Takeaways:   Restaurants/ Takeaways can terminate the Customer’s order due to payment fail or anti-social behaviour by the Customers. 
                </p>
                <br />
                <p>
                    <strong>Payment Policy:</strong>
                    <strong>7.1</strong> Payment for all <strong>Business Owner</strong> will be weekly payment via direct pay to their own Bank. Other payment option needs to inform to customer support team. Bank charges are 2% of price plus 20 pens per order.
                </p>
                <br />
                <p>
                    <strong>Private Policy:</strong>
                    We are committed to safeguarding the privacy of our website visitors; this policy sets out how we will treat your personal information. Our website uses cookies. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.

                </p>
            </AdminLayout>
        </HelmetProvider>
        
    )
}