import React from "react";

import "./index.css";

export default function Button({onClick, className, children}) {
    return (
        <div>
            <button onClick={onClick} className={className}>{children}</button>
        </div>
    );
}
