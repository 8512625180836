
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyB639x5RVsxPLAZ3pJD9LN3AxwzE0Xjelw",
  authDomain: "food-apps-uk.firebaseapp.com",
  projectId: "food-apps-uk",
  storageBucket: "food-apps-uk.appspot.com",
  messagingSenderId: "909859387283",
  appId: "1:909859387283:web:bd55c332b8cfe025ccda66"
};

// Initialize Firebase
 const app =initializeApp(firebaseConfig);
export default app;