import {
    GET_CHARGE_SUCCESS,
    GET_CHARGE_FAILURE,
} from "../types/charges/types";

const initialState = {
    charges: {},
    status: null,
    serviceError: null,
    loading: true
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CHARGE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                charges: action.payload,
                serviceError: null
            };

        case GET_CHARGE_FAILURE:
            return {
                ...state,
                loading: false,
                charges: {},
                status: "Failed",
                serviceError: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;