import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSpecialOfferOfRestaurant } from "../../../../../redux/actions/specialOffer";
import Packages from "./SpecialOffer/Packages";
import SpecialOfferTitle from "./SpecialOffer/SpecialOfferTitle";
const ShowSpecialOffer = ({ setIsEdit, isEdit }) => {
  const { restaurant } = useSelector((state) => state.restaurant);
  const { specialOffers } = useSelector((state) => state.specialOffers);
  const restaurantId = restaurant.restaurant;
  const dispatch = useDispatch();
  useEffect(() => {
    getAllSpecialOfferOfRestaurant(dispatch, restaurantId);
  }, [dispatch, restaurantId]);

  return (
    <div>
      {specialOffers.map((el, i) => (
        <div key={i}>
          <SpecialOfferTitle
            setIsEdit={setIsEdit}
            specialOffer={el}
            isEdit={isEdit}
          />
          <Packages packages={el.packages} />
        </div>
      ))}
    </div>
  );
};

export default ShowSpecialOffer;
