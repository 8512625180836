import React from 'react';

import Modal from 'react-modal';
import { FiX } from 'react-icons/fi';

import Button from '../../../../components/utilities/buttons/Button';
import Icon from '../../../../components/utilities/Icon';
import { useState } from 'react';
import { sendReply, getAllRestaurantAllContacts } from './../../../../redux/actions/contact';
import { useDispatch } from 'react-redux';
import { getCookie } from '../../../../components/utilities/cookie/cookie';
import { useSelector } from 'react-redux';

export default function Inboxpopup({ openModal, setOpenModal, contact }) {

    const date = new Date(contact.createdAt);

    const dispatch = useDispatch();

    const token = getCookie("@partner_token");

    const id = contact._id;

    const { restaurant } = useSelector((state) => state.restaurant);

    const rid = restaurant.restaurant;

    const [formData, setFormData] = useState({
        message: ""
    });

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        sendReply(dispatch, token, id, formData);
        getAllRestaurantAllContacts(dispatch, token, rid);
      };

    return (
        <>
            <Modal
                isOpen={openModal}
                style={customStyles}
                className="w-full outline-none absolute border border-none"
                ariaHideApp={false}
            >
                <div className="w-full h-screen flex items-center justify-center">
                    <div className="w-2/3 bg-white rounded-lg p-6">
                        <div className="flex justify-end">
                            <Icon onClick={() => setOpenModal(false)} icon={FiX} />
                        </div>

                        <div className="flex items-center justify-between py-3 border-b-2">
                            <p className="text-sm text-gray-700">
                                <span className="text-gray-800 font-bold px-2">From:</span>
                                <span>{contact.name}</span>
                            </p>
                            <p className="text-sm text-gray-800 font-bold">{contact.customerEmail}</p>
                            <p className="text-sm text-gray-700">{date.toDateString()}</p>
                        </div>
                        <div className="flex items-center py-3 px-3 w-full scroll">
                            <div className="max-h-64 w-full">
                                <div className="text-sm text-gray-800">
                                    <div className="py-1 w-full flex justify-start">
                                        <div className="max-w-2/3 text-gray-800 rounded-lg p-4 bg-nat text-left">
                                            <p>
                                                {contact.reason}
                                            </p>
                                            <p className="mt-2 text-xs text-gray-600">{date.toString('YYYY-MM-dd')}</p>
                                        </div>
                                    </div>
                                    <div className="py-1 w-full flex justify-end">
                                        <div className="max-w-2/3 text-white rounded-lg p-4 text-right bg-dark">
                                            <p>
                                                {/* {contact.} */}
                                                test
                                            </p>
                                            <p className="mt-2 text-xs text-gray-200">26/11/2021 12:15 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex py-3 border-2 rounded-lg px-3 my-3">
                            <textarea name="message" className="w-full h-16 outline-none" onChange={handleChange}></textarea>
                        </div>
                        <div className="flex justify-end py-2">
                            <Button onClick={handleSubmit}>Send</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
const customStyles = {
    content: {
        top: '0px',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        //   zIndex                : '500'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, .8)'
    },
    modal: {
        outline: "none"
    }
};