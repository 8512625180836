import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getDeliveryCharge } from "../../../redux/actions/deliveryCharge";

const CheckoutDeliveryAddressField = ({ order, setOrder, isEdit, setIsEdit,setIsOpen  }) => {
  const [formData, setFormData] = useState({
    deliveredAt: "",
    postCode: ""
  })

  const dispatch = useDispatch();

  
  const { restaurantInfo } = useSelector(state => state.restaurantInfo);

  const [mount, setMount] = useState(true);

  const [deliveryFormData, setDeliveryFormData] = useState({
    postCode:  "",
    restaurantPostCode: restaurantInfo?.address?.postCode,
    restaurant: restaurantInfo._id

  })

  useEffect(() => {
    getDeliveryCharge(dispatch, deliveryFormData)
  }, [dispatch, deliveryFormData]);
  const { deliveryCharge } = useSelector(state =>(state.deliveryCharge))
  

  useEffect(() => {
    let localOrder = JSON.parse(localStorage.getItem('order'));
    if (mount) {
      localOrder = {
        ...localOrder,
        deliveredAt: formData.address,
        postCode: formData.postCode,
        deliveryCharge: deliveryCharge.data
      };
    } else {
      localOrder = {
        ...localOrder,
        deliveredAt: formData.address,
        postCode: formData.postCode,
        deliveryCharge: deliveryCharge.data
      };
    }
    console.log(localOrder, "local");
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
  }, [deliveryCharge, setOrder, mount])

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleClick = async(e) => {
    e.preventDefault();
    let localOrder = JSON.parse(localStorage.getItem('order'));
    localOrder = {
      ...localOrder, deliveredAt: formData.address, postCode: formData.postCode,
    };
    localStorage.setItem('order', JSON.stringify(localOrder));
    setOrder(localOrder)
    setDeliveryFormData({ ...deliveryFormData, postCode: formData.postCode })
    await getDeliveryCharge(dispatch, { ...deliveryFormData, postCode: formData.postCode })
    setIsEdit(false)
    setMount(false)
    setIsOpen(false)
  }

  return (
    <div className="sm:flex md:flex flex-wrap items-center mt-8">
      <div className="w-full">
          {!isEdit ? (null)
          // <h1 className="mx-2">{order.deliveredAt ? order.deliveredAt : "Enter An Address "} <br />{order.postCode ? order.postCode : "Enter A Post code "}</h1>
          :(
          <div>
            <input type="text" className="w-full border-2 border-prim rounded p-2 mx-1 mb-2" placeholder="Address" value={formData.address} onChange={handleChange} name="address" />
  
            <input type="text" className="w-full border-2 border-prim rounded p-2 mx-1" value={formData.postCode} onChange={handleChange} placeholder="Post Code" name="postCode" />
            <button className="bg-prim px-5 py-2 rounded text-white mx-1" onClick={(e)=>handleClick(e)}>Add</button>
          </div>
         ) }
      </div>
    </div>
  );
};

export default CheckoutDeliveryAddressField;
