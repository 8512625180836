import {
    GET_COUPON_SUCCESS,
    GET_COUPON_FAILURE,
} from "../types/coupon/types";
import { apiBaseUrl, apiVersionPrefix } from "../../config";
import axios from "axios";

export const applyCustomerCoupon = async (dispatch, formData, token) => {
    try {
        const { data: { data } } = await axios.put(`${apiBaseUrl}${apiVersionPrefix}/coupon/customer-use-coupon`, formData, {

            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                "Authorization": `Bearer ${token}`,
            },
        })
        if (data) {
            dispatch({ type: GET_COUPON_SUCCESS, payload: data })
            let order = JSON.parse(localStorage.getItem('order'));
            order.coupon = data._id;
            localStorage.setItem('order', JSON.stringify(order))
        }
    } catch (err) {
        const { response: { data: { error } } } = err;
        dispatch({ type: GET_COUPON_FAILURE, payload: error })
    }
}