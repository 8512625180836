import React from 'react'

const OptionInput = (props) => {
    const { option, setOption, setType, setShowType } = props;
    const handleOptionChange = (e) => {
        e.preventDefault();
        setOption({ ...option, [e.target.name]: e.target.value });
    }
    const handleAdd = (e) => {
        e.preventDefault();
        setType({ typeName: "", price: 0, isAdded: false })
        setShowType(true)
    }
    return (

        <div>
            <div className="py-3 w-full flex items-center bg-red-200 mt-5">
                <input type="text" className="w-1/3 mx-2 py-2 px-2" value={option.name} name="name" placeholder="Name" onChange={handleOptionChange} />
                <input type="text" className="w-1/3 mx-2 py-2 px-2" value={option.choiceCount} name="choiceCount" placeholder="Choice Count" onChange={handleOptionChange} />
                <button className="w-1/3 mx-2 py-2 px-3 bg-red-700 text-white rounded-lg mr-2" onClick={handleAdd}>Add Types</button>

            </div>
        </div>
    )
}

export default OptionInput
