import React from 'react'

export default function BusinessProfileContent(props) {
    return (
        <div className="w-full sm:w-1/2 md:w-1/2 sm:pl-2 md:pl-2">
            <div className="w-full mr-4 flex sm:flex-col md:flex-col mt-3">
                <p className="font-bold text-xs sm:text-sm md:text-sm mb-3">{props.title}<span className="sm:hidden md:hidden">:</span></p>
                <div className="px-3 sm:px-o md:px-0">
                  <p className="text-xs font-bold">{props.value}</p>
            
                </div>
            </div>
        </div>
        
    )
}