import React from 'react';
import { useSelector} from 'react-redux';

import BusinessProfileContent from './BusinessProfileContent'

export default function BusinessProfileBody() {

    const {restaurant} =useSelector(state=>state.restaurant);
    return (
        <div className="flex items-center flex-wrap w-full">
            <div className="w-full flex flex-wrap border-b-2 sm:pb-4 md:pb-4">
                <BusinessProfileContent title="Full Name" value={restaurant.ownerName} />
                <BusinessProfileContent title="Contact Number" value={restaurant.mobileNo} />
                <BusinessProfileContent title="Email" value={restaurant.email} />
                <BusinessProfileContent title="Company or UTR No" value={restaurant.UTR_no} />
                <BusinessProfileContent title="Hygiene Certificate No" value={restaurant.hygieneCertificationNo} />
            </div>
        </div>
    )
}
